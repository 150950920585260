/*--global--*/
/* base sizes */
/* colors */
/* base variables */
/* titles */
.user-select {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.icon {
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
/* for multiline */
/*
1. Reset CSS
2. Fonts
3. Basic styles, components
    3.1 Global
	3.2 Tags
    3.3 Basic elements
        3.3.1 Text fields
        3.3.2 Checkboxes
        3.3.3 Radio
        3.3.4 Selects, dropdowns
        3.3.5 Buttons
    3.4 Basic sections
        3.4.1 Header
        3.4.2 Footer
        3.4.3 Mobile navigation    
        3.4.4 Inner page
        3.4.5 Popups
    3.5 Content
4. Pages and sections
    4.1 Index
    4.2 Catalog
    4.3 Pilomaterials
    4.4 Inner article
    4.5 Contacts
    4.6 Card
    4.7 Basket
    4.8 Delivery
    4.9 About company
5. Libs
    5.1 Slick
    5.2 Fancybox  
    5.3 Rating
*/
/*------------------------------------------------------------------------------------------*/
/*----------------------------------------1. RESET CSS--------------------------------------*/
/*------------------------------------------------------------------------------------------*/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
cite,
code,
em,
font,
img,
q,
s,
small,
strong,
b,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
section,
nav,
header,
footer,
main,
figure,
figcaption,
fgroup,
time,
hr {
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  border: 0;
  outline: 0;
  background: transparent;
}
article,
aside,
section,
nav,
header,
footer,
main,
figure,
figcaption,
fgroup,
time,
hr,
picture {
  display: block;
}
html {
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
}
body {
  line-height: 1;
}
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
[type=submit],
[type=button],
[type=reset],
button {
  cursor: pointer;
  -webkit-appearance: none;
  background-color: transparent;
}
input,
textarea,
button {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 0;
  outline: none;
  background-color: transparent;
  box-shadow: none;
  -webkit-appearance: none;
  -webkit-focus-ring-color: none;
}
button {
  overflow: visible;
}
textarea {
  overflow: auto;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
}
:focus {
  outline: 0;
}
:disabled {
  cursor: default;
}
table {
  border-spacing: 0;
  border-collapse: collapse;
}
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
img {
  display: block;
  user-select: none;
}
/*------------------------------------------------------------------------------------------*/
/*----------------------------------------2. FONTS------------------------------------------*/
/*------------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------------*/
/* -------------------------------3. BASIC STYLES, COMPONENTS-------------------------------*/
/*------------------------------------------------------------------------------------------*/
/*____________________3.1 GLOBAL____________________*/
html {
  height: 100% !important;
  margin: auto;
  position: relative;
}
body {
  min-width: 320px;
  height: 100% !important;
  position: relative;
  font: normal 18px/25px 'Open Sans', sans-serif;
  background: #fff;
  color: #333;
}
@media (max-width:1300px) {
  body {
    font-size: 16px;
  }
}
.page-container {
  max-width: 100%;
  min-height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}
.ie .page-container {
  display: block;
}
.main {
  width: 100%;
  min-width: 0;
  padding: 169px 0 0;
  flex: 1 0 auto;
  position: relative;
}
@media (max-width:1024px) {
  .main {
    padding: 71px 0 0;
  }
}
@media (max-width:767px) {
  .main {
    padding: 48px 0 0;
  }
}
.container {
  width: 100%;
  min-width: 320px;
  max-width: 1580px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 30px;
  padding-left: 30px;
}
@media (max-width:1024px) {
  .container {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (max-width:767px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}
/*____________________3.2 TAGS____________________*/
.title-1 {
  font: bold 45px/61px 'Open Sans', sans-serif;
}
.title-2 {
  font: bold 45px/61px 'Open Sans', sans-serif;
}
.title-3 {
  font: bold 45px/61px 'Open Sans', sans-serif;
}
.title-4 {
  font: bold 25px/34px 'Open Sans', sans-serif;
}
.title-5 {
  font: bold 20px/25px 'Open Sans', sans-serif;
}
.title-6 {
  font: bold 18px/25px 'Open Sans', sans-serif;
}
h1 {
  margin: 0 0 30px;
  font: bold 45px/61px 'Open Sans', sans-serif;
}
@media (max-width:1300px) {
  h1 {
    font-size: 36px;
    line-height: 48px;
  }
}
@media (max-width:1024px) {
  h1 {
    margin: 0 0 24px;
    font-size: 32px;
    line-height: 44px;
  }
}
@media (max-width:767px) {
  h1 {
    margin: 0 0 20px;
    font-size: 25px;
    line-height: 34px;
  }
}
h2 {
  margin: 0 0 30px;
  font: bold 45px/61px 'Open Sans', sans-serif;
}
@media (max-width:1300px) {
  h2 {
    font-size: 36px;
    line-height: 48px;
  }
}
@media (max-width:1024px) {
  h2 {
    margin: 0 0 24px;
    font-size: 32px;
    line-height: 44px;
  }
}
@media (max-width:767px) {
  h2 {
    margin: 0 0 20px;
    font-size: 25px;
    line-height: 34px;
  }
}
h3 {
  margin: 0 0 30px;
  font: bold 45px/61px 'Open Sans', sans-serif;
}
@media (max-width:1300px) {
  h3 {
    font-size: 36px;
    line-height: 48px;
  }
}
@media (max-width:1024px) {
  h3 {
    margin: 0 0 24px;
    font-size: 32px;
    line-height: 44px;
  }
}
@media (max-width:767px) {
  h3 {
    margin: 0 0 20px;
    font-size: 25px;
    line-height: 34px;
  }
}
h4 {
  margin: 0 0 30px;
  font: bold 25px/34px 'Open Sans', sans-serif;
}
@media (max-width:1300px) {
  h4 {
    font-size: 22px;
    line-height: 30px;
  }
}
@media (max-width:1024px) {
  h4 {
    margin: 0 0 24px;
    font-size: 20px;
    line-height: 28px;
  }
}
@media (max-width:767px) {
  h4 {
    margin: 0 0 20px;
    font-size: 16px;
    line-height: 25px;
  }
}
h5 {
  margin: 0 0 30px;
  font: bold 20px/25px 'Open Sans', sans-serif;
}
@media (max-width:1300px) {
  h5 {
    font-size: 18px;
  }
}
@media (max-width:1024px) {
  h5 {
    margin: 0 0 24px;
  }
}
@media (max-width:767px) {
  h5 {
    margin: 0 0 20px;
  }
}
h6 {
  margin: 0 0 30px;
  font: bold 18px/25px 'Open Sans', sans-serif;
}
@media (max-width:1300px) {
  h6 {
    font-size: 16px;
  }
}
@media (max-width:1024px) {
  h6 {
    margin: 0 0 24px;
  }
}
@media (max-width:767px) {
  h6 {
    margin: 0 0 20px;
  }
}
ul {
  margin: 0 0 20px;
}
ul > li {
  list-style: none;
}
ol {
  margin: 0 0 20px;
  list-style: none;
  counter-reset: item;
}
ol > li:before {
  content: counter(item, decimal) ". ";
  counter-increment: item;
}
p {
  margin: 0 0 20px;
}
img {
  max-width: 100%;
  height: auto;
  margin: 0 0 20px;
}
a {
  color: #21B11D;
  text-decoration: none;
  transition: 0.3s linear;
}
button,
input[type=submit],
input[type=reset] {
  transition: 0.3s linear;
}
button {
  user-select: none;
}
/*____________________3.3 BASIC ELEMENTS____________________*/
/* 3.3.1 Text fields */
*::-moz-placeholder {
  opacity: 1;
  color: #333;
}
*::-webkit-input-placeholder {
  line-height: normal;
  opacity: 1;
  color: #333;
}
*:-ms-input-placeholder {
  opacity: 1;
  color: #333;
}
.input {
  padding: 0 0 10px;
}
.input__label {
  padding: 0 0 7px;
  display: block;
  font-size: 18px;
  line-height: 20px;
  user-select: none;
}
.input__field {
  width: 100%;
  height: 47px;
  margin: 0 0 10px;
  padding: 0 20px;
  display: block;
  background: #fff;
  border: 1px solid #CDCDCD;
  font: 18px/1 'Open Sans', sans-serif;
  color: #333;
}
.input__field:focus {
  border-color: #000;
}
.input__field:focus + .input__placeholder {
  display: none;
}
.input__field.active + .input__placeholder {
  display: none;
}
.input__textarea {
  width: 100%;
  height: 142px;
  min-height: 142px;
  margin: 0 0 10px;
  padding: 12px 20px;
  display: block;
  background: #fff;
  border: 1px solid #CDCDCD;
  font: 18px/24px 'Open Sans', sans-serif;
  color: #333;
  resize: none;
}
.input__textarea:focus {
  border-color: #000;
}
.input__textarea:focus + .input__placeholder {
  display: none;
}
.input__textarea.active + .input__placeholder {
  display: none;
}
.input__textarea_small {
  height: 83px;
  min-height: 83px;
}
.input__placeholder-wrap {
  position: relative;
}
.input__placeholder-wrap .input__field::-moz-placeholder {
  color: transparent;
}
.input__placeholder-wrap .input__field::-webkit-input-placeholder {
  color: transparent;
}
.input__placeholder-wrap .input__field:-ms-input-placeholder {
  color: transparent;
}
.input__placeholder {
  width: 100%;
  height: 100%;
  padding: 0 20px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  font: 18px/47px 'Open Sans', sans-serif;
  cursor: text;
}
.input__placeholder span {
  color: #f00;
}
/* 3.3.2 Checkboxes */
.check {
  width: 63px;
}
.check__label {
  margin: 0 0 5px;
  position: relative;
  display: block;
  font: 16px/26px 'Open Sans', sans-serif;
  -webkit-transition: all 0.4s linear 0s;
  -ms-transition: all 0.4s linear 0s;
  transition: all 0.4s linear 0s;
  cursor: pointer;
  text-align: center;
  background: #21B11D;
  color: #fff;
}
.desktop .check__label:hover {
  background: #1c9518;
}
.check__label:last-child {
  margin: 0;
}
.check__input {
  display: none;
}
.check__input:checked + .check__icon {
  background: #31722F;
}
.check__input:checked + .check__icon:before {
  opacity: 1;
}
.check__input:checked + .check__icon + .check__text {
  padding-left: 26px;
  text-align: left;
}
.check__icon {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.check__icon:before {
  content: "";
  width: 14px;
  height: 11px;
  position: absolute;
  top: 7px;
  left: 7px;
  background: url(../img/svg/check.svg) no-repeat;
  background-size: 100% 100%;
  opacity: 0;
}
.check__text {
  display: block;
  position: relative;
  z-index: 1;
}
/* 3.3.3 Radio */
.radio {
  padding: 0 0 5px;
}
@media (max-width:767px) {
  .radio_inline-mob {
    margin-right: 44px;
    display: inline-block;
    vertical-align: top;
  }
  .radio_inline-mob:last-child {
    margin-right: 0;
  }
}
.radio__label {
  min-height: 24px;
  padding: 0 0 0 26px;
  position: relative;
  display: block;
  font: 18px/24px 'Open Sans', sans-serif;
  -webkit-transition: all 0.4s linear 0s;
  -ms-transition: all 0.4s linear 0s;
  transition: all 0.4s linear 0s;
  cursor: pointer;
}
.desktop .radio__label:hover {
  color: #21B11D;
}
.radio__input {
  display: none;
}
.radio__input:checked + .radio__icon:before {
  opacity: 1;
}
.radio__icon {
  width: 16px;
  height: 16px;
  padding: 2px;
  position: absolute;
  top: 4px;
  left: 0;
  border: 1px solid #21B11D;
  border-radius: 50%;
}
.radio__icon:before {
  content: "";
  height: 100%;
  display: block;
  background: #21B11D;
  border-radius: 50%;
  opacity: 0;
}
/* 3.3.4 Selects, dropdowns */
/* 3.3.5 Buttons */
.button {
  max-width: 100%;
  height: 66px;
  margin: 0 0 20px;
  padding: 0 45px;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background: #21B11D;
  font: bold 20px/66px 'Open Sans', sans-serif;
  color: #fff;
  text-align: center;
  user-select: none;
}
.desktop .button:hover {
  background: #1c9518;
}
.button_small {
  height: 39px;
  padding: 0 20px;
  font-weight: normal;
  font-size: 18px;
  line-height: 39px;
}
.button_dark {
  background: #5A5A5A;
}
.desktop .button_dark:hover {
  background: #333;
}
@media (max-width:1300px) {
  .button {
    height: 56px;
    font-size: 18px;
    line-height: 56px;
  }
  .button_small {
    height: 38px;
    font-size: 15px;
    line-height: 38px;
  }
}
.button__arrow {
  padding: 0 54px 0 0;
  display: inline-block;
  position: relative;
}
.button__arrow:after {
  content: "";
  width: 33px;
  height: 17px;
  margin: -8.5px 0 0;
  position: absolute;
  top: 50%;
  right: 0;
  background: url(../img/svg/arrow-button.svg) no-repeat;
  background-size: 100% 100%;
}
.button__cart {
  padding: 0 0 0 35px;
  display: inline-block;
  position: relative;
}
.button__cart:before {
  content: "";
  width: 25px;
  height: 22px;
  margin: -11px 0 0;
  position: absolute;
  top: 50%;
  left: -5px;
  background: url(../img/svg/basket-white.svg) no-repeat;
  background-size: 100% 100%;
}
.arrow-button {
  width: 48px;
  height: 48px;
  display: block;
  position: relative;
}
.arrow-button:before,
.arrow-button:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -webkit-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.arrow-button:before {
  background-image: url(../img/svg/arrow-slider.svg);
}
.arrow-button:after {
  background-image: url(../img/svg/arrow-slider-hover.svg);
  opacity: 0;
  z-index: 1;
}
.desktop .arrow-button:hover:before {
  opacity: 0;
}
.desktop .arrow-button:hover:after {
  opacity: 1;
}
.arrow-button_next {
  -webkit-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
.tg-button {
  width: 18px;
  height: 15px;
  display: block;
  position: relative;
}
.tg-button:before,
.tg-button:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -webkit-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.tg-button:before {
  background-image: url(../img/svg/telegram.svg);
}
.tg-button:after {
  background-image: url(../img/svg/telegram-hover.svg);
  opacity: 0;
  z-index: 1;
}
.desktop .tg-button:hover:before {
  opacity: 0;
}
.desktop .tg-button:hover:after {
  opacity: 1;
}
.whatsapp-button {
  width: 15px;
  height: 15px;
  display: block;
  position: relative;
}
.whatsapp-button:before,
.whatsapp-button:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -webkit-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.whatsapp-button:before {
  background-image: url(../img/svg/whatsapp.svg);
}
.whatsapp-button:after {
  background-image: url(../img/svg/whatsapp-hover.svg);
  opacity: 0;
  z-index: 1;
}
.desktop .whatsapp-button:hover:before {
  opacity: 0;
}
.desktop .whatsapp-button:hover:after {
  opacity: 1;
}
.burger-button {
  width: 54px;
  height: 27px;
  display: block;
  position: relative;
  right: 15px;
}
.burger-button:before,
.burger-button:after,
.burger-button__icon {
  content: "";
  width: 24px;
  height: 2px;
  margin: 0 0 0 -12px;
  position: absolute;
  top: 5px;
  left: 50%;
  background: #000;
  -webkit-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.burger-button:before {
  margin-top: 2px;
}
.burger-button:after {
  margin-top: 10px;
}
.burger-button__icon {
  margin-top: 18px;
}
.desktop .burger-button:hover:before,
.desktop .burger-button:hover:after,
.desktop .burger-button:hover .burger-button__icon {
  background: #21B11D;
}
.burger-button.active:before {
  width: 28px;
  margin-top: 10px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.burger-button.active:after {
  width: 0;
  opacity: 0;
}
.burger-button.active .burger-button__icon {
  width: 26px;
  margin-top: 10px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.remove-button {
  padding: 3px 20px 3px 0;
  display: inline-block;
  vertical-align: top;
  position: relative;
  font: 18px/1 'Open Sans', sans-serif;
  color: #f00;
  user-select: none;
  white-space: nowrap;
}
.remove-button:before,
.remove-button:after {
  content: "";
  width: 14px;
  height: 2px;
  position: absolute;
  top: 50%;
  right: 0;
  background: #f00;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.remove-button:after {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.desktop .remove-button:hover .remove-button__text {
  border-color: transparent;
}
@media (max-width:1024px) {
  .remove-button {
    font-size: 16px;
  }
}
@media (max-width:767px) {
  .remove-button {
    padding: 0 18px 0 0;
    font-size: 14px;
  }
  .remove-button:before,
  .remove-button:after {
    width: 13px;
    margin-top: -1px;
  }
}
.remove-button__text {
  display: inline-block;
  border-bottom: 1px solid #ff9f9f;
}
.x-button {
  width: 36px;
  height: 36px;
  display: inline-block;
  vertical-align: top;
  position: relative;
  border: 3px solid #f00;
  border-radius: 50%;
}
.x-button:before,
.x-button:after {
  content: "";
  width: 14px;
  height: 2px;
  margin: -1px 0 0 -7px;
  position: absolute;
  top: 50%;
  left: 50%;
  background: #f00;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.4s linear 0s;
  -ms-transition: all 0.4s linear 0s;
  transition: all 0.4s linear 0s;
}
.x-button:after {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.desktop .x-button:hover {
  border-color: #333;
}
.desktop .x-button:hover:before,
.desktop .x-button:hover:after {
  background: #333;
}
/*____________________3.4 BASIC SECTIONS____________________*/
/* 3.4.1 Header */
.header {
  width: 100%;
  min-width: 0;
  flex: 0 0 auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  -webkit-transition: box-shadow 0.3s linear 0s;
  -ms-transition: box-shadow 0.3s linear 0s;
  transition: box-shadow 0.3s linear 0s;
}
.header.active {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0);
}
.header.scrolled {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}
.header__top {
  min-height: 119px;
  margin: 0 -15px;
  padding: 0 0 15px;
  display: flex;
  justify-content: space-between;
}
@media (max-width:1024px) {
  .header__top {
    min-height: 0;
    padding: 0;
  }
}
.header__bottom {
  margin: 0 -15px;
  display: flex;
  justify-content: space-between;
}
@media (max-width:1024px) {
  .header__bottom {
    display: none;
  }
}
.header__col {
  padding: 0 15px;
}
.header__col_burger {
  display: none;
}
.header__col_logo {
  padding-top: 22px;
  flex-grow: 0;
  flex-shrink: 0;
}
.header__col_search {
  width: 100%;
  max-width: 478px;
  padding-top: 41px;
}
.header__col_call {
  padding-top: 31px;
  flex-grow: 0;
  flex-shrink: 0;
}
.header__col_info {
  padding-top: 29px;
  flex-grow: 0;
  flex-shrink: 0;
}
.header__col_cart {
  padding-top: 43px;
  flex-grow: 0;
  flex-shrink: 0;
}
@media (max-width:1300px) {
  .header__col_burger {
    display: block;
    position: absolute;
    bottom: 15px;
    left: 15px;
  }
}
@media (max-width:1024px) {
  .header__col_burger {
    padding-top: 20px;
    position: static;
    left: auto;
    bottom: auto;
  }
  .header__col_logo {
    display: none;
  }
  .header__col_search {
    display: none;
  }
  .header__col_call {
    display: none;
  }
  .header__col_info {
    display: none;
  }
  .header__col_cart {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
@media (max-width:767px) {
  .header__col_burger {
    padding-top: 8px;
  }
  .header__col_cart {
    padding-top: 10px;
    padding-bottom: 7px;
  }
}
.header__button {
  height: 32px;
  margin: 3px 0 0 27px;
  padding: 0 25px;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
}
@media (max-width:1300px) {
  .header__button {
    height: 32px;
    font-size: 16px;
    line-height: 32px;
  }
}
.header-logo {
  display: flex;
  align-items: center;
}
.header-logo__image {
  max-width: 102px;
  margin: 0;
}
.header-logo__text {
  padding: 0 0 0 14px;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #333;
  text-transform: uppercase;
}
@media (max-width:1300px) {
  .header-logo__text {
    display: none;
  }
}
.search {
  position: relative;
}
.search__input {
  width: 100%;
  height: 42px;
  padding: 0 50px 0 20px;
  display: block;
  font: 16px/1 'Open Sans', sans-serif;
  color: #333;
  border: 1px solid #D7D7D7;
}
.search__input:focus {
  border-color: #333;
}
.search__input::-moz-placeholder {
  color: #777;
}
.search__input::-webkit-input-placeholder {
  color: #777;
}
.search__input:-ms-input-placeholder {
  color: #777;
}
.search__button {
  width: 51px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}
.search__button:before,
.search__button:after {
  content: "";
  width: 23px;
  height: 23px;
  margin: -11.5px 0 0 -11.5px;
  position: absolute;
  top: 50%;
  left: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -webkit-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.search__button:before {
  background-image: url(../img/svg/search.svg);
}
.search__button:after {
  background-image: url(../img/svg/search-hover.svg);
  opacity: 0;
  z-index: 1;
}
.desktop .search__button:hover:before {
  opacity: 0;
}
.desktop .search__button:hover:after {
  opacity: 1;
}
.info_center .info__item {
  justify-content: center;
  text-align: center;
}
.info__item {
  padding: 0 0 5px;
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
}
.info__item_small {
  padding: 0 0 3px;
  font-size: 14px;
}
.info__item_large {
  padding: 0 0 9px;
  font-size: 20px;
  line-height: 26px;
}
.info__item_large .info__icon-col {
  width: 20px;
  height: 22px;
}
.info__item_large .info__icon_2:before {
  width: 20px;
  height: 15px;
}
.info__item_large .info__icon_3:before {
  width: 20px;
  height: 19px;
}
.info__item_large .info__icon_4:before {
  width: 15px;
  height: 21px;
}
.info__item_large .info__icon_5:before {
  width: 20px;
  height: 20px;
}
.info__item_large .info__app-col {
  margin: 0 0 0 8px;
}
.info__item_large .info__tel {
  font-size: 22px;
}
.info__item_large .tg-button {
  width: 25px;
  height: 21px;
}
.info__item_large .whatsapp-button {
  width: 21px;
  height: 21px;
}
.info__icon-col {
  width: 17px;
  height: 18px;
  margin: 0 10px 0 0;
}
.info__icon {
  height: 100%;
  display: block;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.info__icon:before {
  content: "";
  margin: auto;
  display: block;
  position: relative;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.info__icon_1:before {
  width: 18px;
  height: 18px;
  background-image: url(../img/svg/tel.svg);
}
.info__icon_2:before {
  width: 16px;
  height: 12px;
  background-image: url(../img/svg/mail.svg);
}
.info__icon_3:before {
  width: 17px;
  height: 17px;
  background-image: url(../img/svg/clock.svg);
}
.info__icon_4:before {
  width: 13px;
  height: 18px;
  background-image: url(../img/svg/loc.svg);
}
.info__icon_5:before {
  width: 14px;
  height: 14px;
  background-image: url(../img/svg/phone.svg);
}
.info__text-col br {
  display: none;
}
@media (max-width:479px) {
  .info__text-col br {
    display: block;
  }
}
.info__app-col {
  margin: 0 0 0 7px;
}
.info__tel {
  padding: 0 3px 0 0;
  color: #333;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  white-space: nowrap;
}
.desktop .info__tel:hover {
  color: #21B11D;
}
.info__tel_small {
  font-size: 18px;
}
@media (max-width:1024px) {
  .info__tel {
    font-size: 16px;
  }
}
.info__mail {
  color: #333;
}
.desktop .info__mail:hover {
  color: #21B11D;
}
.info__link {
  display: inline-block;
  border-bottom: 1px solid #ace2ab;
  transition: none;
  line-height: 14px;
}
.desktop .info__link:hover {
  border-color: transparent;
}
.header-cart {
  width: 51px;
  height: 45px;
  margin: 0 14px 0 0;
  display: block;
  position: relative;
}
.header-cart:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: url(../img/svg/basket.svg) no-repeat;
  background-size: 100% 100%;
  -webkit-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.desktop .header-cart:hover .header-cart__count {
  background: #1c9518;
}
@media (max-width:1300px) {
  .header-cart {
    margin: 0;
  }
}
@media (max-width:1024px) {
  .header-cart {
    width: 35px;
    height: 31px;
    margin: 0 14px 0 0;
  }
}
.header-cart__count {
  min-width: 20px;
  height: 20px;
  padding: 0 3px;
  position: absolute;
  top: -5px;
  right: -14px;
  background: #21B11D;
  border-radius: 50%;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  -webkit-transition: all 0.4s linear 0s;
  -ms-transition: all 0.4s linear 0s;
  transition: all 0.4s linear 0s;
}
@media (max-width:767px) {
  .header-cart__count {
    min-width: 18px;
    height: 18px;
    line-height: 18px;
    top: -4px;
  }
}
@media (max-width:1300px) {
  .header-nav {
    display: none;
  }
}
.header-nav__list {
  margin: 0 -25px;
  display: flex;
}
@media (max-width:1500px) {
  .header-nav__list {
    margin: 0 -15px;
  }
}
.header-nav__item {
  position: relative;
}
.header-nav__item.to-right .header-subnav {
  left: auto;
  right: 0;
}
.header-nav__link {
  padding: 0 25px;
  display: block;
  position: relative;
  font-size: 16px;
  line-height: 50px;
  color: #333;
  white-space: nowrap;
}
.desktop .header-nav__link:hover {
  color: #21B11D;
}
.header-nav__link_icon {
  padding-right: 51px;
}
.header-nav__link_icon:after {
  content: "";
  width: 16px;
  height: 16px;
  margin: -8px 0 0;
  position: absolute;
  top: 50%;
  right: 25px;
  background: url(../img/svg/arrow-bottom.svg) no-repeat;
  background-size: 100% 100%;
  -webkit-transition: all 0.2s linear 0s;
  -ms-transition: all 0.2s linear 0s;
  transition: all 0.2s linear 0s;
}
.header-nav__link_icon.active:after {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
@media (max-width:1500px) {
  .header-nav__link {
    padding-left: 15px;
    padding-right: 15px;
  }
  .header-nav__link_icon {
    padding-right: 41px;
  }
  .header-nav__link_icon:after {
    right: 15px;
  }
}
.header-subnav {
  margin: 0;
  padding: 10px 0;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: #F3F3F3;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
}
@media (max-width:1500px) {
  .header-subnav {
    left: -5px;
  }
}
.header-subnav__link {
  padding: 5px 25px;
  display: block;
  font-size: 16px;
  line-height: 24px;
  color: #333;
  white-space: nowrap;
}
.desktop .header-subnav__link:hover {
  color: #21B11D;
}
@media (max-width:1500px) {
  .header-subnav__link {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.login-nav {
  margin: 0 -12px;
  display: flex;
  font-size: 14px;
  line-height: 48px;
  text-transform: uppercase;
}
.login-nav__item {
  padding: 2px 12px 0;
}
.login-nav__link {
  padding: 0 0 0 16px;
  display: block;
  position: relative;
  color: #333;
}
.desktop .login-nav__link:hover {
  color: #21B11D;
}
.login-nav__link:before {
  content: "";
  width: 12px;
  height: 12px;
  margin: -6px 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.mozilla .login-nav__link:before {
  margin-top: -5px;
}
.login-nav__link_icon-1:before {
  background-image: url(../img/svg/login.svg);
}
.login-nav__link_icon-2:before {
  background-image: url(../img/svg/reg.svg);
}
/* 3.4.2 Footer */
.footer {
  width: 100%;
  min-width: 0;
  flex: 0 0 auto;
  position: relative;
  overflow: hidden;
  border-top: 4px solid #EBEBEB;
}
.footer__main {
  padding-top: 30px;
  padding-bottom: 12px;
}
@media (max-width:767px) {
  .footer__main {
    padding-top: 20px;
    text-align: center;
  }
}
.footer__cols {
  margin: 0 -15px;
  display: flex;
  justify-content: space-between;
}
@media (max-width:767px) {
  .footer__cols {
    display: block;
  }
}
.footer__col {
  padding: 0 15px 13px;
}
@media (max-width:950px) {
  .footer__col_hide-tablet {
    display: none;
  }
}
@media (max-width:767px) {
  .footer__col {
    padding-bottom: 0;
  }
  .footer__col_hide-mob {
    display: none;
  }
}
.footer-logo {
  display: flex;
  align-items: center;
}
.footer-logo__image {
  max-width: 102px;
  margin: 0;
}
.footer-logo__text {
  padding: 0 0 0 35px;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #333;
}
@media (max-width:1500px) {
  .footer-logo__text {
    display: none;
  }
}
.footer-nav__list {
  margin: 0;
  font-size: 16px;
  line-height: 22px;
}
@media (max-width:1024px) {
  .footer-nav__list {
    font-size: 14px;
  }
}
@media (max-width:767px) {
  .footer-nav__list {
    font-size: 16px;
  }
}
.footer-nav__link {
  display: block;
  color: #333;
}
.desktop .footer-nav__link:hover {
  color: #21B11D;
}
@media (max-width:1024px) {
  .footer-nav__link {
    padding: 0 0 1px;
  }
}
@media (max-width:767px) {
  .footer-nav__link {
    padding: 0;
  }
}
.socials {
  margin: 0 -2.5px;
  display: flex;
  flex-wrap: wrap;
}
@media (max-width:767px) {
  .socials {
    margin-top: 14px;
    margin-bottom: 12px;
    justify-content: center;
  }
}
.socials__item {
  padding: 0 2.5px 5px;
}
.socials__link {
  width: 41px;
  height: 41px;
  display: block;
  border-radius: 50%;
  background: #21B11D;
  overflow: hidden;
}
.desktop .socials__link:hover {
  background: #1c9518;
}
.socials__icon {
  height: 100%;
  display: block;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.socials__icon:before {
  content: "";
  margin: auto;
  display: block;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.socials__icon_1:before {
  width: 22px;
  height: 22px;
  background-image: url(../img/svg/instagram.svg);
}
.socials__icon_2:before {
  width: 28px;
  height: 16px;
  background-image: url(../img/svg/vk.svg);
}
.socials__icon_3:before {
  width: 25px;
  height: 18px;
  background-image: url(../img/svg/youtube.svg);
}
.copyrights {
  margin: 0;
  padding: 0 0 12px;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
}
@media (max-width:767px) {
  .copyrights {
    font-size: 12px;
    line-height: 16px;
  }
}
/* 3.4.3 Mobile navigation */
.mob-nav {
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 90;
  background: rgba(0, 0, 0, 0.3);
}
.mob-nav.active .mob-nav__bg {
  left: 0;
}
@media (min-width:1301px) {
  .mob-nav {
    display: none !important;
  }
}
@media (max-width:767px) {
  .mob-nav {
    background: #fff;
  }
}
.mob-nav__bg {
  width: 100%;
  max-width: 320px;
  height: 100%;
  padding: 169px 0 0;
  position: absolute;
  top: 0;
  left: -100%;
  z-index: 1;
  -webkit-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  background: #ffffff;
}
@media (max-width:1024px) {
  .mob-nav__bg {
    padding: 71px 0 0;
  }
}
@media (max-width:767px) {
  .mob-nav__bg {
    max-width: none;
    padding: 48px 0 0;
    background: none;
  }
}
.mob-nav__scroll {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
.mob-nav__close {
  position: absolute;
  top: 0;
  right: 0;
}
.mob-nav__mask {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.burger-nav {
  margin: 5px 0 0;
}
.burger-nav__item_tablet {
  display: none;
}
@media (max-width:1024px) {
  .burger-nav__item_tablet {
    display: block;
  }
}
.burger-nav__link {
  padding: 9px 30px 11px;
  display: block;
  font-size: 18px;
  line-height: 25px;
  color: #333;
  border-bottom: 2px solid #E7E7E7;
}
.desktop .burger-nav__link:hover {
  color: #21B11D;
}
@media (max-width:767px) {
  .burger-nav__link {
    padding-left: 35px;
    padding-right: 35px;
  }
}
.burger-subnav {
  margin: 0;
  padding: 0 0 12px;
  display: none;
  background: #E7E7E7;
}
.burger-subnav__item:first-child {
  padding-top: 4px;
}
.burger-subnav__link {
  padding: 11px 30px 9px 60px;
  display: block;
  font-size: 18px;
  line-height: 25px;
  color: #333;
  border-bottom: 1px solid #E7E7E7;
}
.desktop .burger-subnav__link:hover {
  color: #21B11D;
}
@media (max-width:767px) {
  .burger-subnav__link {
    padding-left: 66px;
  }
}
/* 3.4.4 Inner page */
.inner-page {
  padding-top: 26px;
  padding-bottom: 33px;
}
@media (max-width:767px) {
  .inner-page {
    padding-top: 16px;
    padding-bottom: 7px;
  }
}
.inner-page__title {
  margin: 0 0 10px;
  font-weight: bold;
  font-size: 45px;
  line-height: 62px;
  text-transform: uppercase;
}
@media (max-width:1300px) {
  .inner-page__title {
    font-size: 36px;
    line-height: 48px;
  }
}
@media (max-width:1024px) {
  .inner-page__title {
    font-size: 32px;
    line-height: 44px;
  }
}
@media (max-width:767px) {
  .inner-page__title {
    margin: 0 0 8px;
    font-size: 25px;
    line-height: 34px;
  }
}
.inner-page__text {
  padding: 0 0 9px;
}
.inner-page__text + .about-us-page {
  margin-top: -9px;
}
@media (max-width:767px) {
  .inner-page__text {
    line-height: 22px;
  }
  .inner-page__text_hide-mob {
    display: none;
  }
}
.inner-page__cols {
  margin: 0 -19px 20px;
  display: flex;
  align-items: flex-end;
}
.inner-page__cols .inner-page__title {
  margin: 0;
}
@media (max-width:1500px) {
  .inner-page__cols {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media (max-width:1024px) {
  .inner-page__cols {
    justify-content: space-between;
  }
}
@media (max-width:767px) {
  .inner-page__cols {
    margin: 0 -10px 10px;
  }
}
.inner-page__col {
  padding: 0 19px;
}
@media (max-width:1500px) {
  .inner-page__col {
    padding: 0 15px;
  }
}
@media (max-width:767px) {
  .inner-page__col {
    padding: 0 10px;
  }
}
.inner-page__remove-button {
  bottom: 8px;
}
@media (max-width:1024px) {
  .inner-page__remove-button {
    bottom: 4px;
  }
}
@media (max-width:767px) {
  .inner-page__remove-button {
    bottom: -4px;
  }
}
.breadcrumbs {
  margin: 0 0 10px;
  font-size: 14px;
  line-height: 18px;
}
@media (max-width:767px) {
  .breadcrumbs {
    margin: 0 0 7px;
  }
}
.breadcrumbs__item {
  display: inline;
  position: relative;
  color: #777;
}
.breadcrumbs__item:after {
  content: "";
  width: 16px;
  height: 8px;
  margin: 0 7px;
  display: inline-block;
  background: url(../img/webp/arrow-nav.webp) no-repeat;
  background-size: 100% 100%;
}
.breadcrumbs__item:last-child:after {
  display: none;
}
@media (max-width:767px) {
  .breadcrumbs__item {
    display: none;
  }
  .breadcrumbs__item:first-child,
  .breadcrumbs__item:last-child {
    display: inline;
  }
}
.breadcrumbs__link {
  color: #21B11D;
}
.desktop .breadcrumbs__link:hover {
  text-decoration: underline;
}
/* 3.4.5 Popups */
.popup {
  width: 100%;
  height: 100%;
  max-height: 100%;
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  z-index: 9999;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  background: rgba(0, 0, 0, 0.8);
}
.popup.active .popup__window {
  opacity: 1;
  transform: scale(1);
}
.popup__row {
  width: 100%;
  height: 100%;
  display: table;
  table-layout: fixed;
  position: relative;
}
.popup__cell {
  width: 100%;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}
.popup__window {
  width: 100%;
  max-width: 360px;
  margin: auto;
  padding: 50px 30px 20px;
  position: relative;
  z-index: 1;
  color: #333;
  opacity: 0;
  transform: scale(0.5);
  transition: linear 0.4s;
  background: #fff;
}
@media (max-width:767px) {
  .popup__window {
    max-width: 320px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 15px;
  }
}
.popup__mask {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.popup__close {
  position: absolute;
  top: 16px;
  right: 24px;
  border-color: transparent;
}
.popup__close:before,
.popup__close:after {
  width: 26px;
  left: 9px;
  background: #21B11D;
}
.desktop .popup__close:hover {
  border-color: transparent;
}
.desktop .popup__close:hover:before,
.desktop .popup__close:hover:after {
  background: #333;
}
@media (max-width:767px) {
  .popup__close {
    right: 19px;
  }
}
@media (max-width:767px) {
  .popup-form {
    max-width: 290px;
    margin: auto;
  }
}
.popup-form__title {
  margin: 0 0 20px;
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
}
@media (max-width:767px) {
  .popup-form__title {
    margin: 0 0 17px;
    font-size: 22px;
    line-height: 30px;
  }
}
.popup-form__input {
  padding: 0 0 5px;
}
.popup-form__bottom {
  padding: 10px 0 0;
}
.popup-form__button {
  width: 100%;
  height: 66px;
  margin: 0 0 20px;
  padding: 0;
  display: block;
  font-size: 20px;
  line-height: 66px;
  text-transform: uppercase;
}
@media (max-width:1300px) {
  .popup-form__button {
    height: 66px;
    font-size: 20px;
    line-height: 66px;
  }
}
/*____________________3.5 CONTENT____________________*/
.content a {
  text-decoration: underline;
}
.desktop .content a:hover {
  text-decoration: none;
}
.content a[href^="tel"] {
  color: #333;
  text-decoration: none !important;
}
.content ul > li {
  padding: 0 0 0 27px;
  position: relative;
}
.content ul > li:before {
  content: "";
  width: 4px;
  height: 4px;
  position: absolute;
  top: 11px;
  left: 11px;
  background: #21B11D;
  border-radius: 50%;
}
@media (max-width:767px) {
  .content ul > li {
    padding-left: 21px;
  }
  .content ul > li:before {
    left: 8px;
  }
}
.content ol > li {
  padding: 0 0 0 27px;
  position: relative;
}
.content ol > li:before {
  width: 27px;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
}
.content h1,
.content h2 {
  margin: 0 0 19px;
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;
}
@media (max-width:1300px) {
  .content h1,
  .content h2 {
    font-size: 28px;
    line-height: 38px;
  }
}
@media (max-width:767px) {
  .content h1,
  .content h2 {
    margin: 0 0 8px;
    font-size: 20px;
    line-height: 25px;
  }
}
.content h3,
.content h4 {
  margin: 0 0 19px;
  font-weight: normal;
  font-size: 25px;
  line-height: 32px;
}
@media (max-width:1300px) {
  .content h3,
  .content h4 {
    font-size: 22px;
    line-height: 30px;
  }
}
@media (max-width:1024px) {
  .content h3,
  .content h4 {
    margin: 0 0 16px;
    font-size: 20px;
    line-height: 25px;
  }
}
@media (max-width:767px) {
  .content p + h1,
  .content p + h2 {
    margin-top: -9px;
  }
  .content p + h3,
  .content p + h4 {
    margin-top: -3px;
  }
}
.content img[style*="float:right"] {
  margin-left: 40px;
}
.content img[style*="float:left"] {
  margin-right: 30px;
}
@media (max-width:1024px) {
  .content img[style*="float:right"],
  .content img[style*="float:left"] {
    display: none;
  }
}
/*-------------------------------------------------------------------------------------------*/
/* ----------------------------------4. PAGES AND SECTIONS-----------------------------------*/
/*-------------------------------------------------------------------------------------------*/
/*____________________4.1 INDEX____________________*/
/* first screen */
.slider {
  height: 600px;
  overflow: hidden;
  position: relative;
}
@media (max-width:1300px) {
  .slider {
    height: 480px;
  }
}
@media (max-width:767px) {
  .slider {
    height: 382px;
  }
}
@media (max-width:479px) {
  .slider {
    height: 350px;
  }
}
.slider__arrows {
  width: 100%;
  height: 100%;
  padding: 0 30px;
  position: absolute;
  top: 0;
  left: 0;
}
@media (max-width:767px) {
  .slider__arrows {
    display: none;
  }
}
.slider__max {
  max-width: 1320px;
  height: 100%;
  margin: auto;
  position: relative;
}
.slider__prev {
  margin: -24px 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 2;
}
.slider__next {
  margin: -24px 0 0;
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 2;
}
.slider__list {
  position: relative;
  z-index: 1;
  background: #000;
}
.slider__item {
  height: 600px;
  position: relative;
}
@media (max-width:1300px) {
  .slider__item {
    height: 480px;
  }
}
@media (max-width:767px) {
  .slider__item {
    height: 382px;
  }
}
@media (max-width:479px) {
  .slider__item {
    height: 350px;
  }
}
.slider__image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.slider__image:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
}
.slider__row {
  width: 100%;
  display: table;
  table-layout: fixed;
  position: relative;
}
@media (max-width:767px) {
  .slider__row {
    padding-bottom: 43px;
  }
}
.slider__cell {
  height: 600px;
  display: table-cell;
  vertical-align: middle;
  position: relative;
  color: #fff;
  text-align: center;
}
@media (max-width:1300px) {
  .slider__cell {
    height: 480px;
  }
}
@media (max-width:767px) {
  .slider__cell {
    height: 337px;
  }
}
@media (max-width:479px) {
  .slider__cell {
    height: 305px;
  }
}
.slider__title {
  max-width: 1200px;
  margin: 0 auto 22px;
  font-weight: bold;
  font-size: 45px;
  line-height: 62px;
}
@media (max-width:1300px) {
  .slider__title {
    font-size: 36px;
    line-height: 48px;
  }
}
@media (max-width:1024px) {
  .slider__title {
    margin-bottom: 27px;
    font-size: 32px;
    line-height: 44px;
  }
}
@media (max-width:767px) {
  .slider__title {
    max-width: 450px;
    margin-bottom: 11px;
    font-size: 25px;
    line-height: 34px;
  }
}
.slider__content {
  max-width: 570px;
  margin: auto;
  padding: 0 10px 20px;
  font-size: 25px;
  line-height: 34px;
}
@media (max-width:1300px) {
  .slider__content {
    font-size: 22px;
    line-height: 30px;
  }
}
@media (max-width:1024px) {
  .slider__content {
    max-width: 520px;
    font-size: 20px;
    line-height: 28px;
  }
}
@media (max-width:767px) {
  .slider__content {
    max-width: 440px;
    padding-bottom: 0;
    font-size: 16px;
    line-height: 22px;
  }
}
.slider__button {
  height: 56px;
  margin: 0 0 16px;
  padding: 0 45px;
  line-height: 56px;
}
@media (max-width:1300px) {
  .slider__button {
    height: 50px;
    font-size: 18px;
    line-height: 50px;
  }
}
@media (max-width:1024px) {
  .slider__button {
    height: 46px;
    padding: 0 40px;
    font-size: 16px;
    line-height: 46px;
  }
}
@media (max-width:767px) {
  .slider__button {
    height: 40px;
    padding: 0 30px;
    line-height: 40px;
  }
}
.slider__dots {
  width: 100%;
  position: absolute;
  bottom: 40px;
  left: 0;
  z-index: 2;
}
@media (max-width:767px) {
  .slider__dots {
    bottom: 16px;
  }
}
/* second screen */
.second-screen {
  padding-top: 50px;
}
@media (max-width:767px) {
  .second-screen {
    padding-top: 28px;
  }
}
.pilomaterial {
  display: flex;
  flex-wrap: wrap;
}
.pilomaterial__article {
  width: 100%;
  order: 2;
}
.pilomaterial__article .main-article__top {
  margin-bottom: -6px;
}
@media (max-width:767px) {
  .pilomaterial__article {
    margin-bottom: -8px;
  }
}
.pilomaterial__advantages {
  width: 100%;
  order: 1;
}
.main-article {
  padding-bottom: 20px;
}
.main-article__top {
  text-align: center;
}
.main-article__title {
  margin: 0 0 30px;
  font-weight: bold;
  font-size: 45px;
  line-height: 62px;
  text-transform: uppercase;
}
@media (max-width:1300px) {
  .main-article__title {
    font-size: 36px;
    line-height: 48px;
  }
}
@media (max-width:1024px) {
  .main-article__title {
    margin: 0 0 24px;
    font-size: 32px;
    line-height: 44px;
  }
}
@media (max-width:767px) {
  .main-article__title {
    margin: 0 0 20px;
    font-size: 25px;
    line-height: 34px;
  }
}
.main-article__content {
  padding-bottom: 9px;
  overflow: hidden;
}
@media (max-width:767px) {
  .main-article__content {
    padding-bottom: 2px;
  }
}
.main-article__bottom {
  padding-bottom: 30px;
}
@media (max-width:767px) {
  .main-article__bottom {
    padding-bottom: 10px;
  }
}
.advantages {
  max-width: 1486px;
  margin: auto;
  padding-top: 3px;
  padding-bottom: 44px;
}
@media (max-width:1024px) {
  .advantages {
    max-width: 700px;
  }
}
@media (max-width:767px) {
  .advantages {
    padding-bottom: 40px;
  }
}
.advantages__list {
  margin: 0 -15px -40px;
  display: flex;
  justify-content: space-between;
}
@media (max-width:1024px) {
  .advantages__list {
    flex-wrap: wrap;
  }
}
.advantages__item {
  padding: 0 15px 40px;
  text-align: center;
}
@media (max-width:1024px) {
  .advantages__item {
    width: 50%;
  }
}
@media (max-width:767px) {
  .advantages__item {
    padding-bottom: 25px;
  }
}
@media (max-width:479px) {
  .advantages__item {
    width: 100%;
  }
}
.advantages__top {
  height: 84px;
  margin: 0 0 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (max-width:767px) {
  .advantages__top {
    height: auto;
    margin: 0 0 4px;
  }
}
.advantages__icon {
  margin: auto;
}
.advantages__title {
  margin: 0;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
}
@media (max-width:1300px) {
  .advantages__title {
    font-size: 16px;
    line-height: 22px;
  }
}
.main-catalog {
  margin: 0 -10px 50px;
  display: flex;
}
@media (max-width:767px) {
  .main-catalog {
    margin-bottom: 30px;
  }
  .main-catalog_inner {
    padding-top: 15px;
  }
}
.main-catalog__left {
  width: 25%;
  padding: 0 10px;
}
@media (max-width:1300px) {
  .main-catalog__left {
    width: 33.333%;
  }
}
@media (max-width:767px) {
  .main-catalog__left {
    display: none;
  }
}
.main-catalog__title {
  margin: 0;
  padding: 18px 30px 17px 40px;
  background: #21B11D;
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  text-transform: uppercase;
}
@media (max-width:1300px) {
  .main-catalog__title {
    padding-left: 30px;
    font-size: 18px;
  }
}
@media (max-width:1024px) {
  .main-catalog__title {
    padding-right: 25px;
    font-size: 16px;
  }
}
.main-catalog__right {
  width: 75%;
  padding: 0 10px;
}
@media (max-width:1300px) {
  .main-catalog__right {
    width: 66.666%;
  }
}
@media (max-width:767px) {
  .main-catalog__right {
    width: 100%;
  }
}
.main-catalog__button {
  width: 100%;
  height: 64px;
  margin: 0;
  padding: 0;
  display: block;
  line-height: 64px;
}
@media (max-width:1300px) {
  .main-catalog__button {
    height: 64px;
    font-size: 20px;
    line-height: 64px;
  }
}
.main-catalog__inner-bottom {
  padding: 8px 0 0;
}
@media (max-width:767px) {
  .main-catalog__inner-bottom {
    padding: 0;
  }
  .main-catalog__inner-bottom .main-catalog__button {
    position: relative;
    background: #fff;
    color: #21B11D;
  }
  .main-catalog__inner-bottom .main-catalog__button:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid #21B11D;
    -webkit-transition: all 0.4s linear 0s;
    -ms-transition: all 0.4s linear 0s;
    transition: all 0.4s linear 0s;
  }
  .desktop .main-catalog__inner-bottom .main-catalog__button:hover {
    color: #fff;
  }
  .desktop .main-catalog__inner-bottom .main-catalog__button:hover:before {
    border-color: #1c9518;
  }
}
.catalog-nav {
  margin: 0;
  background: #F3F3F3;
}
.catalog-nav__link {
  padding: 13px 30px 11px 40px;
  display: block;
  color: #333;
  border-bottom: 2px solid #E7E7E7;
}
.desktop .catalog-nav__link:hover {
  color: #21B11D;
}
.desktop .catalog-nav__link:hover .catalog-nav__back {
  text-decoration: none;
}
@media (max-width:1300px) {
  .catalog-nav__link {
    padding-left: 30px;
  }
}
.catalog-nav__back {
  padding: 0 0 0 48px;
  display: inline-block;
  position: relative;
  color: #21B11D;
  text-decoration: underline;
}
.catalog-nav__back:before {
  content: "";
  width: 33px;
  height: 17px;
  margin: -8.5px 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  background: url(../img/svg/arrow-back.svg) no-repeat;
  background-size: 100% 100%;
}
.catalog {
  margin: 0 -10px;
  padding: 0 0 2px;
  display: flex;
  flex-wrap: wrap;
}
@media (max-width:767px) {
  .catalog {
    padding: 0;
  }
}
.catalog__item {
  width: 33.333%;
  margin: 0 0 20px;
  padding: 0 10px;
}
@media (max-width:1300px) {
  .catalog__item {
    width: 50%;
  }
}
@media (max-width:767px) {
  .catalog__item {
    margin: 0 0 30px;
  }
}
@media (max-width: 600px) {
  .catalog__item {
    width: 100%;
  }
}
.catalog__bg {
  min-height: 100%;
  position: relative;
  border-radius: 20px;
}
.catalog__bg:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  border: 1px solid #D6D6D6;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}
.catalog__link {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  border-radius: 20px;
}
.catalog__top {
  padding: 20px 20px 0;
  position: relative;
}
.catalog__image {
  max-width: 262px;
  height: 155px;
  margin: 0 auto 24px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
@media (max-width:1500px) {
  .catalog__image {
    margin-bottom: 16px;
  }
}
@media (max-width:950px) {
  .catalog__image {
    height: 130px;
  }
}
@media (max-width:767px) {
  .catalog__image {
    max-width: 400px;
    height: 185px;
    margin-bottom: 4px;
  }
}
.catalog__content {
  padding: 0 21px 23px;
  position: relative;
}
@media (max-width:767px) {
  .catalog__content {
    padding: 0 18px 15px;
  }
}
.catalog__title {
  margin: 0 0 19px;
  text-align: center;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
}
@media (max-width:1500px) {
  .catalog__title {
    margin-bottom: 11px;
  }
}
@media (max-width:1300px) {
  .catalog__title {
    font-size: 18px;
  }
}
@media (max-width:1024px) {
  .catalog__title {
    font-size: 16px;
  }
}
@media (max-width:767px) {
  .catalog__title {
    margin-bottom: 4px;
  }
}
.catalog__title-link {
  position: relative;
  z-index: 2;
  color: #333;
}
.desktop .catalog__title-link:hover {
  color: #21B11D;
}
.catalog__cols {
  margin: 0 -5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width:1500px) and (min-width:1301px) {
  .catalog__cols {
    display: block;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
  }
}
@media (max-width:1024px) and (min-width:768px) {
  .catalog__cols {
    display: block;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
  }
}
.catalog__col {
  padding: 0 5px;
}
.catalog__info {
  margin: 0;
  display: inline-block;
  font-weight: bold;
  font-size: 25px;
  line-height: 23px;
}
.catalog__info span {
  display: inline-block;
  font-size: 20px;
}
@media (max-width:1500px) {
  .catalog__info {
    font-size: 20px;
    line-height: 22px;
  }
  .catalog__info span {
    font-size: 16px;
  }
}
@media (max-width:767px) {
  .catalog__info {
    line-height: 18px;
  }
}
.catalog__button {
  margin: 0;
  padding: 0 20px;
  position: relative;
  z-index: 2;
}
@media (max-width:1500px) and (min-width:1301px) {
  .catalog__button {
    margin-top: 15px;
    margin-bottom: 3px;
  }
}
@media (max-width:1300px) {
  .catalog__button {
    padding: 0 17px;
    font-size: 15px;
  }
  .catalog__button .button__cart {
    padding-left: 30px;
  }
}
@media (max-width:1024px) and (min-width:768px) {
  .catalog__button {
    margin-top: 15px;
    margin-bottom: 3px;
  }
}
@media (max-width:767px) {
  .catalog__button {
    padding: 0 15px;
    height: 32px;
    font-size: 14px;
    line-height: 32px;
  }
}
/* qualities */
.qualities {
  padding-top: 33px;
  padding-bottom: 39px;
  background: #F3F3F3;
}
@media (max-width:1500px) {
  .qualities {
    padding-top: 50px;
  }
}
@media (max-width:767px) {
  .qualities {
    padding-top: 25px;
    padding-bottom: 20px;
  }
}
.qualities__top {
  text-align: center;
}
.qualities__title {
  margin: 0 0 17px;
  font-weight: bold;
  font-size: 45px;
  line-height: 62px;
  text-transform: uppercase;
}
@media (max-width:1300px) {
  .qualities__title {
    font-size: 36px;
    line-height: 48px;
  }
}
@media (max-width:1024px) {
  .qualities__title {
    margin: 0 0 24px;
    font-size: 32px;
    line-height: 44px;
  }
}
@media (max-width:767px) {
  .qualities__title {
    margin: 0 0 22px;
    font-size: 25px;
    line-height: 34px;
  }
}
.qualities__list {
  max-width: 792px;
  margin: auto;
  overflow: hidden;
}
@media (max-width:1300px) {
  .qualities__list {
    max-width: 650px;
  }
}
@media (max-width:1024px) {
  .qualities__list {
    max-width: 600px;
  }
}
.qualities__subtitle {
  margin: 0 0 20px;
  padding: 22px 30px 21px 141px;
  position: relative;
  background: #fff;
  border: 1px solid #A5A5A5;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
}
.qualities__subtitle:before {
  content: "";
  width: 33px;
  height: 31px;
  margin: -15.5px 0 0;
  position: absolute;
  top: 50%;
  left: 66px;
  background: url(../img/svg/rating.svg) no-repeat;
  background-size: 100% 100%;
}
@media (max-width:1300px) {
  .qualities__subtitle {
    padding: 20px 30px 19px 101px;
    font-size: 16px;
  }
  .qualities__subtitle:before {
    left: 36px;
  }
}
@media (max-width:1024px) {
  .qualities__subtitle {
    padding-left: 85px;
  }
  .qualities__subtitle:before {
    left: 30px;
  }
}
@media (max-width:767px) {
  .qualities__subtitle {
    margin: 0 0 10px;
    padding: 10px 15px 10px 75px;
  }
  .qualities__subtitle:before {
    left: 18px;
  }
}
/* description */
.description {
  padding-top: 50px;
}
.description .content a {
  text-decoration: none;
}
.desktop .description .content a:hover {
  text-decoration: underline;
}
@media (max-width:767px) {
  .description {
    padding-top: 30px;
    padding-bottom: 0;
  }
}
.video {
  position: relative;
  background: #000;
}
.video.opened .video__image {
  opacity: 0;
}
.video.opened .video__button {
  display: none;
}
.video__image {
  width: 100%;
  margin: 0;
}
.video__frame {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.video__button {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.2);
}
.desktop .video__button:hover .video__icon {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.video__flex {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.video__icon {
  width: 8.68%;
  height: 12.52%;
  -webkit-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.video__icon:before {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  background: url(../img/svg/play.svg) no-repeat;
  background-size: 100% 100%;
}
/* feedback */
.feedback {
  padding-top: 55px;
  padding-bottom: 60px;
  position: relative;
  overflow: hidden;
  color: #fff;
}
@media (max-width:1024px) {
  .feedback {
    padding-bottom: 50px;
  }
}
@media (max-width:767px) {
  .feedback {
    padding-top: 30px;
    padding-bottom: 16px;
  }
}
.feedback__bg {
  width: 100%;
  height: 100%;
  min-height: 322px;
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.feedback__bg:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
}
.feedback__main {
  position: relative;
}
.feedback__top {
  padding-bottom: 20px;
  text-align: center;
}
@media (max-width:1024px) {
  .feedback__top {
    padding-bottom: 10px;
  }
}
@media (max-width:767px) {
  .feedback__top {
    padding-bottom: 0;
  }
}
.feedback__title {
  margin: 0 0 30px;
  font-weight: bold;
  font-size: 45px;
  line-height: 62px;
}
@media (max-width:1300px) {
  .feedback__title {
    font-size: 36px;
    line-height: 48px;
  }
}
@media (max-width:1024px) {
  .feedback__title {
    margin: 0 0 24px;
    font-size: 32px;
    line-height: 44px;
  }
}
@media (max-width:767px) {
  .feedback__title {
    margin: 0 0 20px;
    font-size: 25px;
    line-height: 34px;
  }
}
.feedback__cols {
  margin: 0 -21px;
  display: flex;
}
@media (max-width:1500px) {
  .feedback__cols {
    margin: 0 -15px;
  }
}
@media (max-width:1024px) {
  .feedback__cols {
    margin: 0 -10px;
  }
}
@media (max-width:767px) {
  .feedback__cols {
    flex-wrap: wrap;
  }
}
.feedback__input-wrap {
  width: 50%;
  padding: 0 21px 10px;
}
@media (max-width:1500px) {
  .feedback__input-wrap {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (max-width:1024px) {
  .feedback__input-wrap {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width:767px) {
  .feedback__input-wrap {
    width: 100%;
    padding-bottom: 0;
  }
}
.feedback__field {
  height: 66px;
  padding: 0 40px;
  font-size: 20px;
  border-color: transparent;
}
.feedback__field:focus {
  border-color: transparent;
}
@media (max-width:1300px) {
  .feedback__field {
    height: 56px;
    padding: 0 20px;
    font-size: 18px;
  }
}
@media (max-width:1024px) {
  .feedback__field {
    height: 50px;
    font-size: 16px;
  }
}
@media (max-width:767px) {
  .feedback__field {
    height: 44px;
    text-align: center;
  }
}
.feedback__button-wrap {
  min-width: 38.25%;
  padding: 0 21px 10px;
  flex-grow: 0;
  flex-shrink: 0;
}
@media (max-width:1500px) {
  .feedback__button-wrap {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (max-width:1024px) {
  .feedback__button-wrap {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width:767px) {
  .feedback__button-wrap {
    width: 100%;
    padding-bottom: 0;
  }
}
.feedback__button {
  min-width: 100%;
  padding: 0 40px;
  display: block;
  text-overflow: clip;
  text-transform: uppercase;
}
@media (max-width:1024px) {
  .feedback__button {
    height: 50px;
    padding: 0 30px;
    font-size: 16px;
    line-height: 50px;
  }
}
@media (max-width:767px) {
  .feedback__button {
    width: 100%;
    padding: 0;
    height: 56px;
    line-height: 56px;
  }
}
/*____________________4.2 CATALOG____________________*/
.catalog-page {
  padding-top: 5px;
}
.catalog-page__list {
  margin: 0 -10px;
  padding: 0 0 14px;
  display: flex;
  flex-wrap: wrap;
}
@media (max-width:767px) {
  .catalog-page__list {
    padding: 0 0 4px;
  }
}
.catalog-page__item {
  width: 33.333%;
  margin: 0 0 30px;
  padding: 0 10px;
}
@media (max-width:1024px) {
  .catalog-page__item {
    width: 50%;
  }
}
@media (max-width:767px) {
  .catalog-page__item {
    width: 100%;
    margin: 0 0 20px;
  }
}
.catalog-page__bg {
  min-height: 100%;
  padding: 25px 15px 22px;
  position: relative;
  background: #fff;
  border: 1px solid #D6D6D6;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  text-align: center;
  overflow: hidden;
  -webkit-transition: all 0.4s linear 0s;
  -ms-transition: all 0.4s linear 0s;
  transition: all 0.4s linear 0s;
}
.desktop .catalog-page__bg:hover {
  color: #21B11D;
}
@media (max-width:1024px) {
  .catalog-page__bg {
    padding-top: 19px;
    padding-bottom: 17px;
  }
}
.catalog-page__link {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.catalog-page__top {
  height: 171px;
  margin: 0 0 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (max-width:767px) {
  .catalog-page__top {
    height: auto;
  }
}
.catalog-page__icon {
  max-width: 100%;
  margin: auto;
}
.catalog-page__title-wrap {
  width: 100%;
  display: table;
  table-layout: fixed;
}
.catalog-page__title {
  height: 68px;
  margin: 0;
  display: table-cell;
  vertical-align: middle;
  font-size: 25px;
  line-height: 34px;
  text-transform: uppercase;
}
@media (max-width:1300px) {
  .catalog-page__title {
    height: 60px;
    font-size: 22px;
    line-height: 30px;
  }
}
@media (max-width:1024px) {
  .catalog-page__title {
    height: auto;
    font-size: 20px;
    line-height: 25px;
  }
}
/*____________________4.3 PILOMATERIALS____________________*/
@media (max-width:767px) {
  .best-offers_all-visible-mob .best-offers__item {
    display: block;
  }
}
.best-offers__list {
  margin: 0 -10px 11px;
  display: flex;
  flex-wrap: wrap;
}
@media (max-width:767px) {
  .best-offers__list {
    margin-bottom: 4px;
    margin-left: -15px;
    margin-right: -15px;
  }
}
.best-offers__item {
  width: 50%;
  padding: 0 10px 20px;
}
@media (max-width:767px) {
  .best-offers__item {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    display: none;
  }
  .best-offers__item:first-child {
    display: block;
  }
}
.best-offers__all {
  height: 460px;
  position: relative;
  overflow: hidden;
}
.desktop .best-offers__all:hover .best-offers__image {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.desktop .best-offers__all:hover .best-offers__bg {
  background: rgba(255, 255, 255, 0.95);
}
@media (max-width:1300px) {
  .best-offers__all {
    height: 400px;
  }
}
@media (max-width:1024px) {
  .best-offers__all {
    height: 340px;
  }
}
@media (max-width:950px) {
  .best-offers__all {
    height: 300px;
  }
}
.best-offers__link {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.best-offers__image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  -webkit-transition: all 0.6s linear 0s;
  -ms-transition: all 0.6s linear 0s;
  transition: all 0.6s linear 0s;
}
.best-offers__bg {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: rgba(255, 255, 255, 0.8);
  -webkit-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.best-offers__title {
  margin: 0;
  padding: 39px 120px 38px 54px;
  position: relative;
  font-weight: bold;
  font-size: 25px;
  line-height: 33px;
}
.best-offers__title:after {
  content: "";
  width: 57px;
  height: 25px;
  margin: -12.5px 0 0;
  position: absolute;
  top: 50%;
  right: 60px;
  background: url(../img/svg/arrow-offers.svg) no-repeat;
  background-size: 100% 100%;
}
@media (max-width:1300px) {
  .best-offers__title {
    padding-right: 100px;
    padding-left: 30px;
    font-size: 22px;
    line-height: 30px;
  }
  .best-offers__title:after {
    right: 30px;
  }
}
@media (max-width:1024px) {
  .best-offers__title {
    padding-top: 31px;
    padding-bottom: 30px;
    font-size: 20px;
    line-height: 25px;
  }
}
@media (max-width:950px) {
  .best-offers__title {
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
  }
  .best-offers__title:after {
    display: none;
  }
}
/*____________________4.4 INNER ARTICLE____________________*/
.inner-article {
  padding-top: 5px;
}
@media (max-width:767px) {
  .inner-article.opened .inner-article__text_hide-mob {
    display: block;
  }
  .inner-article.opened .inner-article__button-text:before {
    content: "Свернуть";
  }
}
.inner-article__image {
  height: 415px;
  margin: 0 0 25px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width:1024px) {
  .inner-article__image {
    height: 330px;
  }
}
@media (max-width:767px) {
  .inner-article__image {
    height: 271px;
    margin: 0 0 13px;
  }
}
.inner-article__text ul > li {
  padding-bottom: 20px;
}
.inner-article__text ul > li:before {
  background: #333;
}
.inner-article__text ul > li:last-child {
  padding-bottom: 10px;
}
@media (max-width:767px) {
  .inner-article__text ul {
    margin-bottom: 10px;
  }
}
.inner-article__text ol > li {
  padding-bottom: 20px;
}
.inner-article__text ol > li:last-child {
  padding-bottom: 10px;
}
@media (max-width:767px) {
  .inner-article__text ol {
    margin-bottom: 10px;
  }
}
@media (max-width:767px) {
  .inner-article__text_hide-mob {
    display: none;
  }
}
.inner-article__button {
  margin: 0 0 25px;
  display: none;
  position: relative;
  background: #fff;
  color: #21B11D;
}
.inner-article__button:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid #21B11D;
  -webkit-transition: all 0.4s linear 0s;
  -ms-transition: all 0.4s linear 0s;
  transition: all 0.4s linear 0s;
}
.desktop .inner-article__button:hover {
  color: #fff;
}
.desktop .inner-article__button:hover:before {
  border-color: #1c9518;
}
@media (max-width:767px) {
  .inner-article__button {
    width: 100%;
    height: 64px;
    padding: 0;
    display: block;
    font-size: 20px;
    line-height: 64px;
  }
}
.inner-article__button-text:before {
  content: "Показать еще";
}
/*____________________4.5 CONTACTS____________________*/
.contacts-page {
  padding: 0 0 16px;
  text-align: center;
}
@media (max-width:767px) {
  .contacts-page {
    padding: 0 0 11px;
  }
}
.contacts-page__title {
  margin: 0 0 10px;
  font-weight: bold;
  font-size: 45px;
  line-height: 62px;
  text-transform: uppercase;
}
@media (max-width:1300px) {
  .contacts-page__title {
    font-size: 36px;
    line-height: 48px;
  }
}
@media (max-width:1024px) {
  .contacts-page__title {
    font-size: 32px;
    line-height: 44px;
  }
}
@media (max-width:767px) {
  .contacts-page__title {
    margin: 0 0 8px;
    font-size: 25px;
    line-height: 34px;
  }
}
.contacts-page__text {
  max-width: 800px;
  margin: auto;
  padding: 0 0 5px;
}
@media (max-width:1500px) {
  .contacts-page__text {
    max-width: 720px;
  }
}
@media (max-width:767px) {
  .contacts-page__text {
    margin: 0 0 -5px;
    padding: 0;
  }
}
.map {
  height: 770px;
  margin: 0 0 40px;
  position: relative;
}
@media (max-width:1300px) {
  .map {
    height: 670px;
  }
}
@media (max-width:1024px) {
  .map {
    height: 570px;
  }
}
@media (max-width:767px) {
  .map {
    height: 507px;
    margin: 0 0 24px;
  }
}
.map__ui {
  height: 100%;
  overflow: hidden;
  background: #F3F3F3;
}
.scheme {
  padding-bottom: 23px;
  text-align: center;
}
.scheme__title {
  margin: 0 0 20px;
  font-weight: bold;
  font-size: 30px;
  line-height: 40px;
}
@media (max-width:1300px) {
  .scheme__title {
    font-size: 28px;
    line-height: 38px;
  }
}
@media (max-width:767px) {
  .scheme__title {
    margin: 0 0 15px;
    font-size: 20px;
    line-height: 25px;
  }
}
.scheme__link {
  display: block;
}
.scheme__image {
  max-width: 100%;
  margin: auto;
}
/*____________________4.6 CARD____________________*/
.card {
  padding: 0 0 98px;
}
@media (max-width:1024px) {
  .card {
    padding: 0 0 50px;
  }
}
@media (max-width:767px) {
  .card {
    padding: 0;
  }
}
.card__art {
  margin: 0 0 24px;
  font-size: 18px;
  line-height: 25px;
}
@media (max-width:1300px) {
  .card__art {
    font-size: 16px;
  }
}
@media (max-width:767px) {
  .card__art {
    margin: -8px 0 13px;
  }
}
.card__cols {
  display: flex;
}
@media (max-width:767px) {
  .card__cols {
    display: block;
  }
}
.card__left {
  width: 100%;
  min-width: 0;
  padding: 0 50px 0 0;
}
@media (max-width:1300px) {
  .card__left {
    padding: 0 30px 0 0;
  }
}
@media (max-width:767px) {
  .card__left {
    width: auto;
    padding: 0;
  }
}
.card__right {
  width: 24.01%;
  min-width: 280px;
  flex-grow: 0;
  flex-shrink: 0;
}
@media (max-width:767px) {
  .card__right {
    width: auto;
    margin: 0 -15px;
    padding: 0 15px 15px;
    background: #F3F3F3;
  }
}
.card__inner-cols {
  margin: 0 -25px;
  display: flex;
}
@media (max-width:1300px) {
  .card__inner-cols {
    margin: 0 -15px;
  }
}
@media (max-width: 1200px) {
  .card__inner-cols {
    display: block;
  }
}
.card__inner-left {
  width: 58.95%;
  padding: 0 25px;
}
@media (max-width:1300px) {
  .card__inner-left {
    padding: 0 15px;
  }
}
@media (max-width: 1200px) {
  .card__inner-left {
    width: auto;
    padding-bottom: 15px;
  }
}
.card__inner-right {
  width: 41.05%;
  padding: 0 25px;
}
@media (max-width:1300px) {
  .card__inner-right {
    padding: 0 15px;
  }
}
@media (max-width: 1200px) {
  .card__inner-right {
    width: auto;
  }
}
.card__image-link {
  max-width: 650px;
  margin: auto;
  display: block;
  border: 1px solid #DEDEDE;
}
@media (max-width:767px) {
  .card__image-link {
    max-width: 500px;
  }
}
.card__image {
  max-width: 100%;
  margin: auto;
}
.card__sidebar {
  padding: 25px 38px 30px;
  background: #F3F3F3;
}
@media (max-width:767px) {
  .card__sidebar {
    max-width: 194px;
    padding-top: 25px;
    padding-bottom: 20px;
    margin: auto;
    padding-left: 0;
    padding-right: 0;
  }
}
.card__price {
  margin: 0 0 14px;
  display: block;
  font-weight: bold;
  font-size: 30px;
  line-height: 30px;
}
.card__price span {
  font-size: 20px;
}
.card__button {
  width: 100%;
  max-width: 194px;
  height: 49px;
  margin: 0 0 8px;
  padding: 0;
  display: block;
  font-weight: normal;
  font-size: 18px;
  line-height: 49px;
}
.card__button:last-child {
  margin: 0;
}
.card__button .button__cart {
  position: relative;
  left: 3px;
}
@media (max-width:1300px) {
  .card__button {
    height: 49px;
    font-size: 18px;
    line-height: 49px;
  }
}
.chars {
  width: 100%;
  display: table;
}
@media (max-width:767px) {
  .chars {
    padding: 0 0 13px;
  }
}
.chars__item {
  display: table-row;
}
@media (max-width:767px) {
  .chars__item:last-child .chars__col {
    border: none;
  }
}
.chars__col {
  padding: 10px 20px 9px 0;
  display: table-cell;
  vertical-align: top;
  border-bottom: 1px solid #D6D6D6;
}
.chars__col:last-child {
  padding-right: 0;
}
@media (max-width:767px) {
  .chars__col:first-child {
    width: 132px;
    padding-left: 4px;
  }
}
.actions {
  max-width: 194px;
  padding: 0 0 21px;
  display: flex;
  justify-content: space-between;
  text-align: center;
}
.actions__text {
  margin: 0;
  font-size: 13px;
  line-height: 22px;
  color: #777;
}
.actions__right {
  padding: 5px 0 0;
}
.labels {
  background: #fff;
  border: 6px solid #F3F3F3;
}
.labels__item {
  display: flex;
  align-items: center;
  border-bottom: 6px solid #F3F3F3;
}
.labels__item:last-child {
  border-bottom: none;
}
.labels__icon-wrap {
  width: 62px;
}
.labels__icon {
  max-width: 100%;
  margin: auto;
}
.labels__text {
  margin: 0;
  padding: 15px 15px 15px 0;
  font-size: 18px;
  line-height: 25px;
}
.tabs-nav {
  margin: 0 3px;
  display: flex;
}
@media (max-width:767px) {
  .tabs-nav {
    display: none;
  }
}
.tabs-nav__item {
  margin: 0 -3px;
}
.tabs-nav__item.active .tabs-nav__link {
  color: #fff !important;
}
.tabs-nav__item.active .tabs-nav__link:before {
  opacity: 1;
}
.tabs-nav__item.active .tabs-nav__link:after {
  opacity: 1;
}
.tabs-nav__item:first-child .tabs-nav__link:before {
  width: -webkit-calc(100% + 6px);
  width: calc(100% + 6px);
  left: 0;
}
.tabs-nav__item:last-child .tabs-nav__link:before {
  width: -webkit-calc(100% + 6px);
  width: calc(100% + 6px);
}
.tabs-nav__link {
  min-width: 183px;
  display: block;
  position: relative;
  font-size: 18px;
  line-height: 50px;
  color: #333;
  text-align: center;
  border: 6px solid #F3F3F3;
  border-bottom: none;
}
.tabs-nav__link:before {
  content: "";
  width: -webkit-calc(100% + 12px);
  width: calc(100% + 12px);
  height: -webkit-calc(100% + 6px);
  height: calc(100% + 6px);
  position: absolute;
  top: 0;
  left: -6px;
  z-index: 1;
  opacity: 0;
  background: #21B11D;
}
.tabs-nav__link:after {
  content: "";
  width: 0;
  height: 0;
  margin: 6px 0 0 -9px;
  position: absolute;
  top: 100%;
  left: 50%;
  border-style: solid;
  border-width: 9px 9px 0 9px;
  border-color: #21B11D transparent transparent transparent;
  opacity: 0;
}
.desktop .tabs-nav__link:hover {
  color: #21B11D;
}
.tabs-nav__text {
  position: relative;
  z-index: 2;
}
.mob-tab.active .mob-tab__button:after {
  opacity: 1;
}
@media (min-width:768px) {
  .mob-tab:not(.active) .mob-tab__content {
    display: block !important;
  }
}
@media (max-width:767px) {
  .mob-tab {
    margin: 0 -15px 10px;
  }
  .mob-tab:last-child {
    margin-bottom: 3px;
  }
}
.mob-tab__button {
  width: 100%;
  padding: 16px;
  display: none;
  overflow: visible;
  position: relative;
  background: #21B11D;
  text-align: center;
  text-transform: uppercase;
  font: bold 18px/25px 'Open Sans', sans-serif;
  color: #fff;
}
.mob-tab__button:after {
  content: "";
  width: 0;
  height: 0;
  margin: 0 0 0 -10px;
  position: absolute;
  top: 100%;
  left: 50px;
  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: #21B11D transparent transparent transparent;
  opacity: 0;
  -webkit-transition: border-color 0.3s linear 0s;
  -ms-transition: border-color 0.3s linear 0s;
  transition: border-color 0.3s linear 0s;
}
.desktop .mob-tab__button:hover {
  background: #1c9518;
}
.desktop .mob-tab__button:hover:after {
  border-color: #1c9518 transparent transparent transparent;
}
.mob-tab__button.active:after {
  opacity: 1;
}
@media (max-width:767px) {
  .mob-tab__button {
    display: block;
  }
}
.mob-tab__content {
  display: none;
}
.tab {
  display: none;
  margin: 0 0 17px;
  padding: 41px 59px 26px;
  border: 6px solid #F3F3F3;
  overflow: hidden;
}
.tab.active {
  display: block;
}
@media (max-width:1300px) {
  .tab {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media (max-width:1024px) {
  .tab {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (max-width:767px) {
  .tab {
    margin: 0;
    padding: 20px 15px 0;
    display: block;
    border: none;
  }
}
.review {
  max-width: 1373px;
  padding: 0 0 19px;
}
@media (max-width:767px) {
  .review {
    padding: 0 0 10px;
  }
}
.review__item {
  padding: 0 0 34px;
}
.review__item:last-child {
  padding: 0;
}
@media (max-width:767px) {
  .review__item {
    margin: 0 0 -3px;
    padding: 0;
  }
}
.review__top {
  margin: 0 -6px 9px;
  display: flex;
  align-items: center;
  color: #777;
}
@media (max-width:767px) {
  .review__top {
    margin-bottom: 2px;
  }
}
.review__col {
  padding: 0 6px;
}
.review__body {
  display: flex;
}
.review__image-wrap {
  padding: 0 23px 0 0;
  flex-grow: 0;
  flex-shrink: 0;
}
@media (max-width:767px) {
  .review__image-wrap {
    padding: 6px 11px 0 0;
  }
}
.review__image {
  width: 108px;
  height: 108px;
  margin: 0 0 15px;
  border-radius: 50%;
}
@media (max-width:767px) {
  .review__image {
    width: 61px;
    height: 61px;
  }
}
.review__content {
  width: 100%;
  min-width: 0;
  padding: 7px 0 0;
}
@media (max-width:767px) {
  .review__content {
    padding: 0;
  }
}
.review__title {
  margin: 0 0 12px;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
}
@media (max-width:767px) {
  .review__title {
    margin: 0 0 3px;
  }
}
.review__text {
  font-size: 18px;
  line-height: 25px;
}
@media (max-width:767px) {
  .review__text {
    font-size: 16px;
  }
}
.add-review {
  max-width: 1007px;
}
.add-review__title {
  margin: 0 0 20px;
  font-size: 45px;
  line-height: 61px;
  text-transform: uppercase;
}
@media (max-width:1300px) {
  .add-review__title {
    font-size: 36px;
    line-height: 48px;
  }
}
@media (max-width:1024px) {
  .add-review__title {
    margin: 0 0 24px;
    font-size: 32px;
    line-height: 44px;
  }
}
@media (max-width:767px) {
  .add-review__title {
    margin: 0 0 20px;
    font-size: 25px;
    line-height: 34px;
  }
}
.add-review__cols {
  margin: 0 0 20px;
  display: flex;
}
.add-review__cols .input {
  padding: 0;
}
.add-review__cols .input__field {
  max-width: 319px;
  margin: 0;
}
.add-review__cols .input__textarea {
  height: 305px;
  min-height: 305px;
  margin: 0;
}
.add-review__cols .input__placeholder {
  opacity: 0;
  left: -9999px;
}
.add-review__cols_rating {
  margin: 0 0 15px;
}
@media (max-width:767px) {
  .add-review__cols {
    display: block;
    margin: 0 0 10px;
  }
  .add-review__cols .input__field {
    max-width: none;
  }
  .add-review__cols .input__textarea {
    height: 200px;
    min-height: 200px;
  }
  .add-review__cols .input__placeholder {
    opacity: 1;
    left: 0;
  }
}
.add-review__left {
  width: 168px;
  flex-grow: 0;
  flex-shrink: 0;
}
@media (max-width:767px) {
  .add-review__left {
    width: auto;
    display: none;
  }
  .add-review__left_visible-mob {
    display: block;
  }
}
.add-review__right {
  width: 100%;
  min-width: 0;
}
.add-review__label {
  padding: 12px 15px 10px 0;
  display: block;
  font-size: 18px;
  line-height: 24px;
  user-select: none;
}
.add-review__label span {
  color: #f00;
}
@media (max-width:1300px) {
  .add-review__label {
    font-size: 16px;
  }
}
@media (max-width:767px) {
  .add-review__label {
    padding: 0;
  }
}
.add-review__bottom {
  padding: 0 0 0 168px;
}
@media (max-width:767px) {
  .add-review__bottom {
    padding: 0;
  }
}
.add-review__button {
  width: 100%;
  height: 66px;
  margin: 0 0 20px;
  padding: 0;
  display: block;
  font-size: 20px;
  line-height: 66px;
  text-transform: uppercase;
}
@media (max-width:1300px) {
  .add-review__button {
    height: 66px;
    font-size: 20px;
    line-height: 66px;
  }
}
@media (max-width:767px) {
  .add-review__button {
    font-size: 18px;
  }
}
.add-review__rating {
  padding: 5px 0 0;
}
/*____________________4.7 BASKET____________________*/
.basket-page {
  margin: 0 -10px;
  display: flex;
}
@media (max-width:1024px) {
  .basket-page {
    display: block;
  }
}
.basket-page__left {
  width: 58.42%;
  padding: 0 10px;
}
@media (max-width:1024px) {
  .basket-page__left {
    width: auto;
  }
}
.basket-page__right {
  width: 41.58%;
  padding: 0 10px;
}
.basket-page__right .basket-page__section:first-child {
  padding-bottom: 34px;
}
@media (max-width:1024px) {
  .basket-page__right {
    width: auto;
  }
  .basket-page__right .basket-page__section:first-child {
    padding-bottom: 14px;
  }
}
@media (max-width:767px) {
  .basket-page__right .basket-page__section:first-child {
    padding-bottom: 0;
  }
}
.basket-page__top {
  margin: 0 0 25px;
  padding: 20px 15px 19px;
  display: flex;
  justify-content: space-between;
  background: #21B11D;
  color: #fff;
}
@media (max-width:767px) {
  .basket-page__top {
    margin: 0 -15px 19px;
    padding: 13px 10px;
  }
}
.basket-page__top-link {
  margin: 0 15px;
  display: inline-block;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  color: #fff;
  border-bottom: 1px solid transparent;
  transition: none;
  white-space: nowrap;
}
.desktop .basket-page__top-link:hover {
  border-color: rgba(255, 255, 255, 0.7);
}
@media (max-width:1500px) {
  .basket-page__top-link {
    font-size: 18px;
  }
}
@media (max-width:767px) {
  .basket-page__top-link {
    margin: 0 5px;
    font-size: 16px;
  }
}
.basket-page__section {
  padding: 0 0 14px;
}
.basket-page__section:last-child {
  padding: 0;
}
@media (max-width:767px) {
  .basket-page__section {
    padding: 0;
  }
}
.basket-page__title {
  margin: 0 15px;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  border-bottom: 1px solid transparent;
}
@media (max-width:1500px) {
  .basket-page__title {
    font-size: 18px;
  }
}
@media (max-width:767px) {
  .basket-page__title {
    margin: 0 5px;
    font-size: 16px;
  }
}
.cart {
  padding: 0 0 21px;
}
@media (max-width:1024px) {
  .cart {
    padding: 0 0 5px;
  }
}
@media (max-width:767px) {
  .cart {
    padding: 0 0 12px;
  }
}
.cart__table {
  width: 100%;
  display: table;
}
@media (max-width:767px) {
  .cart__table {
    display: block;
  }
}
.cart__head {
  display: table-row;
}
@media (max-width:767px) {
  .cart__head {
    display: none;
  }
}
.cart__head-col {
  padding: 16px 15px 16px 0;
  display: table-cell;
  vertical-align: middle;
  background: #21B11D;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: #fff;
}
.cart__head-col:first-child {
  padding-left: 30px;
}
.cart__head-col_1 {
  width: 44.42%;
}
.cart__head-col_2 {
  width: 17.77%;
}
.cart__head-col_3 {
  width: 14.12%;
}
.cart__head-col_4 {
  width: 15.83%;
}
@media (max-width:1500px) {
  .cart__head-col {
    font-size: 18px;
  }
}
.cart__list {
  margin: 0;
  display: table-row-group;
}
@media (max-width:767px) {
  .cart__list {
    display: block;
  }
}
.cart__item {
  display: table-row;
}
@media (max-width:767px) {
  .cart__item {
    min-height: 69px;
    margin: 0 0 17px;
    padding: 0 0 15px 95px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    border-bottom: 1px solid #DEDEDE;
  }
  .cart__item:last-child {
    margin: 0;
    border: none;
  }
}
.cart__cell {
  padding: 15px 15px 15px 0;
  display: table-cell;
  vertical-align: middle;
  border-bottom: 1px solid #DEDEDE;
}
.cart__cell:last-child {
  padding-right: 0;
}
@media (max-width:767px) {
  .cart__cell {
    padding: 0 5px;
    display: block;
    border: none;
  }
  .cart__cell_1 {
    width: 100%;
    padding-bottom: 8px;
  }
  .cart__cell_2 {
    display: none;
  }
  .cart__cell_3 {
    width: 100%;
    padding-bottom: 6px;
  }
}
.cart__info {
  display: flex;
  align-items: center;
}
@media (max-width:1300px) and (min-width:1025px) {
  .cart__info {
    flex-wrap: wrap;
  }
}
.cart__info-col:first-child {
  flex-grow: 0;
  flex-shrink: 0;
}
@media (max-width:1300px) and (min-width:1025px) {
  .cart__info-col {
    width: 100%;
  }
}
.cart__image {
  width: 118px;
  height: 71px;
  margin: 0 20px 0 0;
  display: block;
  border: 1px solid #DEDEDE;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
@media (max-width:1300px) and (min-width:1025px) {
  .cart__image {
    margin: 3px 0 10px;
  }
}
@media (max-width:767px) {
  .cart__image {
    width: 85px;
    height: 51px;
    position: absolute;
    top: 3px;
    left: 0;
  }
}
.cart__title {
  margin: 0;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
}
@media (max-width:1300px) {
  .cart__title {
    font-size: 16px;
    line-height: 22px;
  }
}
.cart__link {
  color: #333;
}
.desktop .cart__link:hover {
  color: #21B11D;
}
.cart__art {
  display: block;
  font-size: 18px;
  line-height: 25px;
}
.cart__art span {
  color: #777;
}
@media (max-width:1300px) {
  .cart__art {
    font-size: 16px;
    line-height: 22px;
  }
}
.cart__mob-button {
  display: none;
}
@media (max-width:767px) {
  .cart__mob-button {
    padding-right: 15px;
    display: block;
    position: relative;
    top: 9px;
  }
  .cart__mob-button:before,
  .cart__mob-button:after {
    width: 9px;
  }
}
@media (max-width:767px) {
  .cart__desktop-button {
    display: none;
  }
}
.cart__price {
  display: block;
  font-size: 22px;
  line-height: 28px;
  white-space: nowrap;
}
.cart__bottom {
  padding: 0 9.68% 0 0;
  text-align: right;
}
@media (max-width:1024px) {
  .cart__bottom {
    padding: 0 13% 0 0;
  }
}
@media (max-width:950px) {
  .cart__bottom {
    padding: 0 8% 0 0;
  }
}
@media (max-width:767px) {
  .cart__bottom {
    padding: 0;
  }
}
.cart__totals-wrap {
  width: 100%;
  max-width: 295px;
  display: inline-block;
  text-align: left;
}
@media (max-width:767px) {
  .cart__totals-wrap {
    max-width: none;
    padding-top: 2px;
  }
}
.cart__totals {
  padding: 21px 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #DEDEDE;
}
.cart__totals:last-child {
  border: none;
}
@media (max-width:767px) {
  .cart__totals {
    margin: 0 0 10px;
    padding: 0 0 11px;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .cart__totals:last-child {
    padding: 0;
  }
}
.cart__totals-left {
  padding: 0 15px 0 0;
  font-size: 18px;
  line-height: 24px;
  position: relative;
}
@media (max-width:767px) {
  .cart__totals-left {
    min-width: 99px;
    flex-grow: 0;
    flex-shrink: 0;
    top: 7px;
  }
}
.cart__totals-right {
  font-size: 25px;
  line-height: 34px;
}
.plus-minus {
  width: 76px;
  padding: 0 19px;
  position: relative;
}
.plus-minus__input {
  width: 100%;
  height: 38px;
  font: 20px/1 'Open Sans', sans-serif;
  text-align: center;
  background: #fff;
  border: 1px solid #CDCDCD;
}
.plus-minus__input:focus {
  border-color: #333;
}
@media (max-width:1300px) {
  .plus-minus__input {
    font-size: 18px;
  }
}
.plus-minus__prev,
.plus-minus__next {
  width: 19px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.plus-minus__prev:before,
.plus-minus__next:before,
.plus-minus__prev:after,
.plus-minus__next:after {
  content: "";
  width: 10px;
  height: 2px;
  margin: -1px 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  background: #21B11D;
  -webkit-transition: all 0.4s linear 0s;
  -ms-transition: all 0.4s linear 0s;
  transition: all 0.4s linear 0s;
}
.plus-minus__prev:after,
.plus-minus__next:after {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.desktop .plus-minus__prev:hover:before,
.desktop .plus-minus__next:hover:before,
.desktop .plus-minus__prev:hover:after,
.desktop .plus-minus__next:hover:after {
  background: #333;
}
.plus-minus__prev:disabled,
.plus-minus__next:disabled {
  cursor: pointer;
}
.plus-minus__prev {
  left: auto;
  right: 0;
}
.plus-minus__prev:before {
  left: auto;
  right: 0;
}
.plus-minus__prev:after {
  display: none;
}
.cart-form {
  padding: 0 0 0 30px;
}
.cart-form_delivery .input__field::-webkit-input-placeholder,
.cart-form_delivery .input__textarea::-webkit-input-placeholder {
  color: transparent;
}
.cart-form_delivery .input__field:-ms-input-placeholder,
.cart-form_delivery .input__textarea:-ms-input-placeholder {
  color: transparent;
}
.cart-form_delivery .input__field::-moz-placeholder,
.cart-form_delivery .input__textarea::-moz-placeholder {
  color: transparent;
}
@media (max-width:1300px) {
  .cart-form .input__label {
    font-size: 16px;
  }
  .cart-form .input__field {
    font-size: 16px;
  }
  .cart-form .input__textarea {
    font-size: 16px;
  }
  .cart-form .input__placeholder {
    font-size: 16px;
  }
  .cart-form .radio {
    padding: 0 0 7px;
  }
  .cart-form .radio__label {
    min-height: 22px;
    font-size: 16px;
    line-height: 22px;
  }
  .cart-form .radio__icon {
    top: 3px;
  }
}
@media (max-width:1024px) {
  .cart-form {
    padding-left: 0;
  }
}
@media (max-width:767px) {
  .cart-form {
    padding: 0 0 10px;
  }
  .cart-form_delivery .input__field,
  .cart-form_delivery .input__textarea {
    margin: 0;
  }
  .cart-form_delivery .input__field::-webkit-input-placeholder,
  .cart-form_delivery .input__textarea::-webkit-input-placeholder {
    color: #333;
  }
  .cart-form_delivery .input__field:-ms-input-placeholder,
  .cart-form_delivery .input__textarea:-ms-input-placeholder {
    color: #333;
  }
  .cart-form_delivery .input__field::-moz-placeholder,
  .cart-form_delivery .input__textarea::-moz-placeholder {
    color: #333;
  }
  .cart-form_delivery .input__label {
    display: none;
  }
}
.cart-form__bottom {
  padding: 10px 0 0;
}
@media (max-width:767px) {
  .cart-form__bottom {
    padding: 0;
  }
}
.cart-form__button {
  width: 100%;
  padding: 0;
  display: block;
  text-transform: uppercase;
}
@media (max-width:1300px) {
  .cart-form__button {
    height: 66px;
    font-size: 20px;
    line-height: 66px;
  }
}
@media (max-width:767px) {
  .cart-form__button {
    margin: 0 0 5px;
  }
}
.cart-form__cols {
  max-width: 643px;
  margin: 0 0 30px;
  display: flex;
  align-items: center;
}
.cart-form__cols .input {
  padding: 0;
}
.cart-form__cols .input__field {
  margin: 0;
}
.cart-form__cols .input__placeholder {
  opacity: 0;
  left: -9999px;
}
@media (max-width:1024px) {
  .cart-form__cols {
    max-width: none;
  }
}
@media (max-width:767px) {
  .cart-form__cols {
    margin: 0 0 10px;
    display: block;
  }
  .cart-form__cols .input__placeholder {
    opacity: 1;
    left: 0;
  }
}
.cart-form__left {
  width: 136px;
  flex-grow: 0;
  flex-shrink: 0;
}
@media (max-width:767px) {
  .cart-form__left {
    display: none;
  }
}
.cart-form__right {
  width: 100%;
  min-width: 0;
}
.cart-form__text {
  padding: 0 0 15px;
  display: block;
  font-size: 18px;
  line-height: 20px;
}
@media (max-width:1300px) {
  .cart-form__text {
    font-size: 16px;
  }
}
@media (max-width:767px) {
  .cart-form__text {
    padding: 0 0 8px;
  }
  .cart-form__text:first-child {
    margin-top: -2px;
  }
}
@media (max-width:767px) {
  .cart-form__content:first-child {
    margin: -4px 0 -16px;
  }
}
.cart-form__label {
  padding: 0 15px 0 0;
  display: block;
  font-size: 18px;
  line-height: 24px;
  user-select: none;
}
.cart-form__label span {
  color: #f00;
}
@media (max-width:1300px) {
  .cart-form__label {
    font-size: 16px;
  }
}
.cart-form__reg {
  padding: 0 0 22px 11px;
}
.cart-form__reg:last-child {
  padding-bottom: 0;
}
@media (max-width:1300px) {
  .cart-form__reg {
    padding-left: 0;
  }
}
@media (max-width:767px) {
  .cart-form__reg {
    padding-bottom: 11px;
    padding-left: 11px;
  }
}
.cart-form__delivery {
  padding: 0 0 16px;
}
.cart-form__delivery .radio {
  margin: 0 0 14px;
}
.cart-form__delivery .radio:last-child {
  margin: 0;
}
.cart-form__delivery:last-child {
  padding: 0 0 8px;
}
@media (max-width:1024px) {
  .cart-form__delivery .radio {
    margin: 0;
  }
}
@media (max-width:767px) {
  .cart-form__delivery {
    padding: 0 0 13px;
  }
  .cart-form__delivery:last-child {
    padding: 0 0 5px;
  }
}
.cart-form__pay {
  padding: 0 0 18px;
}
.cart-form__pay:last-child {
  padding-bottom: 0;
}
@media (max-width:767px) {
  .cart-form__pay {
    padding: 0 0 4px;
  }
  .cart-form__pay:first-child {
    margin-top: -5px;
  }
}
/*____________________4.8 DELIVERY____________________*/
.delivery-table {
  margin: 4px 0 30px;
  font-size: 22px;
  line-height: 30px;
}
@media (max-width:1500px) {
  .delivery-table {
    font-size: 20px;
    line-height: 28px;
  }
}
@media (max-width:1024px) {
  .delivery-table {
    font-size: 18px;
  }
}
@media (max-width:767px) {
  .delivery-table {
    margin-bottom: 24px;
    line-height: 25px;
  }
}
.delivery-table__item {
  margin: 0 -5px 0 0;
  display: flex;
  align-items: center;
}
.delivery-table__item:first-child .delivery-table__col {
  color: #fff;
  font-weight: bold;
}
.delivery-table__item:first-child .delivery-table__col:before {
  background: #21B11D;
}
@media (max-width:1024px) {
  .delivery-table__item:first-child .delivery-table__left {
    padding: 0 0 7px;
    display: block;
    font-weight: bold;
  }
}
.delivery-table__left {
  width: 100%;
  min-width: 0;
  padding: 0 36px;
}
@media (max-width:1500px) {
  .delivery-table__left {
    padding: 0 15px 0 0;
  }
}
@media (max-width:1024px) {
  .delivery-table__left {
    display: none;
  }
  .delivery-table__left span {
    display: none;
  }
}
.delivery-table__col {
  width: 195px;
  margin: 0 5px 0 0;
  padding: 15px 10px;
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: center;
}
.delivery-table__col:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #EBEBEB;
}
.delivery-table__col span {
  position: relative;
  z-index: 1;
}
.delivery-table__col:nth-child(3):before {
  opacity: 0.9;
}
.delivery-table__col:nth-child(4):before {
  opacity: 0.7;
}
.delivery-table__col:nth-child(5):before {
  opacity: 0.5;
}
.delivery-table__col:nth-child(6):before {
  opacity: 0.3;
}
@media (max-width:1500px) {
  .delivery-table__col {
    width: 160px;
  }
}
@media (max-width:1300px) {
  .delivery-table__col {
    width: 135px;
  }
}
@media (max-width:1024px) {
  .delivery-table__col {
    display: none;
  }
}
.delivery-item__tablet {
  display: none;
}
@media (max-width:1024px) {
  .delivery-item__tablet {
    display: block;
  }
}
.delivery-remark {
  margin: 0 0 18px;
  padding: 32px 50px 14px;
  background: #EBEBEB;
  font-size: 22px;
  line-height: 30px;
}
.delivery-remark ul > li {
  padding: 0 0 0 34px;
}
.delivery-remark ul > li:before {
  top: 14px;
  left: 13px;
  background: #333;
}
@media (max-width:1500px) {
  .delivery-remark {
    font-size: 20px;
    line-height: 28px;
  }
  .delivery-remark ul > li {
    padding: 0 0 0 28px;
  }
  .delivery-remark ul > li:before {
    top: 12px;
    left: 10px;
  }
}
@media (max-width:1024px) {
  .delivery-remark {
    margin: 0 0 22px;
    padding: 27px 30px 10px;
    font-size: 18px;
  }
  .delivery-remark p:first-child {
    font-size: 20px;
    line-height: 27px;
  }
}
@media (max-width:767px) {
  .delivery-remark {
    margin: 0 -15px 23px;
    padding: 23px 15px 5px;
    font-size: 16px;
    line-height: 22px;
  }
  .delivery-remark ul > li {
    padding: 0 0 0 24px;
  }
  .delivery-remark ul > li:before {
    top: 9px;
    left: 8px;
  }
}
.delivery-article__title {
  margin: 0 0 11px;
  font-weight: bold;
  font-size: 35px;
  line-height: 48px;
}
@media (max-width:1300px) {
  .delivery-article__title {
    font-size: 30px;
    line-height: 40px;
  }
}
@media (max-width:767px) {
  .delivery-article__title {
    margin: 0 0 8px;
    font-size: 20px;
    line-height: 27px;
  }
}
.delivery-article__text {
  font-size: 22px;
  line-height: 30px;
}
.delivery-article__text ul > li {
  padding: 0 0 0 34px;
}
.delivery-article__text ul > li:before {
  top: 14px;
  left: 13px;
  background: #333;
}
@media (max-width:1500px) {
  .delivery-article__text {
    font-size: 20px;
    line-height: 28px;
  }
  .delivery-article__text ul > li {
    padding: 0 0 0 28px;
  }
  .delivery-article__text ul > li:before {
    top: 12px;
    left: 10px;
  }
}
@media (max-width:1024px) {
  .delivery-article__text {
    font-size: 18px;
  }
}
@media (max-width:767px) {
  .delivery-article__text {
    font-size: 16px;
    line-height: 22px;
  }
  .delivery-article__text ul {
    margin: 0 0 15px;
  }
  .delivery-article__text ul > li {
    padding: 0 0 0 24px;
  }
  .delivery-article__text ul > li:before {
    top: 9px;
    left: 8px;
  }
}
/*____________________4.9 ABOUT COMPANY____________________*/
.about-us-page {
  padding: 0 0 11px;
}
@media (max-width:767px) {
  .about-us-page {
    padding: 0 0 3px;
  }
}
.about-us__title {
  margin: 0 0 13px;
  font-weight: bold;
  font-size: 35px;
  line-height: 48px;
}
@media (max-width:1300px) {
  .about-us__title {
    font-size: 30px;
    line-height: 40px;
  }
}
@media (max-width:767px) {
  .about-us__title {
    margin: 0 0 8px;
    font-size: 20px;
    line-height: 27px;
  }
}
.about-us__text {
  margin: 0 0 -3px;
}
.about-us__text a {
  text-decoration: none;
}
.desktop .about-us__text a:hover {
  text-decoration: underline;
}
/*-------------------------------------------------------------------------------------------*/
/* -----------------------------------------5. LIBS------------------------------------------*/
/*-------------------------------------------------------------------------------------------*/
/*____________________5.1 SLICK____________________*/
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;
}
.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}
.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
  border: none !important;
}
[dir='rtl'] .slick-slide {
  float: right;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
.slick-prev,
.slick-next {
  position: relative;
}
.slick-dots {
  margin: 0 -7px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.slick-dots li {
  padding: 0 7px 14px;
}
.slick-dots li.slick-active button {
  background: #21B11D;
}
.slick-dots li button {
  width: 17px;
  height: 17px;
  background: #fff;
  border: 1px solid #21B11D;
  border-radius: 50%;
}
.desktop .slick-dots li button:hover {
  background: #21B11D;
}
@media (max-width:767px) {
  .slick-dots {
    margin: 0 -3.5px;
  }
  .slick-dots li {
    padding-left: 3.5px;
    padding-right: 3.5px;
  }
  .slick-dots li button {
    background: #d9d9d9;
    border-color: transparent;
  }
}
/*____________________5.2 FANCYBOX____________________*/
body.compensate-for-scrollbar {
  overflow: hidden;
}
.desktop body.compensate-for-scrollbar .header {
  padding-right: 17px;
}
.fancybox-active {
  height: auto;
}
.mob .fancybox-active {
  overflow: hidden !important;
}
.fancybox-is-hidden {
  left: -9999px;
  margin: 0;
  position: absolute !important;
  top: -9999px;
  visibility: hidden;
}
.fancybox-container {
  -webkit-backface-visibility: hidden;
  height: 100%;
  left: 0;
  outline: none;
  position: fixed;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  transform: translateZ(0);
  width: 100%;
  z-index: 99992;
}
.fancybox-container * {
  box-sizing: border-box;
}
.fancybox-outer,
.fancybox-inner,
.fancybox-bg,
.fancybox-stage {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.fancybox-outer {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
}
.fancybox-bg {
  background: rgba(0, 0, 0, 0.75);
  opacity: 0;
  transition-duration: inherit;
  transition-property: opacity;
  /*transition-timing-function:cubic-bezier(.47, 0, .74, .71);*/
}
.fancybox-is-open .fancybox-bg {
  opacity: 1;
  /*transition-timing-function:cubic-bezier(.22, .61, .36, 1);*/
}
.fancybox-infobar,
.fancybox-toolbar,
.fancybox-caption,
.fancybox-navigation .fancybox-button {
  direction: ltr;
  opacity: 0;
  position: absolute;
  transition: opacity 0.25s ease, visibility 0s ease 0.25s;
  visibility: hidden;
  z-index: 99997;
}
.fancybox-show-infobar .fancybox-infobar,
.fancybox-show-toolbar .fancybox-toolbar,
.fancybox-show-caption .fancybox-caption,
.fancybox-show-nav .fancybox-navigation .fancybox-button {
  opacity: 1;
  transition: opacity 0.25s ease 0s, visibility 0s ease 0s;
  visibility: visible;
}
.fancybox-infobar {
  color: #ccc;
  font-size: 13px;
  -webkit-font-smoothing: subpixel-antialiased;
  height: 44px;
  left: 0;
  line-height: 44px;
  min-width: 44px;
  mix-blend-mode: difference;
  padding: 0 10px;
  pointer-events: none;
  top: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.fancybox-toolbar {
  right: 0;
  top: 0;
}
.fancybox-stage {
  direction: ltr;
  overflow: visible;
  transform: translateZ(0);
  z-index: 99994;
}
.fancybox-is-open .fancybox-stage {
  overflow: hidden;
}
.fancybox-slide {
  -webkit-backface-visibility: hidden;
  /* Using without prefix would break IE11 */
  display: none;
  height: 100%;
  left: 0;
  outline: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 0;
  position: absolute;
  text-align: center;
  top: 0;
  transition-property: transform, opacity;
  white-space: normal;
  width: 100%;
  z-index: 99994;
}
.fancybox-slide::before {
  content: '';
  display: inline-block;
  font-size: 0;
  height: 100%;
  vertical-align: middle;
  width: 0;
}
.fancybox-is-sliding .fancybox-slide,
.fancybox-slide--previous,
.fancybox-slide--current,
.fancybox-slide--next {
  display: block;
}
.fancybox-slide--image {
  overflow: hidden;
  padding: 0;
}
.fancybox-slide--image::before {
  display: none;
}
.fancybox-slide--html {
  padding: 85px 42px 40px;
}
.fancybox-content {
  display: inline-block;
  margin: 0;
  width: 100%;
  overflow: visible;
  -webkit-overflow-scrolling: touch;
  position: relative;
  text-align: left;
  vertical-align: middle;
}
.fancybox-slide--image .fancybox-content {
  animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
  -webkit-backface-visibility: hidden;
  background: transparent;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  left: 0;
  max-width: none;
  overflow: visible;
  padding: 0;
  position: absolute;
  top: 0;
  -ms-transform-origin: top left;
  transform-origin: top left;
  transition-property: transform, opacity;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 99995;
}
.fancybox-can-zoomOut .fancybox-content {
  cursor: zoom-out;
}
.fancybox-can-zoomIn .fancybox-content {
  cursor: zoom-in;
}
.fancybox-can-swipe .fancybox-content,
.fancybox-can-pan .fancybox-content {
  cursor: -webkit-grab;
  cursor: grab;
}
.fancybox-is-grabbing .fancybox-content {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.fancybox-container [data-selectable='true'] {
  cursor: text;
}
.fancybox-image,
.fancybox-spaceball {
  background: transparent;
  border: 0;
  height: 100%;
  left: 0;
  margin: 0;
  max-height: none;
  max-width: none;
  padding: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
}
.fancybox-spaceball {
  z-index: 1;
}
.fancybox-slide--video .fancybox-content,
.fancybox-slide--map .fancybox-content,
.fancybox-slide--pdf .fancybox-content,
.fancybox-slide--iframe .fancybox-content {
  height: 100%;
  overflow: visible;
  padding: 0;
  width: 100%;
}
.fancybox-slide--video .fancybox-content {
  background: #000;
  max-width: 800px;
  max-height: 500px;
}
.fancybox-slide--map .fancybox-content {
  background: #e5e3df;
}
.fancybox-slide--iframe .fancybox-content {
  background: #fff;
}
.fancybox-video,
.fancybox-iframe {
  background: transparent;
  border: 0;
  display: block;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%;
}
/* Fix iOS */
.fancybox-iframe {
  left: 0;
  position: absolute;
  top: 0;
}
.fancybox-error {
  background: #fff;
  cursor: default;
  max-width: 400px;
  padding: 40px;
  width: 100%;
}
.fancybox-error p {
  color: #444;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding: 0;
}
/* Buttons */
.fancybox-button {
  width: 40px;
  height: 40px;
  position: relative;
  cursor: pointer;
  display: block;
  background: #fff;
  border: 1px solid #F2F3F4;
  border-radius: 50%;
  text-align: center;
  color: #2a2c31;
  transition: all linear 0.3s !important;
}
.desktop .fancybox-button:hover {
  border-color: #21B11D;
  color: #21B11D;
}
.fancybox-button:focus {
  outline: none;
}
.fancybox-button.fancybox-focus {
  outline: 1px dotted;
}
/* .fancybox-button[disabled],.fancybox-button[disabled]:hover{color:#888;cursor:default;outline:none;} */
.fancybox-button--zoom {
  display: none !important;
}
/* Fix IE11 */
.fancybox-button div {
  height: 100%;
}
.fancybox-button svg {
  display: none;
  height: 100%;
  overflow: visible;
  position: relative;
  width: 100%;
}
.fancybox-button svg path {
  fill: #fff;
  stroke-width: 0;
}
.fancybox-button--play svg:nth-child(2),
.fancybox-button--fsenter svg:nth-child(2) {
  display: none;
}
.fancybox-button--pause svg:nth-child(1),
.fancybox-button--fsexit svg:nth-child(1) {
  display: none;
}
.fancybox-progress {
  background: #ff5268;
  height: 2px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -ms-transform-origin: 0;
  transform-origin: 0;
  transition-property: transform;
  transition-timing-function: linear;
  z-index: 99998;
}
/* Close button on the top right corner of html content */
.fancybox-close-small {
  background: transparent;
  border: 0;
  border-radius: 0;
  color: #ccc;
  cursor: pointer;
  opacity: 0.8;
  padding: 8px;
  position: absolute;
  right: -12px;
  top: -44px;
  z-index: 401;
}
.fancybox-close-small:hover {
  color: #fff;
  opacity: 1;
}
.fancybox-slide--html .fancybox-close-small {
  color: currentColor;
  padding: 10px;
  right: 0;
  top: 0;
}
.fancybox-slide--image.fancybox-is-scaling .fancybox-content {
  overflow: hidden;
}
.fancybox-is-scaling .fancybox-close-small,
.fancybox-is-zoomable.fancybox-can-pan .fancybox-close-small {
  display: none;
}
.fancybox-slide--image.fancybox-is-scaling .fancybox-content {
  overflow: hidden;
}
.fancybox-is-scaling .fancybox-close-small,
.fancybox-is-zoomable.fancybox-can-pan .fancybox-close-small,
.fancybox-is-scaling .fancybox-prev,
.fancybox-is-scaling .fancybox-next,
.fancybox-is-zoomable.fancybox-can-pan .fancybox-prev,
.fancybox-is-zoomable.fancybox-can-pan .fancybox-next {
  display: none !important;
}
/* Navigation arrows */
.fancybox-navigation .fancybox-button {
  opacity: 0;
  position: absolute;
  top: 50%;
  margin: -20px 0 0;
}
.fancybox-navigation .fancybox-button--arrow_left {
  left: 16px;
}
.fancybox-navigation .fancybox-button--arrow_left:before {
  content: "\e900";
  font: 11px/40px 'icons';
}
.fancybox-navigation .fancybox-button--arrow_right {
  right: 16px;
}
.fancybox-navigation .fancybox-button--arrow_right:before {
  content: "\e901";
  font: 11px/40px 'icons';
}
/* Caption */
.fancybox-caption {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0.15) 65%, rgba(0, 0, 0, 0.075) 75.5%, rgba(0, 0, 0, 0.037) 82.85%, rgba(0, 0, 0, 0.019) 88%, rgba(0, 0, 0, 0) 100%);
  bottom: 0;
  color: #eee;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  line-height: 1.5;
  padding: 75px 44px 25px 44px;
  pointer-events: none;
  right: 0;
  text-align: center;
  z-index: 99996;
}
.fancybox-caption--separate {
  margin-top: -50px;
}
.fancybox-caption__body {
  max-height: 50vh;
  overflow: auto;
  pointer-events: all;
}
.fancybox-caption a,
.fancybox-caption a:link,
.fancybox-caption a:visited {
  color: #ccc;
  text-decoration: none;
}
.fancybox-caption a:hover {
  color: #fff;
  text-decoration: underline;
}
/* Loading indicator */
.fancybox-loading {
  animation: fancybox-rotate 1s linear infinite;
  background: transparent;
  border: 4px solid #888;
  border-bottom-color: #fff;
  border-radius: 50%;
  height: 50px;
  left: 50%;
  margin: -25px 0 0 -25px;
  opacity: 0.7;
  padding: 0;
  position: absolute;
  top: 50%;
  width: 50px;
  z-index: 99999;
}
@keyframes fancybox-rotate {
  100% {
    transform: rotate(360deg);
  }
}
/* Transition effects */
.fancybox-animated {
  transition-timing-function: cubic-bezier(0, 0, 0.25, 1);
}
/* transitionEffect:slide */
.fancybox-fx-slide.fancybox-slide--previous {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}
.fancybox-fx-slide.fancybox-slide--next {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}
.fancybox-fx-slide.fancybox-slide--current {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
/* transitionEffect:fade */
.fancybox-fx-fade.fancybox-slide--previous,
.fancybox-fx-fade.fancybox-slide--next {
  opacity: 0;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
.fancybox-fx-fade.fancybox-slide--current {
  opacity: 1;
}
/* transitionEffect:zoom-in-out */
.fancybox-fx-zoom-in-out.fancybox-slide--previous {
  opacity: 0;
  transform: scale3d(1.5, 1.5, 1.5);
}
.fancybox-fx-zoom-in-out.fancybox-slide--next {
  opacity: 0;
  transform: scale3d(0.5, 0.5, 0.5);
}
.fancybox-fx-zoom-in-out.fancybox-slide--current {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}
/* transitionEffect:rotate */
.fancybox-fx-rotate.fancybox-slide--previous {
  opacity: 0;
  -ms-transform: rotate(-360deg);
  transform: rotate(-360deg);
}
.fancybox-fx-rotate.fancybox-slide--next {
  opacity: 0;
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}
.fancybox-fx-rotate.fancybox-slide--current {
  opacity: 1;
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}
/* transitionEffect:circular */
.fancybox-fx-circular.fancybox-slide--previous {
  opacity: 0;
  transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
}
.fancybox-fx-circular.fancybox-slide--next {
  opacity: 0;
  transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
}
.fancybox-fx-circular.fancybox-slide--current {
  opacity: 1;
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
}
/* transitionEffect:tube */
.fancybox-fx-tube.fancybox-slide--previous {
  transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
}
.fancybox-fx-tube.fancybox-slide--next {
  transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg);
}
.fancybox-fx-tube.fancybox-slide--current {
  transform: translate3d(0, 0, 0) scale(1);
}
/* Styling for Small-Screen Devices */
@media all and (max-height: 576px) {
  .fancybox-slide--image {
    padding: 0;
  }
  .fancybox-close-small {
    right: -6px;
  }
  .fancybox-slide--image .fancybox-close-small {
    background: #4e4e4e;
    color: #f2f4f6;
    height: 36px;
    opacity: 1;
    padding: 6px;
    right: 0;
    top: 0;
    width: 36px;
  }
  .fancybox-caption {
    padding-left: 12px;
    padding-right: 12px;
  }
}
/* Share */
.fancybox-share {
  background: #f4f4f4;
  border-radius: 3px;
  max-width: 90%;
  padding: 30px;
  text-align: center;
}
.fancybox-share h1 {
  color: #222;
  font-size: 35px;
  font-weight: 700;
  margin: 0 0 20px 0;
}
.fancybox-share p {
  margin: 0;
  padding: 0;
}
.fancybox-share__button {
  border: 0;
  border-radius: 3px;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  line-height: 40px;
  margin: 0 5px 10px 5px;
  min-width: 130px;
  padding: 0 15px;
  text-decoration: none;
  transition: all 0.2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
}
.fancybox-share__button:visited,
.fancybox-share__button:link {
  color: #fff;
}
.fancybox-share__button:hover {
  text-decoration: none;
}
.fancybox-share__button--fb {
  background: #3b5998;
}
.fancybox-share__button--fb:hover {
  background: #344e86;
}
.fancybox-share__button--pt {
  background: #bd081d;
}
.fancybox-share__button--pt:hover {
  background: #aa0719;
}
.fancybox-share__button--tw {
  background: #1da1f2;
}
.fancybox-share__button--tw:hover {
  background: #0d95e8;
}
.fancybox-share__button svg {
  height: 25px;
  margin-right: 7px;
  position: relative;
  top: -1px;
  vertical-align: middle;
  width: 25px;
}
.fancybox-share__button svg path {
  fill: #fff;
}
.fancybox-share__input {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #d7d7d7;
  border-radius: 0;
  color: #5d5b5b;
  font-size: 14px;
  margin: 10px 0 0 0;
  outline: none;
  padding: 10px 15px;
  width: 100%;
}
/* Thumbs */
.fancybox-thumbs {
  background: #ddd;
  bottom: 0;
  display: none;
  margin: 0;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding: 2px 2px 4px 2px;
  position: absolute;
  right: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  top: 0;
  width: 212px;
  z-index: 99995;
}
.fancybox-thumbs-x {
  overflow-x: auto;
  overflow-y: hidden;
}
.fancybox-show-thumbs .fancybox-thumbs {
  display: block;
}
.fancybox-show-thumbs .fancybox-inner {
  right: 212px;
}
.fancybox-thumbs__list {
  font-size: 0;
  height: 100%;
  list-style: none;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  position: relative;
  white-space: nowrap;
  width: 100%;
}
.fancybox-thumbs-x .fancybox-thumbs__list {
  overflow: hidden;
}
.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar {
  width: 7px;
}
.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-thumb {
  background: #2a2a2a;
  border-radius: 10px;
}
.fancybox-thumbs__list a {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: rgba(0, 0, 0, 0.1);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  float: left;
  height: 75px;
  margin: 2px;
  max-height: calc(100% - 8px);
  max-width: calc(50% - 4px);
  outline: none;
  overflow: hidden;
  padding: 0;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  width: 100px;
}
.fancybox-thumbs__list a::before {
  border: 6px solid #ff5268;
  bottom: 0;
  content: '';
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: 99991;
}
.fancybox-thumbs__list a:focus::before {
  opacity: 0.5;
}
.fancybox-thumbs__list a.fancybox-thumbs-active::before {
  opacity: 1;
}
.fancybox-button--close {
  margin: 16px 16px 0 0;
  padding: 5px;
  display: block;
  position: relative;
  border-radius: 50%;
  opacity: 1;
}
.fancybox-button--close:before,
.fancybox-button--close:after {
  content: "";
  width: 18px;
  height: 2px;
  position: absolute;
  top: 18px;
  left: 10px;
  background: #2a2c31;
  transform: rotate(45deg);
  transition: all linear 0.3s;
}
.fancybox-button--close:after {
  transform: rotate(-45deg);
}
.fancybox-button--close:hover {
  border-color: #21B11D;
}
.fancybox-button--close:hover:before,
.fancybox-button--close:hover:after {
  background: #21B11D;
}
/* Styling for Small-Screen Devices */
@media all and (max-width: 767px) {
  .fancybox-button {
    width: 32px;
    height: 32px;
  }
  .fancybox-navigation .fancybox-button:before,
  .fancybox-navigation .fancybox-button:after {
    line-height: 32px;
  }
  .fancybox-button--close:before,
  .fancybox-button--close:after {
    top: 14px;
    left: 6px;
  }
  .fancybox-navigation .fancybox-button {
    margin: -16px 0 0;
  }
}
@media all and (max-width: 576px) {
  .fancybox-thumbs {
    width: 110px;
  }
  .fancybox-show-thumbs .fancybox-inner {
    right: 110px;
  }
  .fancybox-thumbs__list a {
    max-width: calc(100% - 10px);
  }
}
/*____________________5.3 RATING____________________*/
.star-rating {
  width: 24px !important;
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  position: relative;
  background: transparent !important;
  overflow: hidden !important;
}
.star-rating a {
  width: 24px !important;
  height: 24px;
  display: block;
  text-indent: -9999px;
  border: none;
}
.star-rating a:before {
  content: "";
  width: 23px;
  height: 23px;
  margin: -11.5px 0 0 -11.5px;
  position: absolute;
  top: 50%;
  left: 50%;
  background: url(../img/svg/star.svg) center center no-repeat;
  background-size: 100% 100%;
}
.star-rating-on a:before {
  background: url(../img/svg/star-active.svg) center center no-repeat;
  background-size: 100% 100%;
}
.star-rating-hover a:before {
  background: url(../img/svg/star-active.svg) center center no-repeat !important;
  background-size: 100% 100% !important;
}
.star-rating-readonly a {
  cursor: default;
}
.rating-cancel {
  display: none !important;
}
.rating_large .star-rating {
  width: 34px !important;
}
.rating_large .star-rating a {
  width: 34px !important;
  height: 34px;
}
.rating_large .star-rating a:before {
  width: 32px;
  height: 32px;
  margin: -16px 0 0 -16px;
}
