/*--global--*/
@import "vars";
@import "mixins";

/*
1. Reset CSS
2. Fonts
3. Basic styles, components
    3.1 Global
	3.2 Tags
    3.3 Basic elements
        3.3.1 Text fields
        3.3.2 Checkboxes
        3.3.3 Radio
        3.3.4 Selects, dropdowns
        3.3.5 Buttons
    3.4 Basic sections
        3.4.1 Header
        3.4.2 Footer
        3.4.3 Mobile navigation    
        3.4.4 Inner page
        3.4.5 Popups
    3.5 Content
4. Pages and sections
    4.1 Index
    4.2 Catalog
    4.3 Pilomaterials
    4.4 Inner article
    4.5 Contacts
    4.6 Card
    4.7 Basket
    4.8 Delivery
    4.9 About company
5. Libs
    5.1 Slick
    5.2 Fancybox  
    5.3 Rating
*/

/*------------------------------------------------------------------------------------------*/
/*----------------------------------------1. RESET CSS--------------------------------------*/
/*------------------------------------------------------------------------------------------*/
html,body,div,span,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,cite,code,em,font,img,q,s,small,strong,b,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,section,nav,header,footer,main,figure,figcaption,fgroup,time,hr{font-size:100%;margin:0;padding:0;vertical-align:baseline;border:0;outline:0;background:transparent;}
article,aside,section,nav,header,footer,main,figure,figcaption,fgroup,time,hr,picture{display:block;}
html{-webkit-text-size-adjust:none;-ms-text-size-adjust:none;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;-webkit-tap-highlight-color:rgba(0, 0, 0, .1);}
body{line-height:1;}
input::-moz-focus-inner{padding:0;border:0;}
button::-moz-focus-inner{padding:0;border:0;}
[type=submit], [type=button], [type=reset], button{cursor:pointer; -webkit-appearance:none; background-color:transparent;}
input,textarea,button{margin:0;padding:0;border:none;border-radius:0;outline:none;background-color:transparent;box-shadow:none;-webkit-appearance:none;-webkit-focus-ring-color:none;}
button{overflow:visible;}
textarea{overflow:auto;} 
blockquote,q{quotes:none;}
blockquote:before,blockquote:after,q:before,q:after{content:'';}
:focus{outline:0;}
:disabled{cursor:default;}
table{border-spacing:0;border-collapse:collapse;}
*,*:before,*:after{-webkit-box-sizing:border-box; box-sizing:border-box;}
img{display:block; user-select:none;}



/*------------------------------------------------------------------------------------------*/
/*----------------------------------------2. FONTS------------------------------------------*/
/*------------------------------------------------------------------------------------------*/



/*------------------------------------------------------------------------------------------*/
/* -------------------------------3. BASIC STYLES, COMPONENTS-------------------------------*/
/*------------------------------------------------------------------------------------------*/
/*____________________3.1 GLOBAL____________________*/
html{height:100% !important; margin:auto; position:relative;}

body{
    min-width:320px; height:100% !important; position:relative; font:@base-font-weight @base-font-size/@base-line-height @base-font-family; background:@base-bg; color:@text-color;
    @media @lg-md-max{
        &{font-size:16px;}
    }
}

.page-container{
	max-width:@page-max-width; min-height:100%; margin:auto; display:flex; flex-direction:column; overflow:hidden; position:relative; 
	.ie &{display:block;}
}

.main{
    width:100%; min-width:0; padding:169px 0 0; flex:1 0 auto; position:relative;
    @media @md-max{
        &{padding:71px 0 0;}
    }
    @media @sm-max{
        &{padding:48px 0 0;}
    }
}

.container{
    width:100%; min-width:@min-width; max-width:@max-width; margin-right:auto; margin-left:auto; padding-right:@side-padding; padding-left:@side-padding;
    @media @md-max{
        &{padding-right:@side-padding-md; padding-left:@side-padding-md;}
    }

    @media @sm-max{
        &{padding-right:@side-padding-sm; padding-left:@side-padding-sm;}
    }
}

/*____________________3.2 TAGS____________________*/
.title-1{font:@font-weight-h1 @font-size-h1/@line-height-h1 @font-family-h1;}
.title-2{font:@font-weight-h2 @font-size-h2/@line-height-h2 @font-family-h2;}
.title-3{font:@font-weight-h3 @font-size-h3/@line-height-h3 @font-family-h3;}
.title-4{font:@font-weight-h4 @font-size-h4/@line-height-h4 @font-family-h4;}
.title-5{font:@font-weight-h5 @font-size-h5/@line-height-h5 @font-family-h5;}
.title-6{font:@font-weight-h6 @font-size-h6/@line-height-h6 @font-family-h6;}

h1{
    margin:0 0 30px; .title-1;
    @media @lg-md-max{
        &{font-size:36px; line-height:48px;}
    }
    @media @md-max{
        &{margin:0 0 24px; font-size:32px; line-height:44px;}
    }
    @media @sm-max{
        &{margin:0 0 20px; font-size:25px; line-height:34px;}
    }
}
h2{
    margin:0 0 30px; .title-2;
    @media @lg-md-max{
        &{font-size:36px; line-height:48px;}
    }
    @media @md-max{
        &{margin:0 0 24px; font-size:32px; line-height:44px;}
    }
    @media @sm-max{
        &{margin:0 0 20px; font-size:25px; line-height:34px;}
    }
}
h3{
    margin:0 0 30px; .title-3;
    @media @lg-md-max{
        &{font-size:36px; line-height:48px;}
    }
    @media @md-max{
        &{margin:0 0 24px; font-size:32px; line-height:44px;}
    }
    @media @sm-max{
        &{margin:0 0 20px; font-size:25px; line-height:34px;}
    }
}
h4{
    margin:0 0 30px; .title-4;
    @media @lg-md-max{
        &{font-size:22px; line-height:30px;}
    }
    @media @md-max{
        &{margin:0 0 24px; font-size:20px; line-height:28px;}
    }
    @media @sm-max{
        &{margin:0 0 20px; font-size:16px; line-height:25px;}
    }
}
h5{
    margin:0 0 30px; .title-5;
    @media @lg-md-max{
        &{font-size:18px;}
    }
    @media @md-max{
        &{margin:0 0 24px;}
    }
    @media @sm-max{
        &{margin:0 0 20px;}
    }
}
h6{
    margin:0 0 30px; .title-6;
    @media @lg-md-max{
        &{font-size:16px;}
    }
    @media @md-max{
        &{margin:0 0 24px;}
    }
    @media @sm-max{
        &{margin:0 0 20px;}
    }
}

ul{
    margin:0 0 20px;
    > li{list-style:none;}
}

ol{
    margin:0 0 20px; list-style:none; counter-reset:item;
    > li{
        &:before{content:counter(item, decimal)". "; counter-increment:item;}
    }
}

p{margin:0 0 20px;}

img{max-width:100%; height:auto; margin:0 0 20px;}

a{color:@link-color; text-decoration:none; transition:0.3s linear;}

button, input[type=submit], input[type=reset]{transition:0.3s linear;}
button{user-select:none}

/*____________________3.3 BASIC ELEMENTS____________________*/
/* 3.3.1 Text fields */
*::-moz-placeholder{opacity:1; color:@black;}
*::-webkit-input-placeholder{line-height:normal; opacity:1; color:@black;}
*:-ms-input-placeholder{opacity:1; color:@black;}

.input{padding:0 0 10px;}
.input__label{padding:0 0 7px; display:block; font-size:18px; line-height:20px; user-select:none;}
.input__field{
    width:100%; height:47px; margin:0 0 10px; padding:0 20px; display:block; background:#fff; border:1px solid #CDCDCD; font:18px/1 @font; color:@black;
    &:focus{
        border-color:#000;
        & + .input__placeholder{display:none;}
    }
    &.active{
        & + .input__placeholder{display:none;}
    }
}
.input__textarea{
    width:100%; height:142px; min-height:142px; margin:0 0 10px; padding:12px 20px; display:block; background:#fff; border:1px solid #CDCDCD; font:18px/24px @font; color:@black; resize:none;
    &:focus{
        border-color:#000;
        & + .input__placeholder{display:none;}
    }
    &.active{
        & + .input__placeholder{display:none;}
    }
    &_small{height:83px; min-height:83px;}
}
.input__placeholder-wrap{
    position:relative;
    & .input__field::-moz-placeholder{color:transparent;}
    & .input__field::-webkit-input-placeholder{color:transparent;}
    & .input__field:-ms-input-placeholder{color:transparent;}
}
.input__placeholder{
    width:100%; height:100%; padding:0 20px; position:absolute; top:0; left:0; z-index:1; font:18px/47px @font; cursor:text;
    & span{color:@red;}
}

/* 3.3.2 Checkboxes */
.check{width:63px;}
.check__label{
    margin:0 0 5px; position:relative; display:block; font:16px/26px @font; .transition; cursor:pointer; text-align:center; background:@green; color:#fff;
    .desktop &:hover{background:@green-hover;}
    &:last-child{margin:0;}
}
.check__input{
    display:none;
    &:checked + .check__icon{
        background:#31722F;
        &:before{opacity:1;}
        & + .check__text{padding-left:26px; text-align:left;}
    }
}
.check__icon{
    width:100%; height:100%; position:absolute; top:0; left:0;
    &:before{content:""; width:14px; height:11px; position:absolute; top:7px; left:7px; background:url(../img/svg/check.svg) no-repeat; background-size:100% 100%; opacity:0;}
}
.check__text{display:block; position:relative; z-index:1;}

/* 3.3.3 Radio */
.radio{
    padding:0 0 5px;
    @media @sm-max{
        &_inline-mob{
            margin-right:44px; display:inline-block; vertical-align:top;
            &:last-child{margin-right:0;}
        }
    }
}
.radio__label{
    min-height:24px; padding:0 0 0 26px; position:relative; display:block; font:18px/24px @font; .transition; cursor:pointer;
    .desktop &:hover{color:@green;}
}
.radio__input{
    display:none;
    &:checked + .radio__icon{
        &:before{opacity:1;}
    }
}
.radio__icon{
    width:16px; height:16px; padding:2px; position:absolute; top:4px; left:0; border:1px solid @green; border-radius:50%;
    &:before{content:""; height:100%; display:block; background:@green; border-radius:50%; opacity:0;}
}

/* 3.3.4 Selects, dropdowns */


/* 3.3.5 Buttons */
.button{
    max-width:100%; height:66px; margin:0 0 20px; padding:0 45px; display:inline-block; vertical-align:top; overflow:hidden; white-space:nowrap; text-overflow:ellipsis; background:@green; font:bold 20px/66px @font; color:#fff; text-align:center; user-select:none;
    .desktop &:hover{background:@green-hover;} 
    &_small{height:39px; padding:0 20px; font-weight:normal; font-size:18px; line-height:39px;}  
    &_dark{
        background:#5A5A5A;
        .desktop &:hover{background:@black;}
    }
    @media @lg-md-max{
        &{
            height:56px; font-size:18px; line-height:56px;
            &_small{height:38px; font-size:15px; line-height:38px;}  
        }
    }
}
.button__arrow{
    padding:0 54px 0 0; display:inline-block; position:relative;
    &:after{content:""; width:33px; height:17px; margin:-8.5px 0 0; position:absolute; top:50%; right:0; background:url(../img/svg/arrow-button.svg) no-repeat; background-size:100% 100%;}
}
.button__cart{
    padding:0 0 0 35px; display:inline-block; position:relative;
    &:before{content:""; width:25px; height:22px; margin:-11px 0 0; position:absolute; top:50%; left:-5px; background:url(../img/svg/basket-white.svg) no-repeat; background-size:100% 100%;}
}

.arrow-button{
    width:48px; height:48px; display:block; position:relative;
    &:before, &:after{content:""; width:100%; height:100%; position:absolute; top:0; left:0; background-repeat:no-repeat; background-size:100% 100%; .transition(all,0.3s);}
    &:before{background-image:url(../img/svg/arrow-slider.svg);}
    &:after{background-image:url(../img/svg/arrow-slider-hover.svg); opacity:0; z-index:1;}
    .desktop &:hover{
        &:before{opacity:0;}
        &:after{opacity:1;}
    }
    &_next{.scale(-1,1);}
}

.tg-button{
    width:18px; height:15px; display:block; position:relative;
    &:before, &:after{content:""; width:100%; height:100%; position:absolute; top:0; left:0; background-repeat:no-repeat; background-size:100% 100%; .transition(all,0.3s);}
    &:before{background-image:url(../img/svg/telegram.svg);}
    &:after{background-image:url(../img/svg/telegram-hover.svg); opacity:0; z-index:1;}
    .desktop &:hover{
        &:before{opacity:0;}
        &:after{opacity:1;}
    }
}

.whatsapp-button{
    width:15px; height:15px; display:block; position:relative;
    &:before, &:after{content:""; width:100%; height:100%; position:absolute; top:0; left:0; background-repeat:no-repeat; background-size:100% 100%; .transition(all,0.3s);}
    &:before{background-image:url(../img/svg/whatsapp.svg);}
    &:after{background-image:url(../img/svg/whatsapp-hover.svg); opacity:0; z-index:1;}
    .desktop &:hover{
        &:before{opacity:0;}
        &:after{opacity:1;}
    }
}

.burger-button{
    width:54px; height:27px; display:block; position:relative; right:15px;
    &:before, &:after, &__icon{content:""; width:24px; height:2px; margin:0 0 0 -12px; position:absolute; top:5px; left:50%; background:#000; .transition(all,0.3s);}
    &:before{margin-top:2px;}
    &:after{margin-top:10px;}
    &__icon{margin-top:18px;}
    .desktop &:hover{
        &:before, &:after, & .burger-button__icon{background:@green;}
    }
    &.active{
        &:before{width:28px; margin-top:10px; .rotate(45deg);}
        &:after{width:0; opacity:0;}
        & .burger-button__icon{width:26px; margin-top:10px; .rotate(-45deg);}
    }
}

.remove-button{
    padding:3px 20px 3px 0; display:inline-block; vertical-align:top; position:relative; font:18px/1 @font;  color:@red; user-select:none; white-space:nowrap;
    &:before, &:after{content:""; width:14px; height:2px; position:absolute; top:50%; right:0; background:@red; .rotate(45deg);}
    &:after{.rotate(-45deg);}
    .desktop &:hover{
        & .remove-button__text{border-color:transparent;}
    }
    @media @md-max{
        &{font-size:16px;}
    }
    @media @sm-max{
        &{
            padding:0 18px 0 0; font-size:14px;
            &:before, &:after{width:13px; margin-top:-1px;}
        }
    }
}
.remove-button__text{display:inline-block; border-bottom:1px solid #ff9f9f;}

.x-button{
    width:36px; height:36px; display:inline-block; vertical-align:top; position:relative; border:3px solid @red; border-radius:50%;
    &:before, &:after{content:""; width:14px; height:2px; margin:-1px 0 0 -7px; position:absolute; top:50%; left:50%; background:@red; .rotate(45deg); .transition;}
    &:after{.rotate(-45deg);}
    .desktop &:hover{
        border-color:@black;
        &:before, &:after{background:@black;}
    }
}

/*____________________3.4 BASIC SECTIONS____________________*/
/* 3.4.1 Header */
.header{
    width:100%; min-width:0; flex:0 0 auto; position:fixed; top:0; left:0; z-index:99; background:#fff; box-shadow:0px 4px 4px rgba(0, 0, 0, 0.1); .transition(box-shadow, 0.3s);
    &.active{box-shadow:0px 4px 4px rgba(0, 0, 0, 0);}
    &.scrolled{box-shadow:0px 4px 4px rgba(0, 0, 0, 0.1);}
}
.header__top{
    min-height:119px; margin:0 -15px; padding:0 0 15px; display:flex; justify-content:space-between;
    @media @md-max{
        &{min-height:0; padding:0;}
    }
}
.header__bottom{
    margin:0 -15px; display:flex; justify-content:space-between;
    @media @md-max{
        &{display:none;}
    }
}
.header__col{
    padding:0 15px;
    &_burger{display:none;}
    &_logo{padding-top:22px; flex-grow:0; flex-shrink:0;}
    &_search{width:100%; max-width:478px; padding-top:41px;}
    &_call{padding-top:31px; flex-grow:0; flex-shrink:0;}
    &_info{padding-top:29px; flex-grow:0; flex-shrink:0;}
    &_cart{padding-top:43px; flex-grow:0; flex-shrink:0;}
    @media @lg-md-max{
        &{
            &_burger{display:block; position:absolute; bottom:15px; left:15px;}
        }
    }
    @media @md-max{
        &{
            &_burger{padding-top:20px; position:static; left:auto; bottom:auto;}
            &_logo{display:none;}
            &_search{display:none;}
            &_call{display:none;}
            &_info{display:none;}
            &_cart{padding-top:20px; padding-bottom:20px;}
        }
    }
    @media @sm-max{
        &{
            &_burger{padding-top:8px;}
            &_cart{padding-top:10px; padding-bottom:7px;}
        }
    }
}
.header__button{
    height:32px; margin:3px 0 0 27px; padding:0 25px; font-weight:normal; font-size:16px; line-height:32px;
    @media @lg-md-max{
        &{height:32px; font-size:16px; line-height:32px;}
    }
}

.header-logo{display:flex; align-items:center;}
.header-logo__image{max-width:102px; margin:0;}
.header-logo__text{
    padding:0 0 0 14px; font-weight:bold; font-size:16px; line-height:22px; color:@black; text-transform:uppercase;
    @media @lg-md-max{
        &{display:none;}
    }
}

.search{position:relative;}
.search__input{
    width:100%; height:42px; padding:0 50px 0 20px; display:block; font:16px/1 @font; color:@black; border:1px solid #D7D7D7;
    &:focus{border-color:@black;}
    &::-moz-placeholder{color:#777;}
    &::-webkit-input-placeholder{color:#777;}
    &:-ms-input-placeholder{color:#777;}
}
.search__button{
    width:51px; height:100%; position:absolute; top:0; right:0; z-index:1;
    &:before, &:after{content:""; width:23px; height:23px; margin:-11.5px 0 0 -11.5px; position:absolute; top:50%; left:50%; background-repeat:no-repeat; background-size:100% 100%; .transition(all,0.3s);}
    &:before{background-image:url(../img/svg/search.svg);}
    &:after{background-image:url(../img/svg/search-hover.svg); opacity:0; z-index:1;}
    .desktop &:hover{
        &:before{opacity:0;}
        &:after{opacity:1;}
    }
}

.info{
    &_center{
        & .info__item{justify-content:center; text-align:center;}
    }
}
.info__item{
    padding:0 0 5px; display:flex; align-items:center; font-size:16px; line-height:19px; text-align:left;
    &_small{padding:0 0 3px; font-size:14px;}
    &_large{
        padding:0 0 9px; font-size:20px; line-height:26px;
        & .info__icon-col{width:20px; height:22px;}
        & .info__icon{
            &_2{
                &:before{width:20px; height:15px;}
            }
            &_3{
                &:before{width:20px; height:19px;}
            }
            &_4{
                &:before{width:15px; height:21px;}
            }
            &_5{
                &:before{width:20px; height:20px;}
            }
        }
        & .info__app-col{margin:0 0 0 8px;}
        & .info__tel{font-size:22px;}
        & .tg-button{width:25px; height:21px;}
        & .whatsapp-button{width:21px; height:21px;}
    }
}
.info__icon-col{width:17px; height:18px; margin:0 10px 0 0;}
.info__icon{
    height:100%; display:block; display:flex; flex-direction:column; justify-content:center; align-items:center;
    &:before{content:""; margin:auto; display:block; position:relative; background-repeat:no-repeat; background-size:100% 100%;}
    &_1{
        &:before{width:18px; height:18px; background-image:url(../img/svg/tel.svg);}
    }
    &_2{
        &:before{width:16px; height:12px; background-image:url(../img/svg/mail.svg);}
    }
    &_3{
        &:before{width:17px; height:17px; background-image:url(../img/svg/clock.svg);}
    }
    &_4{
        &:before{width:13px; height:18px; background-image:url(../img/svg/loc.svg);}
    }
    &_5{
        &:before{width:14px; height:14px; background-image:url(../img/svg/phone.svg);}
    }
}
.info__text-col{
    & br{display:none;}
    @media @xsm-max{
        & br{display:block;}
    }
}
.info__app-col{margin:0 0 0 7px;}
.info__tel{
    padding:0 3px 0 0; color:@black; font-weight:bold; font-size:20px; line-height:24px; white-space:nowrap;
    .desktop &:hover{color:@green;}
    &_small{font-size:18px;}
    @media @md-max{
        &{font-size:16px;}
    }
}
.info__mail{
    color:@black;
    .desktop &:hover{color:@green;}
}
.info__link{
    display:inline-block; border-bottom:1px solid #ace2ab; transition:none; line-height:14px;
    .desktop &:hover{border-color:transparent;}
}

.header-cart{
    width:51px; height:45px; margin:0 14px 0 0; display:block; position:relative;
    &:before{content:""; width:100%; height:100%; position:absolute; top:0; left:0; background:url(../img/svg/basket.svg) no-repeat; background-size:100% 100%; .transition(all,0.3s);}
    .desktop &:hover{
        & .header-cart__count{background:@green-hover;}
    }
    @media @lg-md-max{
        &{margin:0;}
    }
    @media @md-max{
        &{
            width:35px; height:31px; margin:0 14px 0 0;
        }
    }
}
.header-cart__count{
    min-width:20px; height:20px; padding:0 3px; position:absolute; top:-5px; right:-14px; background:@green;  border-radius:50%; font-weight:bold; font-size:14px; line-height:20px; color:#fff; text-align:center; .transition;
    @media @sm-max{
        &{min-width:18px; height:18px; line-height:18px; top:-4px;}
    }
}

.header-nav{
    @media @lg-md-max{
        &{display:none;}
    }
}
.header-nav__list{
    margin:0 -25px; display:flex;
    @media @lg-max{
        &{margin:0 -15px;}
    }
}
.header-nav__item{
    position:relative;
    &.to-right{
        & .header-subnav{left:auto; right:0;}
    }
}
.header-nav__link{
    padding:0 25px; display:block; position:relative; font-size:16px; line-height:50px; color:@black; white-space:nowrap;
    .desktop &:hover{color:@green;}
    &_icon{
        padding-right:51px;
        &:after{
            content:""; width:16px; height:16px; margin:-8px 0 0; position:absolute; top:50%; right:25px; background:url(../img/svg/arrow-bottom.svg) no-repeat; background-size:100% 100%; .transition(all,0.2s);
        }
        &.active{
            &:after{.rotate(180deg);
        }     
     }
    }
    @media @lg-max{
        &{
            padding-left:15px; padding-right:15px;
            &_icon{
                padding-right:41px;
                &:after{right:15px;}    
             }
        }
    }
}

.header-subnav{
    margin:0; padding:10px 0; display:none; position:absolute; top:100%; left:0; background:@grey; box-shadow:0px 3px 6px rgba(0, 0, 0, 0.1);
    @media @lg-max{
        &{left:-5px;}
    }
}
.header-subnav__link{
    padding:5px 25px; display:block; font-size:16px; line-height:24px; color:@black; white-space:nowrap;
    .desktop &:hover{color:@green;}
    @media @lg-max{
        &{padding-left:20px; padding-right:20px;}
    }
}

.login-nav{margin:0 -12px; display:flex; font-size:14px; line-height:48px; text-transform:uppercase;}
.login-nav__item{padding:2px 12px 0;}
.login-nav__link{
    padding:0 0 0 16px; display:block; position:relative; color:@black;
    .desktop &:hover{color:@green;}
    &:before{
        content:""; width:12px; height:12px; margin:-6px 0 0; position:absolute; top:50%; left:0; background-size:100% 100%; background-repeat:no-repeat;
        .mozilla &{margin-top:-5px;}
    }
    &_icon-1{
        &:before{background-image:url(../img/svg/login.svg);}
    }
    &_icon-2{
        &:before{background-image:url(../img/svg/reg.svg);}
    }
}

/* 3.4.2 Footer */
.footer{width:100%; min-width:0; flex:0 0 auto; position:relative; overflow:hidden; border-top:4px solid #EBEBEB;}
.footer__main{
    padding-top:30px; padding-bottom:12px;
    @media @sm-max{
        &{padding-top:20px; text-align:center;}
    }
}
.footer__cols{
    margin:0 -15px; display:flex; justify-content:space-between;
    @media @sm-max{
        &{display:block;}
    }
}
.footer__col{
    padding:0 15px 13px;
    @media @md-sm-max{
        &_hide-tablet{display:none;}
    }
    @media @sm-max{
        &{
            padding-bottom:0;
            &_hide-mob{display:none;}
        }
    }
}

.footer-logo{display:flex; align-items:center;}
.footer-logo__image{max-width:102px; margin:0;}
.footer-logo__text{
    padding:0 0 0 35px; font-weight:bold; font-size:14px; line-height:20px; color:@black;
    @media @lg-max{
        &{display:none;}
    }
}

.footer-nav__list{
    margin:0; font-size:16px; line-height:22px;
    @media @md-max{
        &{font-size:14px;}
    }
    @media @sm-max{
        &{font-size:16px;}
    }
}
.footer-nav__link{
    display:block; color:@black;
    .desktop &:hover{color:@green;}
    @media @md-max{
        &{padding:0 0 1px;}
    }
    @media @sm-max{
        &{padding:0;}
    }
}

.socials{
    margin:0 -2.5px; display:flex; flex-wrap:wrap;
    @media @sm-max{
        &{margin-top:14px; margin-bottom:12px; justify-content:center;}
    }
}
.socials__item{padding:0 2.5px 5px;}
.socials__link{
    width:41px; height:41px; display:block; border-radius:50%; background:@green; overflow:hidden;
    .desktop &:hover{background:@green-hover;}
}
.socials__icon{
    height:100%; display:block; display:flex; flex-direction:column; justify-content:center; align-items:center;
    &:before{content:""; margin:auto; display:block; background-repeat:no-repeat; background-size:100% 100%;}
    &_1{
        &:before{width:22px; height:22px; background-image:url(../img/svg/instagram.svg);}
    }
    &_2{
        &:before{width:28px; height:16px; background-image:url(../img/svg/vk.svg);}
    }
    &_3{
        &:before{width:25px; height:18px; background-image:url(../img/svg/youtube.svg);}
    }
}

.copyrights{
    margin:0; padding:0 0 12px; font-weight:bold; font-size:14px; line-height:20px;
    @media @sm-max{
        &{font-size:12px; line-height:16px;}
    }
}

/* 3.4.3 Mobile navigation */
.mob-nav{
	width:100%; height:100%; display:none; position:fixed; top:0; left:0; z-index:90; background:rgba(#000,0.3);
    &.active{
        & .mob-nav__bg{left:0;}
    }
    @media @lg-xl{
        &{display:none !important;}
    }
    @media @sm-max{
        &{background:#fff;}
    }
}
.mob-nav__bg{
	width:100%; max-width:320px; height:100%; padding:169px 0 0; position:absolute; top:0; left:-100%; z-index:1; .transition(all,@dur); background:rgba(#fff);
    @media @md-max{
        &{padding:71px 0 0;}
    }
    @media @sm-max{
        &{max-width:none; padding:48px 0 0; background:none;}
    }
}
.mob-nav__scroll{height:100%; overflow-x:hidden; overflow-y:auto;}
.mob-nav__close{position:absolute; top:0; right:0;}
.mob-nav__mask{width:100%; height:100%; position:absolute; top:0; left:0;}

.burger-nav{margin:5px 0 0;}
.burger-nav__item{
    &_tablet{display:none;}
    @media @md-max{
        &_tablet{display:block;}
    }
}
.burger-nav__link{
    padding:9px 30px 11px; display:block; font-size:18px; line-height:25px; color:@black; border-bottom:2px solid #E7E7E7;
    .desktop &:hover{color:@green;}
    @media @sm-max{
        &{padding-left:35px; padding-right:35px;}
    }
}

.burger-subnav{margin:0; padding:0 0 12px; display:none; background:#E7E7E7;}
.burger-subnav__item{
    &:first-child{padding-top:4px;}
}
.burger-subnav__link{
    padding:11px 30px 9px 60px; display:block; font-size:18px; line-height:25px; color:@black; border-bottom:1px solid #E7E7E7;
    .desktop &:hover{color:@green;}
    @media @sm-max{
        &{padding-left:66px;}
    }
}

/* 3.4.4 Inner page */
.inner-page{
    padding-top:26px; padding-bottom:33px;
    @media @sm-max{
        &{padding-top:16px; padding-bottom:7px;}
    }
}
.inner-page__title{
    margin:0 0 10px; font-weight:bold; font-size:45px; line-height:62px; text-transform:uppercase;
    @media @lg-md-max{
        &{font-size:36px; line-height:48px;}
    }
    @media @md-max{
        &{font-size:32px; line-height:44px;}
    }
    @media @sm-max{
        &{margin:0 0 8px; font-size:25px; line-height:34px;}
    }
}
.inner-page__text{
    padding:0 0 9px;
    & + .about-us-page{margin-top:-9px;}
    @media @sm-max{
        &{
            line-height:22px;
            &_hide-mob{display:none;}
        }
    }
}
.inner-page__cols{
    margin:0 -19px 20px; display:flex; align-items:flex-end;
    & .inner-page__title{margin:0;}
    @media @lg-max{
        &{margin-left:-15px; margin-right:-15px;}
    }
    @media @md-max{
        &{justify-content:space-between;}
    }
    @media @sm-max{
        &{margin:0 -10px 10px;}
    }
}
.inner-page__col{
    padding:0 19px;
    @media @lg-max{
        &{padding:0 15px;}
    }
    @media @sm-max{
        &{padding:0 10px;}
    }
}
.inner-page__remove-button{
    bottom:8px;
    @media @md-max{
        &{bottom:4px;}
    }
    @media @sm-max{
        &{bottom:-4px;}
    }
}

.breadcrumbs{
    margin:0 0 10px; font-size:14px; line-height:18px;
    @media @sm-max{
        &{margin:0 0 7px;}
    }
}
.breadcrumbs__item{
    display:inline; position:relative; color:#777;
    &:after{content:""; width:16px; height:8px; margin:0 7px; display:inline-block; background:url(../img/webp/arrow-nav.webp) no-repeat; background-size:100% 100%;}
    &:last-child{
        &:after{display:none;}
    }
    @media @sm-max{
        &{
            display:none;
            &:first-child, &:last-child{display:inline;}
        }
    }
}
.breadcrumbs__link{
    color:@green;
    .desktop &:hover{text-decoration:underline;}
}

/* 3.4.5 Popups */
.popup{
    width:100%; height:100%; max-height:100%; position:fixed; display:none; top:0; left:0; z-index:9999; overflow-y:auto; overflow-x:hidden; -webkit-overflow-scrolling:touch; background:rgba(#000,0.8);
}
.popup.active{
    & .popup__window{opacity:1; transform:scale(1);}
}
.popup__row{width:100%; height:100%; display:table; table-layout:fixed; position:relative;}
.popup__cell{width:100%; height:100%; display:table-cell; vertical-align:middle;}
.popup__window{
    width:100%; max-width:360px; margin:auto; padding:50px 30px 20px; position:relative; z-index:1; color:@black; opacity:0; transform:scale(0.5); transition:linear 0.4s; background:#fff;
    @media @sm-max{
        &{max-width:320px; padding-left:25px; padding-right:25px; padding-bottom:15px;}
    }
}
.popup__mask{width:100%; height:100%; position:absolute; top:0; left:0;}
.popup__close{
    position:absolute; top:16px; right:24px; border-color:transparent;
    &:before, &:after{width:26px; left:9px; background:@green;}
    .desktop &:hover{
        border-color:transparent;
        &:before, &:after{background:@black;}
    }
    @media @sm-max{
        &{right:19px;}
    }
}

.popup-form{
    @media @sm-max{
        &{max-width:290px; margin:auto;}
    } 
}
.popup-form__title{
    margin:0 0 20px; text-align:center; font-weight:bold; font-size:24px; line-height:32px;
    @media @sm-max{
        &{margin:0 0 17px; font-size:22px; line-height:30px;}
    } 
}
.popup-form__input{padding:0 0 5px;}
.popup-form__bottom{padding:10px 0 0;}
.popup-form__button{
    width:100%; height:66px; margin:0 0 20px; padding:0; display:block; font-size:20px; line-height:66px; text-transform:uppercase;
    @media @lg-md-max{
        &{height:66px; font-size:20px; line-height:66px;}
    }    
}

/*____________________3.5 CONTENT____________________*/
.content{
    & a{
        text-decoration:underline;
        .desktop &:hover{text-decoration:none;}
        &[href^="tel"]{color:@black; text-decoration:none !important;}
    }
    & ul{
        & > li{
            padding:0 0 0 27px; position:relative;
            &:before{content:""; width:4px; height:4px; position:absolute; top:11px; left:11px; background:@green; border-radius:50%;}
        }
        @media @sm-max{
            &{
                & > li{
                    padding-left:21px;
                    &:before{left:8px;}
                }
            }
        }
    }
    & ol{
        & > li{
            padding:0 0 0 27px; position:relative;
            &:before{width:27px; position:absolute; top:0; left:0; text-align:center;}
        }
    }
    & h1, & h2{
        margin:0 0 19px; font-weight:bold; font-size:30px; line-height:35px;
        @media @lg-md-max{
            &{font-size:28px; line-height:38px;}
        }
        @media @sm-max{
            &{margin:0 0 8px; font-size:20px; line-height:25px;}
        }
    }
    & h3, & h4{
        margin:0 0 19px; font-weight:normal; font-size:25px; line-height:32px;
        @media @lg-md-max{
            &{font-size:22px; line-height:30px;}
        }
        @media @md-max{
            &{margin:0 0 16px; font-size:20px; line-height:25px;}
        }        
    }
    & p{
        @media @sm-max{
            &{
                & + h1, & + h2{margin-top:-9px;}
                & + h3, & + h4{margin-top:-3px;}
            }
        } 
    }
    & img{
        &[style*="float:right"]{margin-left:40px;}
        &[style*="float:left"]{margin-right:30px;}
        @media @md-max{
            &[style*="float:right"], &[style*="float:left"]{display:none;}
        }
    }
}

/*-------------------------------------------------------------------------------------------*/
/* ----------------------------------4. PAGES AND SECTIONS-----------------------------------*/
/*-------------------------------------------------------------------------------------------*/
/*____________________4.1 INDEX____________________*/
/* first screen */
.slider{
    height:600px; overflow:hidden; position:relative;
    @media @lg-md-max{
        &{height:480px;}
    }
    @media @sm-max{
        &{height:382px;}
    }    
    @media @xsm-max{
        &{height:350px;}
    }  
}
.slider__arrows{
    width:100%; height:100%; padding:0 30px; position:absolute; top:0; left:0;
    @media @sm-max{
        &{display:none;}
    }   
}
.slider__max{max-width:1320px; height:100%; margin:auto; position:relative;}
.slider__prev{margin:-24px 0 0; position:absolute; top:50%; left:0; z-index:2;}
.slider__next{margin:-24px 0 0; position:absolute; top:50%; right:0; z-index:2;}
.slider__list{position:relative; z-index:1; background:#000;}
.slider__item{
    height:600px; position:relative;
    @media @lg-md-max{
        &{height:480px;}
    }
    @media @sm-max{
        &{height:382px;}
    }    
    @media @xsm-max{
        &{height:350px;}
    }  
}
.slider__image{
    width:100%; height:100%; position:absolute; top:0; left:0; background-size:cover; background-repeat:no-repeat; background-position:center center;
    &:before{content:""; width:100%; height:100%; position:absolute; top:0; left:0; background:rgba(#000,0.5);}
}
.slider__row{
    width:100%; display:table; table-layout:fixed; position:relative;
    @media @sm-max{
        &{padding-bottom:43px;}
    }  
}
.slider__cell{
    height:600px; display:table-cell; vertical-align:middle; position:relative; color:#fff; text-align:center;
    @media @lg-md-max{
        &{height:480px;}
    }
    @media @sm-max{
        &{height:337px;}
    }    
    @media @xsm-max{
        &{height:305px;}
    }  
}
.slider__title{
    max-width:1200px; margin:0 auto 22px; font-weight:bold; font-size:45px; line-height:62px;
    @media @lg-md-max{
        &{font-size:36px; line-height:48px;}
    }
    @media @md-max{
        &{margin-bottom:27px; font-size:32px; line-height:44px;}
    }
    @media @sm-max{
        &{max-width:450px; margin-bottom:11px; font-size:25px; line-height:34px;}
    }
}
.slider__content{
    max-width:570px; margin:auto; padding:0 10px 20px; font-size:25px; line-height:34px;
    @media @lg-md-max{
        &{font-size:22px; line-height:30px;}
    }
    @media @md-max{
        &{max-width:520px; font-size:20px; line-height:28px;}
    }
    @media @sm-max{
        &{max-width:440px; padding-bottom:0; font-size:16px; line-height:22px;}
    }
}
.slider__button{
    height:56px; margin:0 0 16px; padding:0 45px; line-height:56px;
    @media @lg-md-max{
        &{height:50px; font-size:18px; line-height:50px;}
    }
    @media @md-max{
        &{height:46px; padding:0 40px; font-size:16px; line-height:46px;}
    }
    @media @sm-max{
        &{height:40px; padding:0 30px; line-height:40px;}
    }
}
.slider__dots{
    width:100%; position:absolute; bottom:40px; left:0; z-index:2;
    @media @sm-max{
        &{bottom:16px;}
    }  
}

/* second screen */
.second-screen{
    padding-top:50px;
    @media @sm-max{
        &{padding-top:28px;}
    }
}

.pilomaterial{display:flex; flex-wrap:wrap;}
.pilomaterial__article{
    width:100%; order:2;
    & .main-article__top{margin-bottom:-6px;}
    @media @sm-max{
        &{margin-bottom:-8px;}
    }
}
.pilomaterial__advantages{width:100%; order:1;}

.main-article{padding-bottom:20px;}
.main-article__top{text-align:center;}
.main-article__title{
    margin:0 0 30px; font-weight:bold; font-size:45px; line-height:62px; text-transform:uppercase;
    @media @lg-md-max{
        &{font-size:36px; line-height:48px;}
    }
    @media @md-max{
        &{margin:0 0 24px; font-size:32px; line-height:44px;}
    }
    @media @sm-max{
        &{margin:0 0 20px; font-size:25px; line-height:34px;}
    }
}
.main-article__content{
    padding-bottom:9px; overflow:hidden;
    @media @sm-max{
        &{padding-bottom:2px;}
    }
}
.main-article__bottom{
    padding-bottom:30px;
    @media @sm-max{
        &{padding-bottom:10px;}
    }
}

.advantages{
    max-width:1486px; margin:auto; padding-top:3px; padding-bottom:44px;
    @media @md-max{
        &{max-width:700px;}
    }
    @media @sm-max{
        &{padding-bottom:40px;}
    }  
}
.advantages__list{
    margin:0 -15px -40px; display:flex; justify-content:space-between;
    @media @md-max{
        &{flex-wrap:wrap;}
    }
}
.advantages__item{
    padding:0 15px 40px; text-align:center;
    @media @md-max{
        &{width:50%;}
    }
    @media @sm-max{
        &{padding-bottom:25px;}
    }
    @media @xsm-max{
        &{width:100%;}
    }
}
.advantages__top{
    height:84px; margin:0 0 24px; display:flex; flex-direction:column; align-items:center; justify-content:center;
    @media @sm-max{
        &{height:auto; margin:0 0 4px;}
    }
}
.advantages__icon{margin:auto;}
.advantages__title{
    margin:0; font-weight:bold; font-size:18px; line-height:25px;
    @media @lg-md-max{
        &{font-size:16px; line-height:22px;}
    }
}

.main-catalog{
    margin:0 -10px 50px; display:flex;
    @media @sm-max{
        &{margin-bottom:30px;}
        &_inner{
            padding-top:15px;
        }
    }
}
.main-catalog__left{
    width:25%; padding:0 10px;
    @media @lg-md-max{
        &{width:33.333%;}
    }
    @media @sm-max{
        &{display:none;}
    }
}
.main-catalog__title{
    margin:0; padding:18px 30px 17px 40px; background:@green; color:#fff; font-weight:bold; font-size:20px; line-height:25px; text-transform:uppercase;
    @media @lg-md-max{
        &{padding-left:30px; font-size:18px;}
    }
    @media @md-max{
        &{padding-right:25px; font-size:16px;}
    }
}
.main-catalog__right{
    width:75%; padding:0 10px;
    @media @lg-md-max{
        &{width:66.666%;}
    }
    @media @sm-max{
        &{width:100%;}
    }
}
.main-catalog__button{
    width:100%; height:64px; margin:0; padding:0; display:block; line-height:64px;
    @media @lg-md-max{
        &{height:64px; font-size:20px; line-height:64px;}
    }    
}
.main-catalog__inner-bottom{
    padding:8px 0 0;
    @media @sm-max{
        padding:0;
        & .main-catalog__button{
            position:relative; background:#fff; color:@green;
            &:before{content:""; width:100%; height:100%; position:absolute; top:0; left:0; border:1px solid @green; .transition;}
            .desktop &:hover{
                color:#fff;
                &:before{border-color:@green-hover;}
            }
        }
    }
}

.catalog-nav{margin:0; background:@grey;}
.catalog-nav__link{
    padding:13px 30px 11px 40px; display:block; color:@black; border-bottom:2px solid #E7E7E7;
    .desktop &:hover{
        color:@green;
        & .catalog-nav__back{text-decoration:none;}
    }
    @media @lg-md-max{
        &{padding-left:30px;}
    }
}
.catalog-nav__back{
    padding:0 0 0 48px; display:inline-block; position:relative; color:@green; text-decoration:underline;
    &:before{content:""; width:33px; height:17px; margin:-8.5px 0 0; position:absolute; top:50%; left:0; background:url(../img/svg/arrow-back.svg) no-repeat; background-size:100% 100%;}
}

.catalog{
    margin:0 -10px; padding:0 0 2px; display:flex; flex-wrap:wrap;
    @media @sm-max{
        &{padding:0;}
    }
}
.catalog__item{
    width:33.333%; margin:0 0 20px; padding:0 10px;
    @media @lg-md-max{
        &{width:50%;}
    }
    @media @sm-max{
        &{margin:0 0 30px;}
    }
    @media (max-width:600px){
        &{width:100%;}
    }
}
.catalog__bg{
    min-height:100%; position:relative; border-radius:20px;
    &:before{content:""; width:100%; height:100%; position:absolute; top:0; left:0; background:#fff; border:1px solid #D6D6D6; box-shadow:0px 4px 4px rgba(0, 0, 0, 0.25); border-radius:20px;}
}
.catalog__link{width:100%; height:100%; position:absolute; top:0; left:0; z-index:1; border-radius:20px;}
.catalog__top{padding:20px 20px 0; position:relative;}
.catalog__image{
    max-width:262px; height:155px; margin:0 auto 24px; background-repeat:no-repeat; background-size:cover; background-position:center center;
    @media @lg-max{
        &{margin-bottom:16px;}
    }
    @media @md-sm-max{
        &{height:130px;}
    }
    @media @sm-max{
        &{max-width:400px; height:185px; margin-bottom:4px;}
    }
}
.catalog__content{
    padding:0 21px 23px; position:relative;
    @media @sm-max{
        &{padding:0 18px 15px;}
    }
}
.catalog__title{
    margin:0 0 19px; text-align:center; font-weight:normal; font-size:20px; line-height:25px;
    @media @lg-max{
        &{margin-bottom:11px;}
    }
    @media @lg-md-max{
        &{font-size:18px;}
    }
    @media @md-max{
        &{font-size:16px;}
    }
    @media @sm-max{
        &{margin-bottom:4px;}
    }
}
.catalog__title-link{
    position:relative; z-index:2; color:@black;
    .desktop &:hover{color:@green;}
}
.catalog__cols{
    margin:0 -5px; display:flex; justify-content:space-between; align-items:center;
    @media @lg-max and @lg-xl{
        &{display:block; justify-content:center; text-align:center; flex-wrap:wrap;}
    }
    @media @md-max and @md{
        &{display:block; justify-content:center; text-align:center; flex-wrap:wrap;}
    }
}
.catalog__col{padding:0 5px;}
.catalog__info{
    margin:0; display:inline-block; font-weight:bold; font-size:25px; line-height:23px;
    & span{display:inline-block; font-size:20px;}
    @media @lg-max{
        &{
            font-size:20px; line-height:22px;
            & span{font-size:16px;}
        }
    }
    @media @sm-max{
        &{line-height:18px;}
    }
}
.catalog__button{
    margin:0; padding:0 20px; position:relative; z-index:2;
    @media @lg-max and @lg-xl{
        &{margin-top:15px; margin-bottom:3px;}
    }
    @media @lg-md-max{
        &{
            padding:0 17px; font-size:15px;
            & .button__cart{padding-left:30px;}
        }
    }
    @media @md-max and @md{
        &{margin-top:15px; margin-bottom:3px;}
    }
    @media @sm-max{
        &{padding:0 15px; height:32px; font-size:14px; line-height:32px;}
    }
}

/* qualities */
.qualities{
    padding-top:33px; padding-bottom:39px; background:@grey;
    @media @lg-max{
        &{padding-top:50px;}
    }
    @media @sm-max{
        &{padding-top:25px; padding-bottom:20px;}
    }
}
.qualities__top{text-align:center;}
.qualities__title{
    margin:0 0 17px; font-weight:bold; font-size:45px; line-height:62px; text-transform:uppercase;
    @media @lg-md-max{
        &{font-size:36px; line-height:48px;}
    }
    @media @md-max{
        &{margin:0 0 24px; font-size:32px; line-height:44px;}
    }
    @media @sm-max{
        &{margin:0 0 22px; font-size:25px; line-height:34px;}
    }
}
.qualities__list{
    max-width:792px; margin:auto; overflow:hidden;
    @media @lg-md-max{
        &{max-width:650px;}
    }
    @media @md-max{
        &{max-width:600px;}
    }
}
.qualities__subtitle{
    margin:0 0 20px; padding:22px 30px 21px 141px; position:relative; background:#fff; border:1px solid #A5A5A5; font-weight:normal; font-size:18px; line-height:25px;
    &:before{content:""; width:33px; height:31px; margin:-15.5px 0 0; position:absolute; top:50%; left:66px; background:url(../img/svg/rating.svg) no-repeat; background-size:100% 100%;}
    @media @lg-md-max{
        &{
            padding:20px 30px 19px 101px; font-size:16px;
            &:before{left:36px;}
        }
    }
    @media @md-max{
        &{
            padding-left:85px;
            &:before{left:30px;}
        }
    }
    @media @sm-max{
        &{
            margin:0 0 10px; padding:10px 15px 10px 75px;
            &:before{left:18px;}
        }
    }
}

/* description */
.description{
    padding-top:50px;
    & .content{
        & a{
            text-decoration:none;
            .desktop &:hover{text-decoration:underline;}
        }
    }
    @media @sm-max{
        &{padding-top:30px; padding-bottom:0;}
    }
}

.video{
    position:relative; background:#000;
    &.opened{
        & .video__image{opacity:0;}
        & .video__button{display:none;}
    }
}
.video__image{width:100%; margin:0;}
.video__frame{width:100% !important; height:100% !important; position:absolute; top:0; left:0; z-index:2;}
.video__button{
    width:100%; height:100%; position:absolute; top:0; left:0; z-index:1; background:rgba(#000,0.2);
    .desktop &:hover{
        & .video__icon{.zoom(1.1);}
    }
}
.video__flex{width:100%; height:100%; display:flex; flex-direction:column; justify-content:center; align-items:center;}
.video__icon{
    width:8.68%; height:12.52%; .transition(all, 0.3s);
    &:before{content:""; width:100%; height:100%; display:block; background:url(../img/svg/play.svg) no-repeat; background-size:100% 100%;}
}

/* feedback */
.feedback{
    padding-top:55px; padding-bottom:60px; position:relative; overflow:hidden; color:#fff;
    @media @md-max{
        &{padding-bottom:50px;}
    }
    @media @sm-max{
        &{padding-top:30px; padding-bottom:16px;}
    }
}
.feedback__bg{
    width:100%; height:100%; min-height:322px; position:absolute; top:0; left:0; background-size:cover; background-repeat:no-repeat; background-position:center center;
    &:before{content:""; width:100%; height:100%; position:absolute; top:0; left:0; background:rgba(#000,0.8);}
}
.feedback__main{position:relative;}
.feedback__top{
    padding-bottom:20px; text-align:center;
    @media @md-max{
        &{padding-bottom:10px;}
    }
    @media @sm-max{
        &{padding-bottom:0;}
    }
}
.feedback__title{
    margin:0 0 30px; font-weight:bold; font-size:45px; line-height:62px;
    @media @lg-md-max{
        &{font-size:36px; line-height:48px;}
    }
    @media @md-max{
        &{margin:0 0 24px; font-size:32px; line-height:44px;}
    }
    @media @sm-max{
        &{margin:0 0 20px; font-size:25px; line-height:34px;}
    }
}
.feedback__cols{
    margin:0 -21px; display:flex;
    @media @lg-max{
        &{margin:0 -15px;}
    }
    @media @md-max{
        &{margin:0 -10px;}
    }
    @media @sm-max{
        &{flex-wrap:wrap;}
    }
}
.feedback__input-wrap{
    width:50%; padding:0 21px 10px;
    @media @lg-max{
        &{padding-left:15px; padding-right:15px;}
    }
    @media @md-max{
        &{padding-left:10px; padding-right:10px;}
    }
    @media @sm-max{
        &{width:100%; padding-bottom:0;}
    }
}
.feedback__field{
    height:66px; padding:0 40px; font-size:20px; border-color:transparent;
    &:focus{border-color:transparent;}
    @media @lg-md-max{
        &{height:56px; padding:0 20px; font-size:18px;}
    }
    @media @md-max{
        &{height:50px; font-size:16px;}
    }
    @media @sm-max{
        &{height:44px; text-align:center;}
    }
}
.feedback__button-wrap{
    min-width:38.25%; padding:0 21px 10px; flex-grow:0; flex-shrink:0;
    @media @lg-max{
        &{padding-left:15px; padding-right:15px;}
    }
    @media @md-max{
        &{padding-left:10px; padding-right:10px;}
    }
    @media @sm-max{
        &{width:100%; padding-bottom:0;}
    }
}
.feedback__button{
    min-width:100%; padding:0 40px; display:block; text-overflow:clip; text-transform:uppercase;
    @media @md-max{
        &{
            height:50px; padding:0 30px; font-size:16px; line-height:50px;
        }
    }
    @media @sm-max{
        &{width:100%; padding:0; height:56px; line-height:56px;}
    }
}

/*____________________4.2 CATALOG____________________*/
.catalog-page{padding-top:5px;}
.catalog-page__list{
    margin:0 -10px; padding:0 0 14px; display:flex; flex-wrap:wrap;
    @media @sm-max{
        &{padding:0 0 4px;}
    }
}
.catalog-page__item{
    width:33.333%; margin:0 0 30px; padding:0 10px;
    @media @md-max{
        &{width:50%;}
    }
    @media @sm-max{
        &{width:100%; margin:0 0 20px;}
    }
}
.catalog-page__bg{
    min-height:100%; padding:25px 15px 22px; position:relative; background:#fff; border:1px solid #D6D6D6; box-shadow:0px 4px 4px rgba(0, 0, 0, 0.25); border-radius:20px; text-align:center; overflow:hidden; .transition;
    .desktop &:hover{color:@green;}
    @media @md-max{
        &{padding-top:19px; padding-bottom:17px;}
    }
}
.catalog-page__link{width:100%; height:100%; position:absolute; top:0; left:0; z-index:1;}
.catalog-page__top{
    height:171px; margin:0 0 15px; display:flex; flex-direction:column; align-items:center; justify-content:center;
    @media @sm-max{
        &{height:auto;}
    }
}
.catalog-page__icon{max-width:100%; margin:auto;}
.catalog-page__title-wrap{width:100%; display:table; table-layout:fixed;}
.catalog-page__title{
    height:68px; margin:0; display:table-cell; vertical-align:middle; font-size:25px; line-height:34px; text-transform:uppercase;
    @media @lg-md-max{
        &{height:60px; font-size:22px; line-height:30px;}
    }
    @media @md-max{
        &{height:auto; font-size:20px; line-height:25px;}
    }
}

/*____________________4.3 PILOMATERIALS____________________*/
.best-offers{
    &_all-visible-mob{
        @media @sm-max{
            & .best-offers__item{display:block;}
        }
    }
}
.best-offers__list{
    margin:0 -10px 11px; display:flex; flex-wrap:wrap;
    @media @sm-max{
        &{margin-bottom:4px; margin-left:-15px; margin-right:-15px;}
    }
}
.best-offers__item{
    width:50%; padding:0 10px 20px;
    @media @sm-max{
        &{
            width:100%; padding-left:0; padding-right:0; display:none;
            &:first-child{display:block;}
        }
    }
}
.best-offers__all{
    height:460px; position:relative; overflow:hidden;
    .desktop &:hover{
        & .best-offers__image{.zoom(1.1);}
        & .best-offers__bg{background:rgba(#fff,0.95);}
    }
    @media @lg-md-max{
        &{height:400px;}
    }
    @media @md-max{
        &{height:340px;}
    }
    @media @md-sm-max{
        &{height:300px;}
    }
}
.best-offers__link{width:100%; height:100%; position:absolute; top:0; left:0; z-index:2;}
.best-offers__image{width:100%; height:100%; position:absolute; top:0; left:0; background-repeat:no-repeat; background-position:center center; background-size:cover; .transition(all,0.6s);}
.best-offers__bg{width:100%; position:absolute; bottom:0; left:0; z-index:1; background:rgba(#fff,0.8); .transition(all,0.3s);}
.best-offers__title{
    margin:0; padding:39px 120px 38px 54px; position:relative; font-weight:bold; font-size:25px; line-height:33px;
    &:after{content:""; width:57px; height:25px; margin:-12.5px 0 0; position:absolute; top:50%; right:60px; background:url(../img/svg/arrow-offers.svg) no-repeat; background-size:100% 100%;}
    @media @lg-md-max{
        &{
            padding-right:100px; padding-left:30px; font-size:22px; line-height:30px;
            &:after{right:30px;}
        }
    }
    @media @md-max{
        &{padding-top:31px; padding-bottom:30px; font-size:20px; line-height:25px;}
    }
    @media @md-sm-max{
        &{
            padding-left:30px; padding-right:30px; text-align:center;
            &:after{display:none;}
        }
    }
}

/*____________________4.4 INNER ARTICLE____________________*/
.inner-article{
    padding-top:5px;
    @media @sm-max{
        &.opened{
            & .inner-article__text_hide-mob{display:block;}
            & .inner-article__button-text:before{content:"Свернуть";}
        }
    }
}
.inner-article__image{
    height:415px; margin:0 0 25px; background-position:center center; background-repeat:no-repeat; background-size:cover;
    @media @md-max{
        &{height:330px;}
    } 
    @media @sm-max{
        &{height:271px; margin:0 0 13px;}
    }  
}
.inner-article__text{
    & ul{
        & > li{
            padding-bottom:20px;
            &:before{background:@black;}
            &:last-child{padding-bottom:10px;}
        }
        @media @sm-max{
            &{margin-bottom:10px;}
        } 
    }
    & ol{
        & > li{
            padding-bottom:20px;
            &:last-child{padding-bottom:10px;}
        }
        @media @sm-max{
            &{margin-bottom:10px;}
        } 
    }
    @media @sm-max{
        &_hide-mob{display:none;}
    } 
}
.inner-article__button{
    margin:0 0 25px; display:none; position:relative; background:#fff; color:@green;
    &:before{content:""; width:100%; height:100%; position:absolute; top:0; left:0; border:1px solid @green; .transition;}
    .desktop &:hover{
        color:#fff;
        &:before{border-color:@green-hover;}
    }
    @media @sm-max{
        &{width:100%; height:64px; padding:0; display:block; font-size:20px; line-height:64px;}
    } 
}
.inner-article__button-text:before{content:"Показать еще";}

/*____________________4.5 CONTACTS____________________*/
.contacts-page{
    padding:0 0 16px; text-align:center;
    @media @sm-max{
        &{padding:0 0 11px;}
    }  
}
.contacts-page__title{
    margin:0 0 10px; font-weight:bold; font-size:45px; line-height:62px; text-transform:uppercase;
    @media @lg-md-max{
        &{font-size:36px; line-height:48px;}
    }
    @media @md-max{
        &{font-size:32px; line-height:44px;}
    }
    @media @sm-max{
        &{margin:0 0 8px; font-size:25px; line-height:34px;}
    }    
}
.contacts-page__text{
    max-width:800px; margin:auto; padding:0 0 5px;
    @media @lg-max{
        &{max-width:720px;}
    } 
    @media @sm-max{
        &{margin:0 0 -5px; padding:0;}
    }  
}

.map{
    height:770px; margin:0 0 40px; position:relative;
    @media @lg-md-max{
        &{height:670px;}
    }  
    @media @md-max{
        &{height:570px;}
    }  
    @media @sm-max{
        &{height:507px; margin:0 0 24px;}
    }   
}
.map__ui{height:100%; overflow:hidden; background:@grey;}

.scheme{padding-bottom:23px; text-align:center;}
.scheme__title{
    margin:0 0 20px; font-weight:bold; font-size:30px; line-height:40px;
    @media @lg-md-max{
        &{font-size:28px; line-height:38px;}
    }
    @media @sm-max{
        &{margin:0 0 15px; font-size:20px; line-height:25px;}
    }
}
.scheme__link{display:block;}
.scheme__image{max-width:100%; margin:auto;}

/*____________________4.6 CARD____________________*/
.card{
    padding:0 0 98px;
    @media @md-max{
        &{padding:0 0 50px;}
    }
    @media @sm-max{
        &{padding:0;}
    }
}
.card__art{
    margin:0 0 24px; font-size:18px; line-height:25px;
    @media @lg-md-max{
        &{font-size:16px;}
    }
    @media @sm-max{
        &{margin:-8px 0 13px;}
    }
}
.card__cols{
    display:flex;
    @media @sm-max{
        &{display:block;}
    }
}
.card__left{
    width:100%; min-width:0; padding:0 50px 0 0;
    @media @lg-md-max{
        &{padding:0 30px 0 0;}
    }
    @media @sm-max{
        &{width:auto; padding:0;}
    }
}
.card__right{
    width:24.01%; min-width:280px; flex-grow:0; flex-shrink:0;
    @media @sm-max{
        &{width:auto; margin:0 -15px; padding:0 15px 15px; background:@grey;}
    }
}
.card__inner-cols{
    margin:0 -25px; display:flex;
    @media @lg-md-max{
        &{margin:0 -15px;}
    }
    @media (max-width:1200px){
        &{display:block;}
    }
}
.card__inner-left{
    width:58.95%; padding:0 25px;
    @media @lg-md-max{
        &{padding:0 15px;}
    }
    @media (max-width:1200px){
        &{width:auto; padding-bottom:15px;}
    }
}
.card__inner-right{
    width:41.05%; padding:0 25px;
    @media @lg-md-max{
        &{padding:0 15px;}
    }
    @media (max-width:1200px){
        &{width:auto;}
    }
}
.card__image-link{
    max-width:650px; margin:auto; display:block; border:1px solid #DEDEDE;
    @media @sm-max{
        &{max-width:500px;}
    }
}
.card__image{max-width:100%; margin:auto;}
.card__sidebar{
    padding:25px 38px 30px; background:@grey;
    @media @sm-max{
        &{max-width:194px; padding-top:25px; padding-bottom:20px; margin:auto; padding-left:0; padding-right:0;}
    }
}
.card__price{
    margin:0 0 14px; display:block; font-weight:bold; font-size:30px; line-height:30px;
    & span{font-size:20px;}
}
.card__button{
    width:100%; max-width:194px; height:49px; margin:0 0 8px; padding:0; display:block; font-weight:normal; font-size:18px; line-height:49px;
    &:last-child{margin:0;}
    & .button__cart{position:relative; left:3px;}
    @media @lg-md-max{
        height:49px; font-size:18px; line-height:49px;
    }
}

.chars{
    width:100%; display:table;
    @media @sm-max{
        &{padding:0 0 13px;}
    }
}
.chars__item{
    display:table-row;
    @media @sm-max{
        &:last-child{
            & .chars__col{border:none;}
        }
    }
}
.chars__col{
    padding:10px 20px 9px 0; display:table-cell; vertical-align:top; border-bottom:1px solid #D6D6D6;
    &:last-child{padding-right:0;}
    @media @sm-max{
        &:first-child{width:132px; padding-left:4px;}
    }
}

.actions{max-width:194px; padding:0 0 21px; display:flex; justify-content:space-between; text-align:center;}
.actions__text{margin:0; font-size:13px; line-height:22px; color:#777;}
.actions__right{padding:5px 0 0;}

.labels{background:#fff; border:6px solid @grey;}
.labels__item{
    display:flex; align-items:center; border-bottom:6px solid @grey;
    &:last-child{border-bottom:none;}
}
.labels__icon-wrap{width:62px;}
.labels__icon{max-width:100%; margin:auto;}
.labels__text{margin:0; padding:15px 15px 15px 0; font-size:18px; line-height:25px;}

.tabs-nav{
    margin:0 3px; display:flex;
    @media @sm-max{display:none;}
}
.tabs-nav__item{
    margin:0 -3px;
    &.active{
        & .tabs-nav__link{
            color:#fff !important;
            &:before{opacity:1;}
            &:after{opacity:1;}
        }
    }
    &:first-child{
        & .tabs-nav__link{
            &:before{.calc(width, "100% + 6px"); left:0;}
        }
    }
    &:last-child{
        & .tabs-nav__link{
            &:before{.calc(width, "100% + 6px");}
        }
    }
}
.tabs-nav__link{
    min-width:183px; display:block; position:relative; font-size:18px; line-height:50px; color:@black; text-align:center; border:6px solid @grey; border-bottom:none;
    &:before{content:""; .calc(width, "100% + 12px"); .calc(height, "100% + 6px"); position:absolute; top:0; left:-6px; z-index:1; opacity:0; background:@green;}
    &:after{content:""; width:0; height:0; margin:6px 0 0 -9px; position:absolute; top:100%; left:50%; border-style:solid; border-width:9px 9px 0 9px; border-color:@green transparent transparent transparent; opacity:0;}
    .desktop &:hover{color:@green;}
}
.tabs-nav__text{position:relative; z-index:2;}

.mob-tab{
    &.active{
        .mob-tab__button{
            &:after{opacity:1;}
        }       
    }
    @media @md{
        &:not(.active){
            & .mob-tab__content{display:block !important;}
        }
    }
    @media @sm-max{
        margin:0 -15px 10px;
        &:last-child{margin-bottom:3px;}
    }
}
.mob-tab__button{
    width:100%; padding:16px; display:none; overflow:visible; position:relative; background:@green; text-align:center; text-transform:uppercase;  font:bold 18px/25px @font; color:#fff;
    &:after{content:""; width:0; height:0; margin:0 0 0 -10px; position:absolute; top:100%; left:50px; border-style:solid; border-width:10px 10px 0 10px; border-color:@green transparent transparent transparent; opacity:0; .transition(border-color, 0.3s);}
    .desktop &:hover{
        background:@green-hover;
        &:after{border-color:@green-hover transparent transparent transparent;}
    }
    &.active{
        &:after{opacity:1;}
    }
    @media @sm-max{
        &{display:block;}
    }
}
.mob-tab__content{display:none;}

.tab{
    display:none; margin:0 0 17px; padding:41px 59px 26px; border:6px solid @grey; overflow:hidden;
    &.active{display:block;}
    @media @lg-md-max{
        &{padding-left:50px; padding-right:50px;}
    }
    @media @md-max{
        &{padding-left:30px; padding-right:30px;}
    }
    @media @sm-max{
        &{margin:0; padding:20px 15px 0; display:block; border:none;}
    }
}

.review{
    max-width:1373px; padding:0 0 19px;
    @media @sm-max{
        &{padding:0 0 10px;}
    }  
}
.review__item{
    padding:0 0 34px;
    &:last-child{padding:0;}
    @media @sm-max{
        &{margin:0 0 -3px; padding:0;}
    }    
}
.review__top{
    margin:0 -6px 9px; display:flex; align-items:center; color:#777;
    @media @sm-max{
        &{margin-bottom:2px;}
    }
}
.review__col{padding:0 6px;}
.review__body{display:flex;}
.review__image-wrap{
    padding:0 23px 0 0; flex-grow:0; flex-shrink:0;
    @media @sm-max{
        &{padding:6px 11px 0 0;}
    }
}
.review__image{
    width:108px; height:108px; margin:0 0 15px; border-radius:50%;
    @media @sm-max{
        &{width:61px; height:61px;}
    }
}
.review__content{
    width:100%; min-width:0; padding:7px 0 0;
    @media @sm-max{
        &{padding:0;}
    }
}
.review__title{
    margin:0 0 12px; font-weight:bold; font-size:18px; line-height:25px;
    @media @sm-max{
        &{margin:0 0 3px;}
    }
}
.review__text{
    font-size:18px; line-height:25px;
    @media @sm-max{
        &{font-size:16px;}
    }
}

.add-review{max-width:1007px;}
.add-review__title{
    margin:0 0 20px; font-size:45px; line-height:61px; text-transform:uppercase;
    @media @lg-md-max{
        &{font-size:36px; line-height:48px;}
    }
    @media @md-max{
        &{margin:0 0 24px; font-size:32px; line-height:44px;}
    }
    @media @sm-max{
        &{margin:0 0 20px; font-size:25px; line-height:34px;}
    }
}
.add-review__cols{
    margin:0 0 20px; display:flex;
    & .input{padding:0;}
    & .input__field{max-width:319px; margin:0;}
    & .input__textarea{height:305px; min-height:305px; margin:0;}
    & .input__placeholder{opacity:0; left:-9999px;}
    &_rating{margin:0 0 15px;}
    @media @sm-max{
        display:block; margin:0 0 10px;
        & .input__field{max-width:none;}
        & .input__textarea{height:200px; min-height:200px;}
        & .input__placeholder{opacity:1; left:0;}
    }
}
.add-review__left{
    width:168px; flex-grow:0; flex-shrink:0;
    @media @sm-max{
        &{width:auto; display:none;}
        &_visible-mob{display:block;}
    }
}
.add-review__right{width:100%; min-width:0;}
.add-review__label{
    padding:12px 15px 10px 0; display:block; font-size:18px; line-height:24px; user-select:none;
    & span{color:@red;}
    @media @lg-md-max{
        &{font-size:16px;}
    }
    @media @sm-max{
        &{padding:0;}
    }
}
.add-review__bottom{
    padding:0 0 0 168px;
    @media @sm-max{
        &{padding:0;}
    }
}
.add-review__button{
    width:100%; height:66px; margin:0 0 20px; padding:0; display:block; font-size:20px; line-height:66px; text-transform:uppercase;
    @media @lg-md-max{
        &{height:66px; font-size:20px; line-height:66px;}
    }
    @media @sm-max{
        &{font-size:18px;}
    }
}
.add-review__rating{padding:5px 0 0;}

/*____________________4.7 BASKET____________________*/
.basket-page{
    margin:0 -10px; display:flex;
    @media @md-max{
        &{display:block;}
    }
}
.basket-page__left{
    width:58.42%; padding:0 10px;
    @media @md-max{
        &{width:auto;}
    }
}
.basket-page__right{
    width:41.58%; padding:0 10px;
    & .basket-page__section{
        &:first-child{padding-bottom:34px;}
    }
    @media @md-max{
        &{width:auto;}
        & .basket-page__section{
            &:first-child{padding-bottom:14px;}
        }
    }
    @media @sm-max{
        & .basket-page__section{
            &:first-child{padding-bottom:0;}
        }
    }
}
.basket-page__top{
    margin:0 0 25px; padding:20px 15px 19px; display:flex; justify-content:space-between; background:@green; color:#fff;
    @media @sm-max{
        &{margin:0 -15px 19px; padding:13px 10px;}
    }
}
.basket-page__top-link{
    margin:0 15px; display:inline-block; font-weight:bold; font-size:20px; line-height:20px; color:#fff; border-bottom:1px solid transparent; transition:none; white-space:nowrap;
    .desktop &:hover{border-color:rgba(#fff, 0.7);}
    @media @lg-max{
        &{font-size:18px;}
    }
    @media @sm-max{
        &{margin:0 5px; font-size:16px;}
    }
}
.basket-page__section{
    padding:0 0 14px;
    &:last-child{padding:0;}
    @media @sm-max{
        &{padding:0;}
    }
}
.basket-page__title{
    margin:0 15px; font-weight:bold; font-size:20px; line-height:20px; border-bottom:1px solid transparent;
    @media @lg-max{
        &{font-size:18px;}
    }
    @media @sm-max{
        &{margin:0 5px; font-size:16px;}
    }
}

.cart{
    padding:0 0 21px;
    @media @md-max{
        &{padding:0 0 5px;}
    }
    @media @sm-max{
        &{padding:0 0 12px;}
    }
}
.cart__table{
    width:100%; display:table;
    @media @sm-max{
        &{display:block;}
    }
}
.cart__head{
    display:table-row;
    @media @sm-max{
        &{display:none;}
    }
}
.cart__head-col{
    padding:16px 15px 16px 0; display:table-cell; vertical-align:middle; background:@green; font-weight:bold; font-size:20px; line-height:28px; color:#fff;
    &:first-child{padding-left:30px;}
    &_1{width:44.42%;}
    &_2{width:17.77%;}
    &_3{width:14.12%;}
    &_4{width:15.83%;}
    @media @lg-max{
        &{font-size:18px;}
    }
}
.cart__list{
    margin:0; display:table-row-group;
    @media @sm-max{
        &{display:block;}
    }
}
.cart__item{
    display:table-row;
    @media @sm-max{
        &{min-height:69px; margin:0 0 17px; padding:0 0 15px 95px; display:flex; flex-wrap:wrap; position:relative; border-bottom:1px solid #DEDEDE;}
        &:last-child{margin:0; border:none;}
    }
}
.cart__cell{
    padding:15px 15px 15px 0; display:table-cell; vertical-align:middle; border-bottom:1px solid #DEDEDE;
    &:last-child{padding-right:0;}
    @media @sm-max{
        &{
            padding:0 5px; display:block; border:none;
            &_1{width:100%; padding-bottom:8px;}
            &_2{display:none;}
            &_3{width:100%; padding-bottom:6px;}
        }
    }
}
.cart__info{
    display:flex; align-items:center;
    @media @lg-md-max and @lg{
        &{flex-wrap:wrap;}
    }
}
.cart__info-col{
    &:first-child{flex-grow:0; flex-shrink:0;}
    @media @lg-md-max and @lg{
        &{width:100%;}
    }
}
.cart__image{
    width:118px; height:71px; margin:0 20px 0 0; display:block; border:1px solid #DEDEDE; background-repeat:no-repeat; background-position:center center; background-size:cover;
    @media @lg-md-max and @lg{
        &{margin:3px 0 10px;}
    }
    @media @sm-max{
        &{width:85px; height:51px; position:absolute; top:3px; left:0;}
    }
}
.cart__title{
    margin:0; font-weight:normal; font-size:18px; line-height:25px;
    @media @lg-md-max{
        &{font-size:16px; line-height:22px;}
    }
}
.cart__link{
    color:@black;
    .desktop &:hover{color:@green;}
}
.cart__art{
    display:block; font-size:18px; line-height:25px;
    & span{color:#777;}
    @media @lg-md-max{
        &{font-size:16px; line-height:22px;}
    }
}
.cart__mob-button{
    display:none;
    @media @sm-max{
        &{
            padding-right:15px; display:block; position:relative; top:9px;
            &:before, &:after{width:9px;}
        }
    }
}
.cart__desktop-button{
    @media @sm-max{
        &{display:none;}
    }    
}
.cart__price{display:block; font-size:22px; line-height:28px; white-space:nowrap;}
.cart__bottom{
    padding:0 9.68% 0 0; text-align:right;
    @media @md-max{
        &{padding:0 13% 0 0;}
    }
    @media @md-sm-max{
        &{padding:0 8% 0 0;}
    }
    @media @sm-max{
        &{padding:0;}
    }
}
.cart__totals-wrap{
    width:100%; max-width:295px; display:inline-block; text-align:left;
    @media @sm-max{
        &{max-width:none; padding-top:2px;}
    }
}
.cart__totals{
    padding:21px 3px; display:flex; justify-content:space-between; align-items:center; border-bottom:1px solid #DEDEDE;
    &:last-child{border:none;}
    @media @sm-max{
        &{
            margin:0 0 10px; padding:0 0 11px; justify-content:flex-start; align-items:flex-start;
            &:last-child{padding:0;}
        }
    }
}
.cart__totals-left{
    padding:0 15px 0 0; font-size:18px; line-height:24px; position:relative;
    @media @sm-max{
        &{min-width:99px; flex-grow:0; flex-shrink:0; top:7px;}
    }
}
.cart__totals-right{
    font-size:25px; line-height:34px;
}

.plus-minus{width:76px; padding:0 19px; position:relative;}
.plus-minus__input{
    width:100%; height:38px; font:20px/1 @font; text-align:center; background:#fff; border:1px solid #CDCDCD;
    &:focus{border-color:@black;}
    @media @lg-md-max{
        &{font-size:18px;}
    }
}
.plus-minus__prev, .plus-minus__next{
    width:19px; height:100%; position:absolute; top:0; left:0;
    &:before, &:after{content:""; width:10px; height:2px; margin:-1px 0 0; position:absolute; top:50%; left:0; background:@green; .transition;}
    &:after{.rotate(90deg);}
    .desktop &:hover{
        &:before, &:after{background:@black;}
    }
    &:disabled{cursor:pointer;}
}
.plus-minus__prev{
    left:auto; right:0;
    &:before{left:auto; right:0;}
    &:after{display:none;}
}

.cart-form{
    padding:0 0 0 30px;
    &_delivery{
        & .input__field, & .input__textarea{
            &::-webkit-input-placeholder{color:transparent;}
            &:-ms-input-placeholder{color:transparent;}
            &::-moz-placeholder{color:transparent;}
        }
    }
    @media @lg-md-max{
        & .input__label{font-size:16px;}
        & .input__field{font-size:16px;}
        & .input__textarea{font-size:16px;}
        & .input__placeholder{font-size:16px;}
        & .radio{padding:0 0 7px;}
        & .radio__label{min-height:22px; font-size:16px; line-height:22px;}
        & .radio__icon{top:3px;}
    }
    @media @md-max{
        &{padding-left:0;}
    }
    @media @sm-max{
        padding:0 0 10px;
        &_delivery{
            & .input__field, & .input__textarea{
                margin:0;
                &::-webkit-input-placeholder{color:@black;}
                &:-ms-input-placeholder{color:@black;}
                &::-moz-placeholder{color:@black;}
            }
            & .input__label{display:none;}
        }
    }
}
.cart-form__bottom{
    padding:10px 0 0;
    @media @sm-max{
        &{padding:0;}
    }
}
.cart-form__button{
    width:100%; padding:0; display:block; text-transform:uppercase;
    @media @lg-md-max{
        &{height:66px; font-size:20px; line-height:66px;}
    }
    @media @sm-max{
        &{margin:0 0 5px;}
    }
}
.cart-form__cols{
    max-width:643px; margin:0 0 30px; display:flex; align-items:center;
    & .input{padding:0;}
    & .input__field{margin:0;}
    & .input__placeholder{opacity:0; left:-9999px;}
    @media @md-max{
        &{max-width:none;}
    }
    @media @sm-max{
        margin:0 0 10px; display:block;
        & .input__placeholder{opacity:1; left:0;}
    }
}
.cart-form__left{
    width:136px; flex-grow:0; flex-shrink:0;
    @media @sm-max{
        &{display:none;}
    }
}
.cart-form__right{width:100%; min-width:0;}
.cart-form__text{
    padding:0 0 15px; display:block; font-size:18px; line-height:20px;
    @media @lg-md-max{
        &{font-size:16px;}
    }
    @media @sm-max{
        &{
            padding:0 0 8px;
            &:first-child{margin-top:-2px;}
        }
    }
}
.cart-form__content{
    @media @sm-max{
        &{
            &:first-child{margin:-4px 0 -16px;}
        }
    }
}
.cart-form__label{
    padding:0 15px 0 0; display:block; font-size:18px; line-height:24px; user-select:none;
    & span{color:@red;}
    @media @lg-md-max{
        &{font-size:16px;}
    }
}
.cart-form__reg{
    padding:0 0 22px 11px;
    &:last-child{padding-bottom:0;}
    @media @lg-md-max{
        &{padding-left:0;}
    }
    @media @sm-max{
        &{padding-bottom:11px; padding-left:11px;}
    }
}
.cart-form__delivery{
    padding:0 0 16px;
    & .radio{
        margin:0 0 14px;
        &:last-child{margin:0;}
    }
    &:last-child{padding:0 0 8px;}
    @media @md-max{
        & .radio{
            margin:0;
        }
    }
    @media @sm-max{
        &{
            padding:0 0 13px;
            &:last-child{padding:0 0 5px;}
        }
    }
}
.cart-form__pay{
    padding:0 0 18px;
    &:last-child{padding-bottom:0;}
    @media @sm-max{
        &{
            padding:0 0 4px;
            &:first-child{margin-top:-5px;}
        }
    }
}

/*____________________4.8 DELIVERY____________________*/
.delivery-table{
    margin:4px 0 30px; font-size:22px; line-height:30px;
    @media @lg-max{
        &{font-size:20px; line-height:28px;}
    }
    @media @md-max{
        &{font-size:18px;}
    }
    @media @sm-max{
        &{margin-bottom:24px; line-height:25px;}
    }
}
.delivery-table__item{
    margin:0 -5px 0 0; display:flex; align-items:center;
    &:first-child{
        & .delivery-table__col{
            color:#fff; font-weight:bold;
            &:before{background:@green;;}
        }
    }
    @media @md-max{
        &:first-child{
            & .delivery-table__left{padding:0 0 7px; display:block; font-weight:bold;}
        }
    }
}
.delivery-table__left{
    width:100%; min-width:0; padding:0 36px;
    @media @lg-max{
        &{padding:0 15px 0 0;}
    }
    @media @md-max{
        &{display:none;}
        & span{display:none;}
    }
}
.delivery-table__col{
    width:195px; margin:0 5px 0 0; padding:15px 10px; position:relative; flex-grow:0; flex-shrink:0; text-align:center;
    &:before{content:""; width:100%; height:100%; position:absolute; top:0; left:0; background:#EBEBEB;}
    & span{position:relative; z-index:1;}
    &:nth-child(3){
        &:before{opacity:0.9;}
    }
    &:nth-child(4){
        &:before{opacity:0.7;}
    }
    &:nth-child(5){
        &:before{opacity:0.5;}
    }
    &:nth-child(6){
        &:before{opacity:0.3;}
    }
    @media @lg-max{
        &{width:160px;}
    }
    @media @lg-md-max{
        &{width:135px;}
    }
    @media @md-max{
        &{display:none;}
    }
}
.delivery-item__tablet{
    display:none;
    @media @md-max{
        &{display:block;}
    }
}

.delivery-remark{
    margin:0 0 18px; padding:32px 50px 14px; background:#EBEBEB; font-size:22px; line-height:30px;
    & ul{
        & > li{
            padding:0 0 0 34px;
            &:before{top:14px; left:13px; background:@black;}
        }
    }
    @media @lg-max{
        &{
            font-size:20px; line-height:28px;
            & ul{
                & > li{
                    padding:0 0 0 28px;
                    &:before{top:12px; left:10px;}
                }
            } 
        }
    }
    @media @md-max{
        &{
            margin:0 0 22px; padding:27px 30px 10px; font-size:18px;
            & p:first-child{font-size:20px; line-height:27px;}
        }
    }
    @media @sm-max{
        &{
            margin:0 -15px 23px; padding:23px 15px 5px; font-size:16px; line-height:22px;
            & ul{
                & > li{
                    padding:0 0 0 24px;
                    &:before{top:9px; left:8px;}
                }
            }    
        }
    }
}

.delivery-article__title{
    margin:0 0 11px; font-weight:bold; font-size:35px; line-height:48px;
    @media @lg-md-max{
        &{font-size:30px; line-height:40px;}
    }
    @media @sm-max{
        &{margin:0 0 8px; font-size:20px; line-height:27px;}
    }     
}
.delivery-article__text{
    font-size:22px; line-height:30px;
    & ul{
        & > li{
            padding:0 0 0 34px;
            &:before{top:14px; left:13px; background:@black;}
        }
    }
    @media @lg-max{
        &{
            font-size:20px; line-height:28px;
            & ul{
                & > li{
                    padding:0 0 0 28px;
                    &:before{top:12px; left:10px;}
                }
            }  
        }
    }
    @media @md-max{
        &{font-size:18px;}
    }
    @media @sm-max{
        &{
            font-size:16px; line-height:22px;
            & ul{
                margin:0 0 15px;
                & > li{
                    padding:0 0 0 24px;
                    &:before{top:9px; left:8px;}
                }
            }    
        }
    }
}


/*____________________4.9 ABOUT COMPANY____________________*/
.about-us-page{
    padding:0 0 11px;
    @media @sm-max{
        &{padding:0 0 3px;}
    }   
}

.about-us__title{
    margin:0 0 13px; font-weight:bold; font-size:35px; line-height:48px;
    @media @lg-md-max{
        &{font-size:30px; line-height:40px;}
    }
    @media @sm-max{
        &{margin:0 0 8px; font-size:20px; line-height:27px;}
    }   
}
.about-us__text{
    margin:0 0 -3px;
    & a{
        text-decoration:none;
        .desktop &:hover{text-decoration:underline;}
    }
}

/*-------------------------------------------------------------------------------------------*/
/* -----------------------------------------5. LIBS------------------------------------------*/
/*-------------------------------------------------------------------------------------------*/
/*____________________5.1 SLICK____________________*/
.slick-slider{position:relative; display:block; box-sizing:border-box; -webkit-user-select:none; -moz-user-select:none; -ms-user-select:none; user-select:none; -webkit-touch-callout:none; -khtml-user-select:none; -ms-touch-action:pan-y; touch-action:pan-y; -webkit-tap-highlight-color:transparent;}
.slick-list{position:relative; display:block; overflow:hidden; margin:0; padding:0;}
.slick-list:focus{outline:none;}
.slick-list.dragging{cursor:pointer;}
.slick-slider .slick-track,.slick-slider .slick-list{-webkit-transform:none; -moz-transform:none; -ms-transform:none; -o-transform:none; transform:none;}
.slick-track{position:relative; top:0; left:0; display:block;}
.slick-track:before,.slick-track:after{display:table; content:'';}
.slick-track:after{clear:both;}
.slick-loading .slick-track{visibility:hidden;}
.slick-slide{display:none; float:left; height:100%; min-height:1px; border:none !important;}
[dir='rtl'] .slick-slide{float:right;}
.slick-slide.dragging img{pointer-events:none;}
.slick-initialized .slick-slide{display:block;}
.slick-loading .slick-slide{visibility:hidden;}
.slick-vertical .slick-slide{display:block; height:auto; border:1px solid transparent;}
.slick-arrow.slick-hidden{display:none;}
.slick-prev, .slick-next{position:relative;}

.slick-dots{
    margin:0 -7px; display:flex; flex-wrap:wrap; justify-content:center;
    & li{
        padding:0 7px 14px;
        &.slick-active{
            & button{background:@green;}
        }
        & button{
            width:17px; height:17px; background:#fff; border:1px solid @green; border-radius:50%;
            .desktop &:hover{background:@green;}
        }
    }
    @media @sm-max{
        margin:0 -3.5px;
        & li{
            padding-left:3.5px; padding-right:3.5px;
            & button{background:#d9d9d9; border-color:transparent;}
        }
    }
}

/*____________________5.2 FANCYBOX____________________*/
body.compensate-for-scrollbar{
    overflow:hidden;
    .desktop & .header{
        padding-right:17px;
    }
}
.fancybox-active{height:auto;}
.mob .fancybox-active{overflow:hidden !important;}
.fancybox-is-hidden{left:-9999px;margin:0;position:absolute !important;top:-9999px;visibility:hidden;}
.fancybox-container{-webkit-backface-visibility:hidden;height:100%;left:0;outline:none;position:fixed;-webkit-tap-highlight-color:transparent;top:0;-ms-touch-action:manipulation;touch-action:manipulation;transform:translateZ(0);width:100%;z-index:99992;}
.fancybox-container *{box-sizing:border-box;}
.fancybox-outer,.fancybox-inner,.fancybox-bg,.fancybox-stage{bottom:0;left:0;position:absolute;right:0;top:0;}
.fancybox-outer{-webkit-overflow-scrolling:touch;overflow-y:auto;}
.fancybox-bg{background:rgba(0,0,0,0.75); opacity:0;transition-duration:inherit;transition-property:opacity;/*transition-timing-function:cubic-bezier(.47, 0, .74, .71);*/}
.fancybox-is-open .fancybox-bg{opacity:1;/*transition-timing-function:cubic-bezier(.22, .61, .36, 1);*/}
.fancybox-infobar,.fancybox-toolbar,.fancybox-caption,.fancybox-navigation .fancybox-button{direction:ltr;opacity:0;position:absolute;transition:opacity .25s ease, visibility 0s ease .25s;visibility:hidden;z-index:99997;}
.fancybox-show-infobar .fancybox-infobar,.fancybox-show-toolbar .fancybox-toolbar,.fancybox-show-caption .fancybox-caption,.fancybox-show-nav .fancybox-navigation .fancybox-button{opacity:1;transition:opacity .25s ease 0s, visibility 0s ease 0s;visibility:visible;}
.fancybox-infobar{color:#ccc;font-size:13px;-webkit-font-smoothing:subpixel-antialiased;height:44px;left:0;line-height:44px;min-width:44px;mix-blend-mode:difference;padding:0 10px;pointer-events:none;top:0;-webkit-touch-callout:none;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;}
.fancybox-toolbar{right:0;top:0;}
.fancybox-stage{direction:ltr;overflow:visible;transform:translateZ(0);z-index:99994;}
.fancybox-is-open .fancybox-stage{overflow:hidden;}
.fancybox-slide{-webkit-backface-visibility:hidden;/* Using without prefix would break IE11 */
 display:none;height:100%;left:0;outline:none;overflow:auto;-webkit-overflow-scrolling:touch;padding:0;position:absolute;text-align:center;top:0;transition-property:transform, opacity;white-space:normal;width:100%;z-index:99994;}
.fancybox-slide::before{content:'';display:inline-block;font-size:0;height:100%;vertical-align:middle;width:0;}
.fancybox-is-sliding .fancybox-slide,.fancybox-slide--previous,.fancybox-slide--current,.fancybox-slide--next{display:block;}
.fancybox-slide--image{overflow:hidden;padding:0;}
.fancybox-slide--image::before{display:none;}
.fancybox-slide--html{padding:85px 42px 40px;}
.fancybox-content{display:inline-block;margin:0;width:100%;overflow:visible;-webkit-overflow-scrolling:touch;position:relative;text-align:left;vertical-align:middle;}
.fancybox-slide--image .fancybox-content{animation-timing-function:cubic-bezier(.5, 0, .14, 1);-webkit-backface-visibility:hidden;background:transparent;background-repeat:no-repeat;background-size:100% 100%;left:0;max-width:none;overflow:visible;padding:0;position:absolute;top:0;-ms-transform-origin:top left;transform-origin:top left;transition-property:transform, opacity;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;z-index:99995;}
.fancybox-can-zoomOut .fancybox-content{cursor:zoom-out;}
.fancybox-can-zoomIn .fancybox-content{cursor:zoom-in;}
.fancybox-can-swipe .fancybox-content,.fancybox-can-pan .fancybox-content{cursor:-webkit-grab;cursor:grab;}
.fancybox-is-grabbing .fancybox-content{cursor:-webkit-grabbing;cursor:grabbing;}
.fancybox-container [data-selectable='true']{cursor:text;}
.fancybox-image,.fancybox-spaceball{background:transparent;border:0;height:100%;left:0;margin:0;max-height:none;max-width:none;padding:0;position:absolute;top:0;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;width:100%;}
.fancybox-spaceball{z-index:1;}
.fancybox-slide--video .fancybox-content,.fancybox-slide--map .fancybox-content,.fancybox-slide--pdf .fancybox-content,.fancybox-slide--iframe .fancybox-content{height:100%;overflow:visible;padding:0;width:100%;}
.fancybox-slide--video .fancybox-content{background:#000; max-width:800px; max-height:500px;}
.fancybox-slide--map .fancybox-content{background:#e5e3df;}
.fancybox-slide--iframe .fancybox-content{background:#fff;}
.fancybox-video,.fancybox-iframe{background:transparent;border:0;display:block;height:100%;margin:0;overflow:hidden;padding:0;width:100%; }
/* Fix iOS */
.fancybox-iframe{left:0;position:absolute;top:0;}
.fancybox-error{background:#fff;cursor:default;max-width:400px;padding:40px;width:100%;}
.fancybox-error p{color:#444;font-size:16px;line-height:20px;margin:0;padding:0;}
/* Buttons */
.fancybox-button{
    width:40px; height:40px; position:relative; cursor:pointer; display:block; background:#fff; border:1px solid #F2F3F4;
    border-radius:50%; text-align:center; color:#2a2c31; transition:all linear 0.3s !important;
}
.desktop .fancybox-button:hover{border-color:@green; color:@green;}
.fancybox-button:focus{outline:none;}
.fancybox-button.fancybox-focus{outline:1px dotted;}
/* .fancybox-button[disabled],.fancybox-button[disabled]:hover{color:#888;cursor:default;outline:none;} */
.fancybox-button--zoom{display:none !important;}
/* Fix IE11 */
.fancybox-button div{height:100%;}
.fancybox-button svg{display:none;height:100%;overflow:visible;position:relative;width:100%;}
.fancybox-button svg path{fill:#fff;stroke-width:0;}
.fancybox-button--play svg:nth-child(2),.fancybox-button--fsenter svg:nth-child(2){display:none;}
.fancybox-button--pause svg:nth-child(1),.fancybox-button--fsexit svg:nth-child(1){display:none;}
.fancybox-progress{background:#ff5268;height:2px;left:0;position:absolute;right:0;top:0;-ms-transform:scaleX(0);transform:scaleX(0);-ms-transform-origin:0;transform-origin:0;transition-property:transform;transition-timing-function:linear;z-index:99998;}
/* Close button on the top right corner of html content */
.fancybox-close-small{background:transparent;border:0;border-radius:0;color:#ccc;cursor:pointer;opacity:.8;padding:8px;position:absolute;right:-12px;top:-44px;z-index:401;}
.fancybox-close-small:hover{color:#fff;opacity:1;}
.fancybox-slide--html .fancybox-close-small{color:currentColor;padding:10px;right:0;top:0;}
.fancybox-slide--image.fancybox-is-scaling .fancybox-content{overflow:hidden;}
.fancybox-is-scaling .fancybox-close-small,.fancybox-is-zoomable.fancybox-can-pan .fancybox-close-small{display:none;}
.fancybox-slide--image.fancybox-is-scaling .fancybox-content{overflow:hidden;}
.fancybox-is-scaling .fancybox-close-small,.fancybox-is-zoomable.fancybox-can-pan .fancybox-close-small, .fancybox-is-scaling .fancybox-prev, .fancybox-is-scaling .fancybox-next, .fancybox-is-zoomable.fancybox-can-pan .fancybox-prev, .fancybox-is-zoomable.fancybox-can-pan .fancybox-next{display:none !important;}
/* Navigation arrows */
.fancybox-navigation .fancybox-button{opacity:0; position:absolute; top:50%; margin:-20px 0 0;}

.fancybox-navigation .fancybox-button--arrow_left{left:16px;}
.fancybox-navigation .fancybox-button--arrow_left:before{content:"\e900"; font:11px/40px 'icons';}
.fancybox-navigation .fancybox-button--arrow_right{right:16px;}
.fancybox-navigation .fancybox-button--arrow_right:before{content:"\e901"; font:11px/40px 'icons';}
/* Caption */
.fancybox-caption{background:linear-gradient(to top, rgba(0, 0, 0, .85) 0%, rgba(0, 0, 0, .3) 50%, rgba(0, 0, 0, .15) 65%, rgba(0, 0, 0, .075) 75.5%, rgba(0, 0, 0, .037) 82.85%, rgba(0, 0, 0, .019) 88%, rgba(0, 0, 0, 0) 100%);bottom:0;color:#eee;font-size:14px;font-weight:400;left:0;line-height:1.5;padding:75px 44px 25px 44px;pointer-events:none;right:0;text-align:center;z-index:99996;}
.fancybox-caption--separate{margin-top:-50px;}
.fancybox-caption__body{max-height:50vh;overflow:auto;pointer-events:all;}
.fancybox-caption a,.fancybox-caption a:link,.fancybox-caption a:visited{color:#ccc;text-decoration:none;}
.fancybox-caption a:hover{color:#fff;text-decoration:underline;}
/* Loading indicator */
.fancybox-loading{animation:fancybox-rotate 1s linear infinite;background:transparent;border:4px solid #888;border-bottom-color:#fff;border-radius:50%;height:50px;left:50%;margin:-25px 0 0 -25px;opacity:.7;padding:0;position:absolute;top:50%;width:50px;z-index:99999;}
@keyframes fancybox-rotate{100%{transform:rotate(360deg);}
}
/* Transition effects */
.fancybox-animated{transition-timing-function:cubic-bezier(0, 0, .25, 1);}
/* transitionEffect:slide */
.fancybox-fx-slide.fancybox-slide--previous{opacity:0;transform:translate3d(-100%, 0, 0);}
.fancybox-fx-slide.fancybox-slide--next{opacity:0;transform:translate3d(100%, 0, 0);}
.fancybox-fx-slide.fancybox-slide--current{opacity:1;transform:translate3d(0, 0, 0);}
/* transitionEffect:fade */
.fancybox-fx-fade.fancybox-slide--previous,.fancybox-fx-fade.fancybox-slide--next{opacity:0;transition-timing-function:cubic-bezier(.19, 1, .22, 1);}
.fancybox-fx-fade.fancybox-slide--current{opacity:1;}
/* transitionEffect:zoom-in-out */
.fancybox-fx-zoom-in-out.fancybox-slide--previous{opacity:0;transform:scale3d(1.5, 1.5, 1.5);}
.fancybox-fx-zoom-in-out.fancybox-slide--next{opacity:0;transform:scale3d(.5, .5, .5);}
.fancybox-fx-zoom-in-out.fancybox-slide--current{opacity:1;transform:scale3d(1, 1, 1);}
/* transitionEffect:rotate */
.fancybox-fx-rotate.fancybox-slide--previous{opacity:0;-ms-transform:rotate(-360deg);transform:rotate(-360deg);}
.fancybox-fx-rotate.fancybox-slide--next{opacity:0;-ms-transform:rotate(360deg);transform:rotate(360deg);}
.fancybox-fx-rotate.fancybox-slide--current{opacity:1;-ms-transform:rotate(0deg);transform:rotate(0deg);}
/* transitionEffect:circular */
.fancybox-fx-circular.fancybox-slide--previous{opacity:0;transform:scale3d(0, 0, 0) translate3d(-100%, 0, 0);}
.fancybox-fx-circular.fancybox-slide--next{opacity:0;transform:scale3d(0, 0, 0) translate3d(100%, 0, 0);}
.fancybox-fx-circular.fancybox-slide--current{opacity:1;transform:scale3d(1, 1, 1) translate3d(0, 0, 0);}
/* transitionEffect:tube */
.fancybox-fx-tube.fancybox-slide--previous{transform:translate3d(-100%, 0, 0) scale(.1) skew(-10deg);}
.fancybox-fx-tube.fancybox-slide--next{transform:translate3d(100%, 0, 0) scale(.1) skew(10deg);}
.fancybox-fx-tube.fancybox-slide--current{transform:translate3d(0, 0, 0) scale(1);}
/* Styling for Small-Screen Devices */
@media all and (max-height:576px){
 .fancybox-slide--image{padding:0;}
 .fancybox-close-small{right:-6px;}
 .fancybox-slide--image .fancybox-close-small{background:#4e4e4e;color:#f2f4f6;height:36px;opacity:1;padding:6px;right:0;top:0;width:36px;}
 .fancybox-caption{padding-left:12px;padding-right:12px;}
}
/* Share */
.fancybox-share{background:#f4f4f4;border-radius:3px;max-width:90%;padding:30px;text-align:center;}
.fancybox-share h1{color:#222;font-size:35px;font-weight:700;margin:0 0 20px 0;}
.fancybox-share p{margin:0;padding:0;}
.fancybox-share__button{border:0;border-radius:3px;display:inline-block;font-size:14px;font-weight:700;line-height:40px;margin:0 5px 10px 5px;min-width:130px;padding:0 15px;text-decoration:none;transition:all .2s;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;white-space:nowrap;}
.fancybox-share__button:visited,.fancybox-share__button:link{color:#fff;}
.fancybox-share__button:hover{text-decoration:none;}
.fancybox-share__button--fb{background:#3b5998;}
.fancybox-share__button--fb:hover{background:#344e86;}
.fancybox-share__button--pt{background:#bd081d;}
.fancybox-share__button--pt:hover{background:#aa0719;}
.fancybox-share__button--tw{background:#1da1f2;}
.fancybox-share__button--tw:hover{background:#0d95e8;}
.fancybox-share__button svg{height:25px;margin-right:7px;position:relative;top:-1px;vertical-align:middle;width:25px;}
.fancybox-share__button svg path{fill:#fff;}
.fancybox-share__input{background:transparent;border:0;border-bottom:1px solid #d7d7d7;border-radius:0;color:#5d5b5b;font-size:14px;margin:10px 0 0 0;outline:none;padding:10px 15px;width:100%;}
/* Thumbs */
.fancybox-thumbs{background:#ddd;bottom:0;display:none;margin:0;-webkit-overflow-scrolling:touch;-ms-overflow-style:-ms-autohiding-scrollbar;padding:2px 2px 4px 2px;position:absolute;right:0;-webkit-tap-highlight-color:rgba(0, 0, 0, 0);top:0;width:212px;z-index:99995;}
.fancybox-thumbs-x{overflow-x:auto;overflow-y:hidden;}
.fancybox-show-thumbs .fancybox-thumbs{display:block;}
.fancybox-show-thumbs .fancybox-inner{right:212px;}
.fancybox-thumbs__list{font-size:0;height:100%;list-style:none;margin:0;overflow-x:hidden;overflow-y:auto;padding:0;position:absolute;position:relative;white-space:nowrap;width:100%;}
.fancybox-thumbs-x .fancybox-thumbs__list{overflow:hidden;}
.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar{width:7px;}
.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-track{background:#fff;border-radius:10px;box-shadow:inset 0 0 6px rgba(0, 0, 0, .3);}
.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-thumb{background:#2a2a2a;border-radius:10px;}
.fancybox-thumbs__list a{-webkit-backface-visibility:hidden;backface-visibility:hidden;background-color:rgba(0, 0, 0, .1);background-position:center center;background-repeat:no-repeat;background-size:cover;cursor:pointer;float:left;height:75px;margin:2px;max-height:calc(100% - 8px);max-width:calc(50% - 4px);outline:none;overflow:hidden;padding:0;position:relative;-webkit-tap-highlight-color:transparent;width:100px;}
.fancybox-thumbs__list a::before{border:6px solid #ff5268;bottom:0;content:'';left:0;opacity:0;position:absolute;right:0;top:0;transition:all .2s cubic-bezier(.25, .46, .45, .94);z-index:99991;}
.fancybox-thumbs__list a:focus::before{opacity:.5;}
.fancybox-thumbs__list a.fancybox-thumbs-active::before{opacity:1;}

.fancybox-button--close{margin:16px 16px 0 0; padding:5px; display:block; position:relative; border-radius:50%; opacity:1;}
.fancybox-button--close:before, .fancybox-button--close:after{content:""; width:18px; height:2px; position:absolute; top:18px; left:10px; background:#2a2c31; transform:rotate(45deg); transition:all linear 0.3s;}
.fancybox-button--close:after{transform:rotate(-45deg);}
.fancybox-button--close:hover{border-color:@green;}
.fancybox-button--close:hover:before, .fancybox-button--close:hover:after{background:@green;}

/* Styling for Small-Screen Devices */
@media all and (max-width:767px){
    .fancybox-button{width:32px; height:32px;}
    .fancybox-navigation .fancybox-button:before, .fancybox-navigation .fancybox-button:after{line-height:32px;}
    .fancybox-button--close:before, .fancybox-button--close:after{top:14px; left:6px;}
    .fancybox-navigation .fancybox-button{margin:-16px 0 0;}
}

@media all and (max-width:576px){
    .fancybox-thumbs{width:110px;}
 .fancybox-show-thumbs .fancybox-inner{right:110px;}
 .fancybox-thumbs__list a{max-width:calc(100% - 10px);}
}

/*____________________5.3 RATING____________________*/
.star-rating{width:24px !important; cursor:pointer; display:inline-block; vertical-align:top; position:relative; background:transparent !important; overflow:hidden !important}
.star-rating a{width:24px !important; height:24px; display:block; text-indent:-9999px; border:none;}
.star-rating a:before{content:""; width:23px; height:23px; margin:-11.5px 0 0 -11.5px; position:absolute; top:50%; left:50%; background:url(../img/svg/star.svg) center center no-repeat; background-size:100% 100%;}
.star-rating-on a:before{background:url(../img/svg/star-active.svg) center center no-repeat; background-size:100% 100%;}
.star-rating-hover a:before{background:url(../img/svg/star-active.svg) center center no-repeat !important; background-size:100% 100% !important;}
.star-rating-readonly a{cursor:default}

.rating-cancel{display:none !important;}

.rating{
    &_large{
        .star-rating{width:34px !important;}
        .star-rating a{width:34px !important; height:34px;}
        .star-rating a:before{width:32px; height:32px; margin:-16px 0 0 -16px;}
    }
}